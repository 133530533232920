/* eslint-disable */
import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import { Grid, Column } from "../../../../theme/Grid";
import Button from "../../../../theme/Button";
import Input from "../../../../theme/CustomMUI/Input";
import Select from "../../../../theme/CustomMUI/Select";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import MaskedInput from "../../../../theme/CustomMUI/MaskedInput";
import { toast } from "react-toastify";
import moment from "moment";
import {
  AddBox,
  Layers,
  ShoppingBasket,
  Payment,
  Clear,
} from "@material-ui/icons";

// import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment, TextField } from "@material-ui/core";
import StaffSelect from "../../../../theme/CustomMUI/StaffSelect";
import { Tooltip } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Waiting from "../../../../assets/images/svg/waiting-receipt.svg";
import HomeChcek from "../../../../assets/images/svg/home-check-receipt.svg";
import NotCome from "../../../../assets/images/svg/notCome-receipt.svg";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import PackageSalesAdd from "../../Packages/Sales/Add";
import AppContext from "../../../../context/store";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const themeForDatePicker = createTheme({
  typography: {
    fontSize: 11,
  },
});

const themeForCardDetail = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        zIndex: "999999999",
        width: "150px",
        backgroundColor: "rgba(10,10,10,0.7)",
      },
    },
  },
});

const selectedmuiTheme = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#3189ec",
          "&:hover": {
            backgroundColor: "#3189ec",
          },
        },
      },
      button: {
        "&:hover": {
          backgroundColor: "rgba(13, 78, 108, 0.2)",
        },
      },
    },
  },
});

//** Detail Dialog Models */

//** */ Services Dialog
export const _TypeServicesDetailDialog = ({
  services_detail_dialog_modal,
  checkout_data,
  staffs,
  service_transaction,
  services,
  services_by_selected_staff,
  services_by_selected_staff_show,
  setServicesDetailDialogModal,
  setServiceTransaction,
  setServicesBySelectedStaffShow,
  setServicesBySelectedStaff,
  getServicesByStaffId,
  addSingleServiceIntoCheckout,
  setCheckoutData,
  customer_s,
  save_button_disabled,
  setPackageChanges,
  setSaveButtonDisabled,
  saveSingleReceipt,
}) => {
  const context = useContext(AppContext);
  return (
    <AlertDialog
      open={services_detail_dialog_modal}
      title={
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
          }}
        >
          <Clear
            style={{ cursor: "pointer" }}
            onClick={() => {
              setServicesDetailDialogModal(false);
              setServiceTransaction({
                staff_id: null,
                service_id: null,
                amount: null,
                first_amount: null,
                process_date: null,
                process_type: null,
              });
              setServicesBySelectedStaffShow(false);
            }}
          />
        </div>
      }
      disableBackdropClick={false}
      fullWidth={true}
      maxWidth={false}
      // maxWidth="lg"
      closeHandler={() => {
        setServicesDetailDialogModal(false);
      }}
    >
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-12">
          {/* Servis başlığı */}
          <Column className="xs-12 sm-12 md-12 lg-4">
            <ServicesSectionHeading
              style={{
                backgroundColor: "rgba(13, 78, 108, 0.04)",
                color: "#3189ec",
                border: "1px solid #3189ec",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <AddBox />
                <h3 style={{ marginLeft: "15px" }}>
                  {context.t(
                    `['receipt/detail/id'].serviceTransactions.soldTitle`
                  )}
                </h3>
              </div>
            </ServicesSectionHeading>
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-8">
            <ServicesSectionHeading
              style={{
                backgroundColor: "rgba(13, 78, 108, 0.04)",
                color: "#3189ec",
                border: "1px solid #3189ec",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Layers />
                <h3 style={{ marginLeft: "15px" }}>
                  {context.t(
                    `['receipt/detail/id'].serviceTransactions.title`
                  )}
                  (
                  {
                    Object.values(checkout_data.services).filter(
                      (value) => value !== undefined
                    ).length
                  }
                  )
                </h3>
              </div>
            </ServicesSectionHeading>
          </Column>
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-12">
          <Column className="xs-12 sm-12 md-4 lg-4">
            <StaffList className="no-scroll-bar">
              {staffs
                .filter((m) => m.deleted_at === null)
                .map((staff) => (
                  <MuiThemeProvider theme={selectedmuiTheme}>
                    <ListItem
                      button
                      selected={staff.id == service_transaction.staff_id}
                      onClick={() => {
                        setServiceTransaction({
                          service_id: null,
                          amount: null,
                          first_amount: null,
                          process_date: null,
                          process_type: null,
                          staff_id: parseInt(staff.id),
                        });
                        setServicesBySelectedStaffShow(false);
                        setServicesBySelectedStaff([]);
                      }}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            color: `${staff.id == service_transaction.staff_id
                              ? "white"
                              : "#3189ec"
                              }`,
                          },
                        }}
                        primary={`${staff.name} ${staff.surname}`}
                      />
                    </ListItem>
                  </MuiThemeProvider>
                ))}
            </StaffList>
            <Column className="xs-12 sm-12">
              <Button
                className="mt-1 mb-1 mx-auto"
                fullWidth
                backgroundColor="primary"
                icon="search"
                // size="lg"
                textColor="white"
                title={context.t(`["receipt/detail/id"].getServices`)}
                disabled={!service_transaction.staff_id}
                onClick={() => {
                  getServicesByStaffId();
                }}
              />
            </Column>

            {services_by_selected_staff_show && (
              <div
                style={{
                  overflow: "hidden",
                  border: "1px solid white",
                }}
              >
                <ServicesList className="no-scroll-bar">
                  {services_by_selected_staff.map((service) => (
                    <MuiThemeProvider theme={selectedmuiTheme}>
                      <ListItem
                        button
                        selected={
                          service.id == service_transaction.service_id
                        }
                        onClick={() => {
                          setServiceTransaction({
                            ...service_transaction,
                            service_id: parseInt(service.id),
                            amount: Math.round(service.amount),
                            first_amount: Math.round(service.amount),
                            process_date: moment().format("YYYY-MM-DD"),
                            process_type: 2,
                          });
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              color: `${service.id == service_transaction.service_id
                                ? "white"
                                : "#3129ec"
                                }`,
                            }}
                          >{`${service.name} (${service.process_time} dk)`}</p>

                          <p
                            style={{
                              color: `${service.id == service_transaction.service_id
                                ? "white"
                                : "#3189ec"
                                }`,
                            }}
                          >{`${service.amount} ${context.state.currency
                            ? context.state.currency
                            : "₺"
                            }`}</p>
                        </div>
                      </ListItem>
                    </MuiThemeProvider>
                  ))}
                </ServicesList>
              </div>
            )}
            {services_by_selected_staff_show && (
              <Column className="xs-12 sm-12 md-4 lg-12 mt-2">
                <Column className="xs-12 sm-12 md-4 lg-6">
                  <Button
                    icon="add"
                    title="Ekle"
                    disabled={
                      !(
                        service_transaction.staff_id &&
                        service_transaction.service_id
                      )
                    }
                    backgroundColor="primary"
                    textColor="white"
                    fullWidth
                    onClick={() => {
                      addSingleServiceIntoCheckout();
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-4 lg-6">
                  <Button
                    icon="clear"
                    title={context.t(`["receipt/detail/id"].clear`)}
                    textColor="#ccc"
                    outlined={true}
                    fullWidth
                    onClick={() => {
                      setServiceTransaction({
                        staff_id: null,
                        service_id: null,
                        amount: null,
                        first_amount: null,
                        process_date: null,
                        process_type: null,
                      });
                      setServicesBySelectedStaffShow(false);
                    }}
                  />
                </Column>
              </Column>
            )}
          </Column>

          <Column className="xs-12 sm-12 md-8 lg-8">
            <Services
              className="no-scroll-bar"
              style={{ boxShadow: "inset 0 0 5px #3189ec" }}
            >
              {
                //Hizmet Listesi
                Object.keys(checkout_data.services).filter(
                  (key) => checkout_data.services[key] !== undefined
                ).length > 0 ? (
                  Object.keys(checkout_data.services).map(
                    (key, index) =>
                      checkout_data.services[key] !== undefined &&
                      checkout_data.services[key].process_type !== 1 && (
                        <Grid
                          style={{ margin: "8px" }}
                          className="no-gutters-row"
                          key={index}
                        >
                          <Column className="xs-12 sm-12 md-12 mt-1 lg-2">
                            <MuiThemeProvider theme={themeForDatePicker}>
                              <DatePickerInput
                                className="mb-1"
                                label={context.t(
                                  `['receipt/detail/id'].serviceTransactions.dateInputLabel`
                                )}
                                error={false}
                                value={
                                  moment(
                                    checkout_data.services[key].process_date
                                  ).format("YYYY-MM-DD") === "0001-01-01"
                                    ? null
                                    : moment(
                                      checkout_data.services[key]
                                        .process_date
                                    )
                                }
                                onChange={(process_date) => {
                                  setCheckoutData({
                                    ...checkout_data,
                                    services: {
                                      ...checkout_data.services,
                                      [key]: {
                                        ...checkout_data.services[key],
                                        process_date:
                                          moment(process_date).format(
                                            "YYYY-MM-DD"
                                          ),
                                      },
                                    },
                                  });
                                }}
                              />
                            </MuiThemeProvider>
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-3">
                            <Select
                              className="mb-1"
                              label={context.t(
                                `['receipt/detail/id'].serviceTransactions.nameInputLabel`
                              )}
                              items={staffs
                                .filter(
                                  (staff) =>
                                    staff.id ===
                                    checkout_data.services[key].staff_id
                                )[0]
                                .services.map((service) => service.service)}
                              selected={
                                checkout_data.services[key].service_id
                                  ? services.filter(
                                    (item) =>
                                      item.id ===
                                      checkout_data.services[key].service_id
                                  ).length
                                    ? checkout_data.services[key].service_id
                                    : context.t(`["receipt/detail/id"].deletedService`)
                                  : context.t(`["receipt/detail/id"].deletedService`)
                              }
                              labelKey="name"
                              valueKey="id"
                              title={
                                services.filter(
                                  (item) =>
                                    item.id ===
                                    checkout_data.services[key].service_id
                                ).length > 0
                                  ? services.filter(
                                    (item) =>
                                      item.id ===
                                      checkout_data.services[key].service_id
                                  )[0].name
                                  : context.t(`["receipt/detail/id"].deletedService`)
                              }
                              handler={(service_id) => {
                                setCheckoutData({
                                  ...checkout_data,
                                  services: {
                                    ...checkout_data.services,
                                    [key]: {
                                      ...checkout_data.services[key],
                                      service_id: parseInt(service_id),
                                      process_type: 2,
                                    },
                                  },
                                });
                              }}
                            />
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-2">
                            <StaffSelect
                              className="mb-1"
                              disabled={
                                context.state.user.permission === 0
                              }
                              label={context.t(
                                `['receipt/detail/id'].serviceTransactions.staffInputLabel`
                              )}
                              items={staffs}
                              selected={
                                checkout_data.services[key].staff_id
                                  ? checkout_data.services[key].staff_id
                                  : ""
                              }
                              labelKey="full_name"
                              valueKey="id"
                              handler={(staff_id) => {
                                setCheckoutData({
                                  ...checkout_data,
                                  services: {
                                    ...checkout_data.services,
                                    [key]: {
                                      ...checkout_data.services[key],
                                      staff_id: parseInt(staff_id),
                                      process_type: 2,
                                    },
                                  }
                                });
                              }}
                            />
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-2">
                            <Select
                              className="mb-1"
                              disabled={
                                context.state.user.permission === 0
                              }
                              label={context.t(`sms.bottom_table_titles.status`)}
                              items={customer_s}
                              selected={
                                checkout_data.services[key].customer_state !==
                                  null
                                  ? `${checkout_data.services[key].customer_state}`
                                  : ""
                              }
                              labelKey="label"
                              valueKey="value"
                              handler={(customer_state) => {
                                setCheckoutData({
                                  ...checkout_data,
                                  services: {
                                    ...checkout_data.services,
                                    [key]: {
                                      ...checkout_data.services[key],
                                      customer_state:
                                        parseInt(customer_state),
                                      process_type: 2,
                                    },
                                  }
                                });
                              }}
                            />
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-2">
                            <MaskedInput
                              className="mb-1"
                              label={
                                context.t(
                                  `['receipt/detail/id'].serviceTransactions.amountInputLabel`
                                ) +
                                (context.state.currency
                                  ? " (" + context.state.currency + ")"
                                  : "(₺)")
                              }
                              mask="9999999999999"
                              maskChar=""
                              value={
                                checkout_data.services[key].amount !== null
                                  ? checkout_data.services[key].amount
                                  : ""
                              }
                              onChange={(e) => {
                                setCheckoutData({
                                  ...checkout_data,
                                  services: {
                                    ...checkout_data.services,
                                    [key]: {
                                      ...checkout_data.services[key],
                                      amount: /[0-9,.]+/.test(
                                        e.target.value
                                      )
                                        ? Math.round(
                                          parseFloat(e.target.value)
                                        )
                                        : null,
                                      process_type: 2,
                                    },
                                  }
                                });
                              }}
                            />
                          </Column>

                          <Column className="xs-12  sm-12 md-12 lg-1">
                            <Button
                              icon="delete_forever"
                              iconSize={21}
                              textColor="red"
                              size="lg"
                              fullWidth
                              onClick={() => {
                                setCheckoutData({
                                  ...checkout_data,
                                  services: {
                                    ...checkout_data.services,
                                    [key]: undefined,
                                  }
                                });
                              }}
                            />
                          </Column>
                        </Grid>
                      )
                  )
                ) : (
                  <NoDataContainer style={{ height: "70%" }}>
                    <img
                      src={require("../../../../assets/images/manypixels/click_action.svg")}
                      alt="Malesef bulunamadı!"
                    />
                    <p>
                      {context.t(
                        `['receipt/detail/id'].serviceTransactions.noDataText`
                      )}
                    </p>
                  </NoDataContainer>
                )
              }
            </Services>
          </Column>
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-12 m-4">
          <ButtonContainer>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <Button
                icon="check"
                title={context.t(`receipts.detailButtons.confirmButton`)}
                backgroundColor="primary"
                textColor="white"
                fullWidth
                disabled={save_button_disabled}
                onClick={() => {
                  setPackageChanges(false);
                  setSaveButtonDisabled(true);
                  saveSingleReceipt();
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <Button
                icon="close"
                title={context.t(`receipts.detailButtons.closeButton`)}
                backgroundColor="white"
                textColor="black"
                fullWidth
                outlined
                onClick={() => {
                  setServicesDetailDialogModal(false);
                  setServiceTransaction({
                    staff_id: null,
                    service_id: null,
                    amount: null,
                    first_amount: null,
                    process_date: null,
                    process_type: null,
                  });
                  setServicesBySelectedStaffShow(false);
                }}
              />
            </Column>
          </ButtonContainer>
        </Column>
      </Grid>
    </AlertDialog>
  );
};
// Package Dialog
export const _TypePackageDetailDialog = ({
  checkout_data,
  package_detail_dialog_modal,
  staffs,
  services,
  setPackageDetailDialogModal,
  setNewPackageSalesDialogModal,
  setSelectedPackageUpdateDate,
  setSelectedPackageTransitionId,
  setServicesUpdateDateModal,
  packet_detail_array,
  setPacketDetailArray,
  setCheckoutData,
  setPackageChanges,
  packageChanges,
  setSelectedPackageTransitionUpdateState,
  setServicesUpdateModal,
  setDeletePackageId,
  setPackageDeleteModal,
  setDeletedPackagesServicesTransactionPackageId,
  deleted_packages_services_transaction_package_id,
  setDeletedPackages,
  deleted_packages,
  setPackageDetailArray,
  customer_s,
  setCheckoutDataDialog,
  setSelectedTime,
  setSelectedDate,
  setSelectedService,
  setSelectedStaff,
  setNoteAddPopUp,
  note_add_pop_up,
  setSessionNote,
  session_note,
  addPackage,
  save_button_disabled,
  setSaveButtonDisabled,
  saveSingleReceipt,
  new_package_sales_dialog_modal,
  customer,
  getSingleReceipt,
  goBack,
  saveSingleTransactionNote,
  setPackageUpdateDate,
}) => {
  const params = useParams();
  const context = useContext(AppContext);

  useEffect(() => {
    setDeletedPackages([
      ...deleted_packages,
      deleted_packages_services_transaction_package_id,
    ]);
  }, [deleted_packages_services_transaction_package_id]);

  return (
    <AlertDialog
      open={package_detail_dialog_modal}
      title={
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
          }}
        >
          <Clear
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPackageDetailDialogModal(false);
            }}
          />
        </div>
      }
      disableBackdropClick={false}
      fullWidth={true}
      maxWidth={false}
      closeHandler={() => {
        setPackageDetailDialogModal(false);
      }}
    >
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-12">
          {/* Paket başlığı */}
          <ServicesSectionHeading
            style={{
              backgroundColor: "rgba(140,15,180, 0.1)",
              color: "#8c0fb4",
              border: "1px solid #8c0fb4",
            }}
          >
            <div
              style={{
                justifySelf: "flex-start",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "10vw",
                cursor: "pointer",
              }}
              onClick={() => {
                setNewPackageSalesDialogModal(true);
              }}
            >
              <AddBox />
              {/* Yeni Paket Satışı */}
              {context.t(
                `['receipt/detail/id'].serviceTransactions.soldTitle`
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "0 auto",
              }}
            >
              <ShoppingBasket />
              <h3 style={{ marginLeft: "15px" }}>
                {context.t(
                  `['receipt/detail/id'].serviceTransactions.packageTitle`
                )}
              </h3>
            </div>
          </ServicesSectionHeading>
        </Column>

        <Column
          className="xs-12 sm-12 md-12 lg-12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              height: "auto",
              width: "80%",
              margin: "0 auto",
              overflow: "hidden",
            }}
          >
            <Column className={`xs-12 sm-12 md-4 lg-12`}>
              <Services
                className="no-scroll-bar"
                style={{ boxShadow: "inset 0 0 5px purple" }}
              >
                {checkout_data.groups
                  .filter((el) => el !== undefined)
                  .map((data, indexGroup) => (
                    <PackageDetailContainer
                      key={indexGroup}
                      style={{
                        transition: "all 0.3s ease",
                      }}
                    >
                      <Grid
                        className="sx-12 sm-12 md-12 lg-12 group_header"
                        style={{
                          width: "100%",
                          paddingTop: "10px",
                          background:
                            "linear-gradient(0deg, rgba(254,0,255,0) 0%, rgba(140,15,180, 0.1) 90%)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderTop: "1px solid rgba(140,15,180, 0.4)",
                        }}
                      >
                        <Column className="xs-12 sm-12 md-12 lg-2">
                          <MuiThemeProvider theme={themeForDatePicker}>
                            <DatePickerInput
                              className="mb-1"
                              label={context.t(
                                `['receipt/detail/id'].serviceTransactions.packageDateInputLabel`
                              )}
                              error={false}
                              value={data.process_date}
                              onChange={(date) => {
                                setSelectedPackageUpdateDate(moment(date).format("YYYY-MM-DD"));
                                setSelectedPackageTransitionId(data.id);
                                setServicesUpdateDateModal(true);
                                setPackageUpdateDate(moment(date).diff(data.process_date, "days"));
                              }}
                            />
                          </MuiThemeProvider>
                        </Column>

                        <Column className="xs-12 sm-12 md-12 lg-2">
                          <ReportsHeader
                            onClick={() => {
                              if (
                                packet_detail_array.includes(
                                  indexGroup
                                )
                              ) {
                                setPacketDetailArray(packet_detail_array.filter((index) => index !== indexGroup))
                              } else {
                                setPacketDetailArray([
                                  ...packet_detail_array,
                                  indexGroup
                                ]);
                              }
                            }}
                          >
                            <p
                              style={{
                                color: data.is_passive ? "red" : "black",
                              }}
                            >{`${data.package_name}`}</p>
                            <p>
                              &nbsp;
                              {`(${Object.keys(checkout_data.packages)
                                .filter(
                                  (key) =>
                                    checkout_data.packages[key] !==
                                    undefined
                                )
                                .filter(
                                  (key) =>
                                    checkout_data.packages[key]
                                      .receipt_package_id === data.id
                                ).length
                                })`}
                            </p>
                          </ReportsHeader>
                        </Column>

                        <Column className="xs-12 sm-12 md-12 lg-2">
                          <StaffSelect
                            className="mb-1"
                            disabled={
                              context.state.user.permission === 0
                            }
                            label={context.t(
                              `['receipt/detail/id'].serviceTransactions.staffInputLabel`
                            )}
                            items={staffs}
                            selected={data.staff_id ? data.staff_id : ""}
                            labelKey="full_name"
                            valueKey="id"
                            handler={(staff_id) => {
                              setCheckoutData({
                                ...checkout_data,
                                groups: [
                                  ...checkout_data.groups.map(
                                    (item) =>
                                      item.id === data.id
                                        ? {
                                          ...item,
                                          staff_id: parseInt(staff_id),
                                        }
                                        : { ...item }
                                  ),
                                ],
                                groupsAmount: Object.assign(
                                  {},
                                  Object.values(
                                    checkout_data.groupsAmount
                                  ).map((item, index) =>
                                    item.id === data.id
                                      ? {
                                        ...item,
                                        staff_id: parseInt(staff_id),
                                      }
                                      : { ...item }
                                  )
                                ),
                                packages: Object.assign({}, [
                                  ...Object.values(
                                    checkout_data.packages
                                  ).filter(
                                    (item) =>
                                      item.receipt_package_id !== data.id
                                  ),
                                  ...Object.values(
                                    checkout_data.packages
                                  )
                                    .filter(
                                      (item) =>
                                        item.receipt_package_id === data.id
                                    )
                                    .map((item, key) => ({
                                      ...item,
                                      staff_id: parseInt(staff_id),
                                    })),
                                ]),
                              });
                              setPackageChanges(true);
                            }}
                          />
                        </Column>

                        <Column
                          className="xs-12 sm-12 md-12 lg-3"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <InfoCard
                            style={{
                              backgroundColor: "rgba(249,186,132, 0.21)",
                              border: "1px solid rgba(249,186,132, 0.51)",
                              color: "rgb(219,146,92)",
                            }}
                            onClick={async () => {
                              await setSelectedPackageTransitionId(data.id);
                              await setSelectedPackageTransitionUpdateState(2);
                              await setServicesUpdateModal(true);
                            }}
                          >
                            <InfoCardText>
                              <span>
                                {
                                  Object.keys(checkout_data.packages)
                                    .filter(
                                      (key) =>
                                        checkout_data.packages[key] !==
                                        undefined
                                    )
                                    .filter(
                                      (key) =>
                                        checkout_data.packages[key]
                                          .receipt_package_id === data.id &&
                                        checkout_data.packages[key]
                                          .customer_state === 2
                                    ).length
                                }
                              </span>
                              <img src={Waiting} alt="waiting" />
                            </InfoCardText>
                          </InfoCard>

                          <InfoCard
                            style={{
                              backgroundColor: "rgb(29, 139, 62, 0.21)",
                              border: "1px solid rgb(58, 154, 87, 0.35)",
                              color: "rgb(29, 139, 62)",
                            }}
                            onClick={async () => {
                              await setSelectedPackageTransitionId(data.id);
                              await setSelectedPackageTransitionUpdateState(0);
                              await setServicesUpdateModal(true);
                            }}
                          >
                            <InfoCardText>
                              <span>
                                {
                                  Object.keys(checkout_data.packages)
                                    .filter(
                                      (key) =>
                                        checkout_data.packages[key] !==
                                        undefined
                                    )
                                    .filter(
                                      (key) =>
                                        checkout_data.packages[key]
                                          .receipt_package_id === data.id &&
                                        checkout_data.packages[key]
                                          .customer_state === 0
                                    ).length
                                }
                              </span>
                              <img src={HomeChcek} alt="home-check" />
                            </InfoCardText>
                          </InfoCard>

                          <InfoCard
                            style={{
                              backgroundColor: "rgb(254, 151, 151, 0.19)",
                              border: "1px solid rgb(185, 178, 178, 0.65)",
                              color: "rgb(254, 111, 111)",
                            }}
                            onClick={async () => {
                              await setSelectedPackageTransitionId(data.id);
                              await setSelectedPackageTransitionUpdateState(1);
                              await setServicesUpdateModal(true);
                            }}
                          >
                            <InfoCardText>
                              <span>
                                {
                                  Object.keys(checkout_data.packages)
                                    .filter(
                                      (key) =>
                                        checkout_data.packages[key] !==
                                        undefined
                                    )
                                    .filter(
                                      (key) =>
                                        checkout_data.packages[key]
                                          .receipt_package_id === data.id &&
                                        checkout_data.packages[key]
                                          .customer_state === 1
                                    ).length
                                }
                              </span>
                              <img src={NotCome} alt="note-come" />
                            </InfoCardText>
                          </InfoCard>
                        </Column>

                        <Column className="xs-12 sm-12 md-12 lg-2">
                          <MaskedInput
                            className="mb-1"
                            label={
                              context.t(
                                `['receipt/detail/id'].serviceTransactions.amountInputLabel`
                              ) +
                              (context.state.currency
                                ? " (" + context.state.currency + ")"
                                : "(₺)")
                            }
                            mask="9999999999999"
                            maskChar=""
                            value={
                              checkout_data.groupsAmount[indexGroup]
                                .amount !== null
                                ? checkout_data.groupsAmount[indexGroup]
                                  .amount
                                : ""
                            }
                            onChange={(e) => {
                              let dividedBy = Object.values(
                                checkout_data.packages
                              ).filter(
                                (el) => el.receipt_package_id === data.id
                              ).length;

                              setCheckoutData({
                                ...checkout_data,
                                groupsAmount: {
                                  ...checkout_data.groupsAmount,
                                  [indexGroup]: {
                                    ...checkout_data.groupsAmount[
                                    indexGroup
                                    ],
                                    amount: /[0-9,.]+/.test(e.target.value)
                                      ? Math.round(
                                        parseFloat(e.target.value)
                                      )
                                      : null,
                                  },
                                },
                                groups: checkout_data.groups.map((item) =>
                                  item.id !==
                                    checkout_data.groups[indexGroup].id
                                    ? item
                                    : {
                                      ...item,
                                      amount: /[0-9,.]+/.test(
                                        e.target.value
                                      )
                                        ? Math.round(
                                          parseFloat(e.target.value)
                                        )
                                        : null,
                                    }
                                ),
                                packages: {
                                  ...Object.assign({}, [
                                    ...Object.values(
                                      checkout_data.packages
                                    )
                                      .filter(
                                        (el) =>
                                          el.receipt_package_id === data.id
                                      )
                                      .map((item) => ({
                                        ...item,
                                        amount: e.target.value / dividedBy,
                                      })),
                                    ...Object.values(
                                      checkout_data.packages
                                    ).filter(
                                      (el) =>
                                        el.receipt_package_id !== data.id
                                    ),
                                  ]),
                                },
                              });
                            }}
                          />
                        </Column>

                        <Column
                          style={{ display: "flex" }}
                          className="xs-12 sm-12 md-12 lg-1"
                        >
                          <Button
                            icon="delete_forever"
                            iconSize={21}
                            textColor="red"
                            fullWidth
                            size="lg"
                            onClick={async (id) => {
                              setDeletePackageId(checkout_data.groups[indexGroup].id);
                              setPackageDeleteModal(true);
                              setDeletedPackagesServicesTransactionPackageId(checkout_data.groups[indexGroup].id);
                            }}
                          />
                          <div
                            style={{ cursor: "pointer", marginTop: "17px" }}
                            onClick={() => {
                              if (
                                packet_detail_array.includes(
                                  indexGroup
                                )
                              ) {
                                setPackageDetailArray(
                                  packet_detail_array.filter(
                                    (index) => index !== indexGroup
                                  ),
                                );
                              } else {
                                setPackageDetailArray([
                                  ...packet_detail_array,
                                  indexGroup,
                                ]);
                              }
                            }}
                          >
                            {!packet_detail_array.includes(
                              indexGroup
                            ) ? (
                              <ExpandLessIcon color="secondary" />
                            ) : (
                              <ExpandMoreIcon color="primary" />
                            )}
                          </div>
                        </Column>
                      </Grid>

                      <Grid>
                        {!packet_detail_array.includes(
                          indexGroup
                        ) && (
                            <Column className="xs-12 sm-12 md-12 lg-12 pr-5 pl-5">
                              {
                                //Paket Listesi
                                checkout_data.groups.length > 0 ? (
                                  Object.keys(checkout_data.packages)
                                    .filter(
                                      (key) =>
                                        checkout_data.packages[key]
                                          .receipt_package_id === data.id &&
                                        checkout_data.packages[key]
                                          .process_type === 1
                                    )
                                    .map((key, index) => (
                                      <Grid
                                        className="no-gutters-row"
                                        key={index}
                                        style={{
                                          paddingTop: "16px !important",
                                        }}
                                      >
                                        <MuiThemeProvider
                                          theme={themeForCardDetail}
                                        >
                                          <Tooltip
                                            placement="top-start"
                                            title={
                                              checkout_data.packages[key]
                                                .session_note === ""
                                                ? ""
                                                : checkout_data.packages[key]
                                                  .session_note
                                            }
                                            arrow
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              padding: 0,
                                              position: "relative",
                                              backgroundColor:
                                                "white !important",
                                            }}
                                          >
                                            <div>
                                              <Column className="xs-12 sm-12 md-12 mt-1 lg-3">
                                                <MuiThemeProvider
                                                  theme={themeForDatePicker}
                                                >
                                                  <DatePickerInput
                                                    className="mb-1"
                                                    // label={context.t(
                                                    //   `['receipt/detail/id'].serviceTransactions.dateInputLabel`
                                                    // )}
                                                    label={`${context.t(
                                                      `['receipt/detail/id'].serviceTransactions.dateInputLabel`
                                                    )} / ${index + 1}. seans`}
                                                    error={false}
                                                    value={
                                                      moment(
                                                        checkout_data.packages[
                                                          key
                                                        ].process_date
                                                      ).format("YYYY-MM-DD") ===
                                                        "0001-01-01"
                                                        ? null
                                                        : moment(
                                                          checkout_data
                                                            .packages[key]
                                                            .process_date
                                                        )
                                                    }
                                                    // disablePast
                                                    onChange={(
                                                      process_date
                                                    ) => {
                                                      setCheckoutData({
                                                        ...checkout_data,
                                                        packages: {
                                                          ...checkout_data.packages,
                                                          [key]: {
                                                            ...checkout_data
                                                              .packages[key],
                                                            process_date:
                                                              moment(
                                                                process_date
                                                              ).format(
                                                                "YYYY-MM-DD"
                                                              ),
                                                          },
                                                        }
                                                      });
                                                      setPackageChanges(true);
                                                    }}
                                                  />
                                                </MuiThemeProvider>
                                              </Column>
                                              <Column className="xs-12 sm-12 md-12 lg-3">
                                                <Select
                                                  className="mb-1"
                                                  label={context.t(
                                                    `['receipt/detail/id'].serviceTransactions.nameInputLabel`
                                                  )}
                                                  title={
                                                    services.filter(
                                                      (item) =>
                                                        item.id ===
                                                        checkout_data.packages[
                                                          key
                                                        ].service_id
                                                    ).length > 0
                                                      ? services.filter(
                                                        (item) =>
                                                          item.id ===
                                                          checkout_data
                                                            .packages[key]
                                                            .service_id
                                                      )[0].name
                                                      : context.t(`["receipt/detail/id"].deletedService`)
                                                  }
                                                  items={staffs
                                                    .filter(
                                                      (staff) =>
                                                        staff.id ===
                                                        checkout_data.packages[
                                                          key
                                                        ].staff_id
                                                    )[0]
                                                    .services.map(
                                                      (service) =>
                                                        service?.service
                                                    )}
                                                  selected={
                                                    checkout_data.packages[key]
                                                      .service_id
                                                      ? services.filter(
                                                        (item) =>
                                                          item.id ===
                                                          checkout_data
                                                            .packages[key]
                                                            .service_id
                                                      ).length
                                                        ? checkout_data
                                                          .packages[key]
                                                          .service_id
                                                        : context.t(`["receipt/detail/id"].deletedService`)
                                                      : context.t(`["receipt/detail/id"].deletedService`)
                                                  }
                                                  labelKey="name"
                                                  valueKey="id"
                                                  handler={(service_id) => {
                                                    setCheckoutData({
                                                      ...checkout_data,
                                                      packages: {
                                                        ...checkout_data.packages,
                                                        [key]: {
                                                          ...checkout_data
                                                            .packages[key],
                                                          service_id:
                                                            parseInt(
                                                              service_id
                                                            ),
                                                        },
                                                      }
                                                    });
                                                    setPackageChanges(true);
                                                  }}
                                                />
                                              </Column>

                                              <Column className="xs-12 sm-12 md-12 lg-3">
                                                <StaffSelect
                                                  className="mb-1"
                                                  disabled={
                                                    context.state.user
                                                      .permission === 0
                                                  }
                                                  label={context.t(
                                                    `['receipt/detail/id'].serviceTransactions.staffInputLabel`
                                                  )}
                                                  items={staffs}
                                                  selected={
                                                    checkout_data.packages[key]
                                                      .staff_id
                                                      ? checkout_data.packages[
                                                        key
                                                      ].staff_id
                                                      : ""
                                                  }
                                                  labelKey="full_name"
                                                  valueKey="id"
                                                  handler={(staff_id) => {
                                                    setCheckoutData({
                                                      ...checkout_data,
                                                      packages: {
                                                        ...checkout_data.packages,
                                                        [key]: {
                                                          ...checkout_data
                                                            .packages[key],
                                                          staff_id:
                                                            parseInt(
                                                              staff_id
                                                            ),
                                                        },
                                                      }
                                                    });
                                                    setPackageChanges(true);
                                                  }}
                                                />
                                              </Column>

                                              <Column className="xs-12 sm-12 md-12 lg-2">
                                                <Select
                                                  className="mb-1"
                                                  disabled={
                                                    context.state.user
                                                      .permission === 0
                                                  }
                                                  label={context.t(`sms.deviceStatus`)}
                                                  items={customer_s}
                                                  selected={
                                                    checkout_data.packages[key]
                                                      .customer_state !== null
                                                      ? `${checkout_data.packages[key].customer_state}`
                                                      : ""
                                                  }
                                                  labelKey="label"
                                                  valueKey="value"
                                                  handler={(customer_state) => {
                                                    setCheckoutData({
                                                      ...checkout_data,
                                                      packages: {
                                                        ...checkout_data.packages,
                                                        [key]: {
                                                          ...checkout_data
                                                            .packages[key],
                                                          customer_state:
                                                            parseInt(
                                                              customer_state
                                                            ),
                                                        },
                                                      }
                                                    });
                                                  }}
                                                />
                                              </Column>
                                              <Column className="xs-12 sm-12 md-12 lg-1">
                                                {/* delete session button */}
                                                <Column className="xs-12 sm-12 md-12 lg-4 mt-1">
                                                  <Button
                                                    icon="delete_forever"
                                                    iconSize={21}
                                                    textColor="red"
                                                    fullWidth
                                                    size="lg"
                                                    onClick={async () => {
                                                      delete checkout_data
                                                        .packages[key];
                                                      setCheckoutData({
                                                        ...checkout_data
                                                      });
                                                      // Paketteki son hizmet silinirse paketi de siler
                                                      setTimeout(() => {
                                                        if (
                                                          !Object.values(
                                                            checkout_data.packages
                                                          ).filter(
                                                            (item) =>
                                                              item.receipt_package_id ===
                                                              checkout_data
                                                                .groups[
                                                                indexGroup
                                                              ].id
                                                          ).length > 0
                                                        ) {
                                                          setDeletedPackages([
                                                            ...deleted_packages,
                                                            checkout_data
                                                              .groups[
                                                              indexGroup
                                                            ].id,
                                                          ]);
                                                          setCheckoutData({
                                                            ...checkout_data,
                                                            groups: [
                                                              ...checkout_data.groups.filter(
                                                                (item) =>
                                                                  item.id !==
                                                                  checkout_data
                                                                    .groups[
                                                                    indexGroup
                                                                  ].id
                                                              ),
                                                            ]
                                                          });
                                                        }
                                                      }, 1000);
                                                    }}
                                                  />
                                                </Column>
                                                {/* Create appointment button */}
                                                <Column className="xs-12 sm-12 md-12 lg-4 mt-1">
                                                  <Button
                                                    icon="event"
                                                    iconSize={21}
                                                    textColor="blue"
                                                    size="lg"
                                                    fullWidth
                                                    onClick={() => {
                                                      if (
                                                        packageChanges
                                                      ) {
                                                        toast.warning(
                                                          context.t(`["receipt/detail/id"].saveBeforeGetAppointmentWarnToast`)
                                                        );
                                                      } else {
                                                        setCheckoutDataDialog(true);
                                                        setSelectedTime(
                                                          moment().hour() +
                                                          ":" +
                                                          moment().minutes()
                                                        );
                                                        setSelectedDate(
                                                          new Date(
                                                            checkout_data.packages[
                                                              key
                                                            ].process_date
                                                          ).toISOString()
                                                        );
                                                        setSelectedService(
                                                          checkout_data
                                                            .packages[key]
                                                            .service_id
                                                        );
                                                        setSelectedStaff(
                                                          checkout_data
                                                            .packages[key]
                                                            .staff_id
                                                        );
                                                      }
                                                    }}
                                                  />
                                                </Column>
                                                {/* make a note button */}
                                                <Column className="xs-12 sm-12 lg-4 mt-1">
                                                  <Button
                                                    className="button-hover-note-add"
                                                    icon="note_add"
                                                    iconSize={21}
                                                    textColor="blue"
                                                    size="lg"
                                                    fullWidth
                                                    onClick={(row) => {
                                                      setNoteAddPopUp(true);
                                                      setSessionNote({
                                                        ...session_note,
                                                        session_id:
                                                          checkout_data
                                                            .packages[key]
                                                            .receipt_transaction_uniqe_id,
                                                        key: key,
                                                      });
                                                    }}
                                                  />
                                                </Column>
                                              </Column>
                                            </div>
                                          </Tooltip>
                                        </MuiThemeProvider>
                                      </Grid>
                                    ))
                                ) : (
                                  <NoDataContainer style={{ height: "70%" }}>
                                    <img
                                      src={require("../../../../assets/images/manypixels/click_action.svg")}
                                      alt="Malesef bulunamadı!"
                                    />
                                    <p>
                                      {context.t(
                                        `['receipt/detail/id'].serviceTransactions.noDataPackageText`
                                      )}
                                    </p>
                                  </NoDataContainer>
                                )
                              }
                            </Column>
                          )}
                      </Grid>
                    </PackageDetailContainer>
                  ))}

                {checkout_data.groups.length === 0 ? (
                  <NoDataContainer style={{ height: "70%" }}>
                    <img
                      src={require("../../../../assets/images/no-data-img.png")}
                      alt="Malesef bulunamadı!"
                    />
                    <p>
                      {context.t(
                        `['receipt/detail/id'].serviceTransactions.noDataPackageText`
                      )}
                    </p>
                  </NoDataContainer>
                ) : (
                  <></>
                )}
              </Services>
            </Column>
          </div>
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
          <ButtonContainer
            style={{ display: `${addPackage ? "none" : ""}` }}
          >
            <Column className="xs-12 sm-12 md-12 lg-3">
              <Button
                icon="check"
                title={context.t(`receipts.detailButtons.confirmButton`)}
                backgroundColor="primary"
                textColor="white"
                disabled={save_button_disabled}
                fullWidth
                onClick={() => {
                  setPackageChanges(false);
                  setSaveButtonDisabled(true);
                  saveSingleReceipt();
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <Button
                icon="close"
                title={context.t(`receipts.detailButtons.closeButton`)}
                backgroundColor="white"
                textColor="black"
                fullWidth
                outlined
                onClick={() => {
                  setPackageDetailDialogModal(false);
                }}
              />
            </Column>
          </ButtonContainer>
        </Column>
      </Grid>

      <AlertDialog
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3>{context.t(`scaffold.QAItems.createPackageSale`)}</h3>
            <Clear
              style={{ cursor: "pointer" }}
              onClick={() => {
                setNewPackageSalesDialogModal(false);
              }}
            />
          </div>
        }
        open={new_package_sales_dialog_modal}
        disableBackdropClick={false}
        fullWidth={true}
        maxWidth={false}
        closeHandler={() => {
          setNewPackageSalesDialogModal(false);
        }}
      >
        <Grid>
          <PackageSalesAdd
            customer={customer.id}
            inReceipt={true}
            receipt_id={parseInt(params.id)}
            getUpdatedReceipt={getSingleReceipt}
            goBack={goBack}
          />
        </Grid>
      </AlertDialog>

      <AlertDialog
        title={"Not ekle"}
        maxWidth={"sm"}
        fullWidth
        closeHandler={() => {
          setNoteAddPopUp(false);
        }}
        open={note_add_pop_up}
        buttons={[
          {
            title: context.t(`["receipt/detail/id"].stockSale.addButtonTitle`),
            backgroundColor: "primary",
            textColor: "white",
            onClick: () => {
              saveSingleTransactionNote();
            },
          },
          {
            title: context.t(`["receipt/detail/id"].stockSale.discardButtonTitle`),
            textColor: "black",
            style: {
              border: "1px solid #666666",
            },
            onClick: () => {
              setSessionNote({
                value: "",
                session_id: null,
              });
              setNoteAddPopUp(false);
            },
          },
        ]}
      >
        <TextField
          label={context.t(`dashboard.onlineAppointments.headings.note`)}
          variant="outlined"
          value={session_note.value}
          inputProps={{ maxLength: 90 }}
          fullWidth
          multiline
          helperText={`${context.t(`["receipt/detail/id"].charLimitTitle`)} ${90 - parseInt(session_note.value.length)
            }`}
          onChange={(e) => {
            setSessionNote({
              ...session_note,
              value: e.target.value,
            });
          }}
        />
      </AlertDialog>
    </AlertDialog>
  );
};
// Product Dialog
export const _TypeProductDetailDialog = ({
  product_detail_dialog_modal,
  product_transaction,
  checkout_data,
  service_transaction,
  setProductDetailDialogModal,
  setProductsBySelectedStaffShow,
  staffs,
  setServiceTransaction,
  setServicesBySelectedStaffShow,
  setServicesBySelectedStaff,
  products_by_selected_staff_show,
  stock_items,
  _product_transaction,
  setProductTransaction,
  addSingleStockSaleIntoCheckout,
  setCheckoutData,
  saveSingleReceipt,
  setPackageChanges,
}) => {
  const context = useContext(AppContext);
  return (
    <AlertDialog
      open={product_detail_dialog_modal}
      title={
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
          }}
        >
          <Clear
            style={{ cursor: "pointer" }}
            onClick={() => {
              setProductDetailDialogModal(false);
              setProductsBySelectedStaffShow(false);
            }}
          />
        </div>
      }
      disableBackdropClick={false}
      fullWidth={true}
      maxWidth={false}
      // maxWidth="lg"
      closeHandler={() => {
        setProductDetailDialogModal(false);
      }}
    >
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-12">
          {/* Servis başlığı */}
          <Column className="xs-12 sm-12 md-12 lg-4">
            <ServicesSectionHeading
              style={{
                backgroundColor: "rgba(13, 78, 108, 0.04)",
                color: "#3189ec",
                border: "1px solid #3189ec",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <AddBox />
                <h3 style={{ marginLeft: "15px" }}>
                  {context.t(
                    `['receipt/detail/id'].productTransactions.productSaleTitle`
                  )}

                  {/* Yeni Ürün Satışı */}
                </h3>
              </div>
            </ServicesSectionHeading>
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-8">
            <ServicesSectionHeading
              style={{
                backgroundColor: "rgba(13, 78, 108, 0.04)",
                color: "#3189ec",
                border: "1px solid #3189ec",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Layers />
                <h3 style={{ marginLeft: "15px" }}>
                  {context.t(`['receipt/detail/id'].stockSales.title`)}
                </h3>
              </div>
            </ServicesSectionHeading>
          </Column>
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-12">
          <Column className="xs-12 sm-12 md-12 lg-4">
            <StaffList className="no-scroll-bar">
              {staffs.map((staff) => (
                <MuiThemeProvider theme={selectedmuiTheme}>
                  <ListItem
                    button
                    selected={
                      staff.id == service_transaction.staff_id
                    }
                    onClick={() => {
                      setServiceTransaction({
                        service_id: null,
                        amount: null,
                        first_amount: null,
                        process_date: null,
                        process_type: null,
                        staff_id: parseInt(staff.id),
                      });
                      setServicesBySelectedStaffShow(false);
                      setServicesBySelectedStaff([]);
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          color: `${staff.id ==
                            service_transaction.staff_id
                            ? "white"
                            : "#3189ec"
                            }`,
                        },
                      }}
                      primary={`${staff.name} ${staff.surname}`}
                    />
                  </ListItem>
                </MuiThemeProvider>
              ))}
            </StaffList>
            <Column className="xs-12 sm-12">
              <Button
                className="mt-1 mb-1 mx-auto"
                fullWidth
                backgroundColor="primary"
                icon="search"
                // size="lg"
                textColor="white"
                title={context.t(`["receipt/detail/id"].getProducts`)}
                disabled={!service_transaction.staff_id}
                onClick={() => {
                  setProductsBySelectedStaffShow(true);
                }}
              />
            </Column>
            {products_by_selected_staff_show && (
              <div
                style={{
                  overflow: "hidden",
                  border: "1px solid white",
                }}
              >
                <ServicesList className="no-scroll-bar">
                  {stock_items.map((item) => (
                    <MuiThemeProvider theme={selectedmuiTheme}>
                      <ListItem
                        button
                        selected={
                          item.id == product_transaction.stock_item_id
                        }
                        onClick={() => {
                          //consle.log(item)
                          setProductTransaction({
                            ..._product_transaction,
                            staff_id: service_transaction.staff_id,
                            is_passive: item.is_passive,
                            stock_item_id: parseInt(item.id),
                            amount: Math.round(item.amount),
                          });
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              color: `${item.id == product_transaction.stock_item_id
                                ? "white"
                                : "#3189ec"
                                }`,
                            }}
                          >{`${item.name} (${item.limit})`}</p>
                          <p
                            style={{
                              color: `${item.id == product_transaction.stock_item_id
                                ? "white"
                                : "#3189ec"
                                }`,
                            }}
                          >{`${item.amount} ${context.state.currency
                            ? context.state.currency
                            : "₺"
                            }`}</p>
                        </div>
                      </ListItem>
                    </MuiThemeProvider>
                  ))}
                </ServicesList>
              </div>
            )}
            {products_by_selected_staff_show && (
              <Column className="xs-12 sm-12 md-4 lg-12 mt-2">
                <Column className="xs-12 sm-12 md-4 lg-6">
                  <Button
                    icon="add"
                    title={context.t(`["receipt/detail/id"].stockSale.addButtonTitle`)}
                    disabled={!product_transaction.staff_id}
                    backgroundColor="primary"
                    textColor="white"
                    fullWidth
                    onClick={async () => {
                      await addSingleStockSaleIntoCheckout();
                      await setProductTransaction({
                        ..._product_transaction,
                        staff_id: null,
                        is_passive: null,
                        stock_item_id: null,
                        amount: null,
                      });
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-4 lg-6">
                  <Button
                    icon="clear"
                    title={context.t(`["receipt/detail/id"].clear`)}
                    textColor="#ccc"
                    outlined={true}
                    fullWidth
                    onClick={() => {
                      setServiceTransaction({
                        staff_id: null,
                        service_id: null,
                        amount: null,
                        first_amount: null,
                        process_date: null,
                        process_type: null,
                      });
                      setProductsBySelectedStaffShow(false);
                    }}
                  />
                </Column>
              </Column>
            )}
          </Column>

          <Column className="xs-12 sm-12 md-8 lg-8">
            <Services
              className="no-scroll-bar"
              style={{
                boxShadow: "inset 0 0 5px #3189ec",
              }}
            >
              {
                // Ürün Listesi
                Object.keys(checkout_data.sales).filter(
                  (key) => checkout_data.sales[key] !== undefined
                ).length ? (
                  Object.keys(checkout_data.sales)
                    .filter((key) => checkout_data.sales[key] !== undefined)
                    .map((key) => (
                      <Grid
                        className="no-gutters-row"
                        style={{ margin: "8px" }}
                      >
                        <Column className="xs-12 sm-12 md-12 mt-1 lg-2">
                          <MuiThemeProvider theme={themeForDatePicker}>
                            <DatePickerInput
                              className="mb-1"
                              label={context.t(
                                `['receipt/detail/id'].serviceTransactions.dateInputLabel`
                              )}
                              error={false}
                              value={
                                moment(
                                  checkout_data.sales[key].sold_date
                                ).format("YYYY-MM-DD") === "0001-01-01"
                                  ? null
                                  : moment(checkout_data.sales[key].sold_date)
                              }
                              disabled={true}
                              onChange={(process_date) => {
                                setCheckoutData({
                                  ...checkout_data,
                                  sales: {
                                    ...checkout_data.sales,
                                    [key]: {
                                      ...checkout_data.sales[key],
                                      sold_date:
                                        moment(process_date).toISOString(),
                                    },
                                  }
                                });
                              }}
                            />
                          </MuiThemeProvider>
                        </Column>

                        <Column className="xs-12 sm-12 md-12 lg-3">
                          <Input
                            readOnly
                            label={context.t(
                              `['receipt/detail/id'].stockSales.nameInputLabel`
                            )}
                            inputProps={{
                              style: {
                                color: `${checkout_data.sales[key].is_passive !== true ? "red" : "black"}`,
                              },
                            }}
                            value={
                              checkout_data.sales[key].stock_item_id
                                ? stock_items.filter(
                                  (item) =>
                                    item.id ===
                                    checkout_data.sales[key].stock_item_id
                                ).length
                                  ? stock_items.filter(
                                    (item) =>
                                      item.id ===
                                      checkout_data.sales[key].stock_item_id
                                  )[0].name
                                  : context.t(
                                    `['receipt/detail/id'].stockSales.deletedName`
                                  )
                                : context.t(
                                  `['receipt/detail/id'].stockSales.deletedName`
                                )
                            }
                          />
                        </Column>

                        <Column className="xs-12 sm-12 md-12 lg-2">
                          <StaffSelect
                            disabled={
                              context.state.user.permission === 0
                            }
                            label={context.t(
                              `['receipt/detail/id'].stockSales.staffInputLabel`
                            )}
                            items={staffs}
                            selected={
                              checkout_data.sales[key].staff_id
                                ? checkout_data.sales[key].staff_id
                                : null
                            }
                            labelKey="full_name"
                            valueKey="id"
                            handler={(staff_id) => {
                              setCheckoutData({
                                ...checkout_data,
                                sales: {
                                  ...checkout_data.sales,
                                  [key]: {
                                    ...checkout_data.sales[key],
                                    staff_id: parseInt(staff_id),
                                  },
                                }
                              });
                            }}
                          />
                        </Column>

                        {/* INPUTCHECK */}
                        <Column className="xs-12 sm-12 md-12 lg-2">
                          <Input
                            label={context.t(
                              `['receipt/detail/id'].stockSales.countInputLabel`
                            )}
                            value={checkout_data.sales[key].quantity}
                            onChange={(e) => {
                              setCheckoutData({
                                ...checkout_data,
                                sales: {
                                  ...checkout_data.sales,
                                  [key]: {
                                    ...checkout_data.sales[key],
                                    quantity:
                                      e.target.value &&
                                        /[0-9]+/.test(e.target.value)
                                        ? parseInt(e.target.value)
                                        : 0,
                                  },
                                }
                              });
                            }}
                          />
                        </Column>

                        <Column className="xs-12 sm-12 md-12 lg-2">
                          <Input
                            readOnly
                            type="number"
                            label={
                              context.t(
                                `['receipt/detail/id'].stockSales.amountInputLabel`
                              ) +
                              (context.state.currency
                                ? " (" + context.state.currency + ")"
                                : "(₺)")
                            }
                            value={checkout_data.sales[key].amount}
                            onChange={(e) => {
                              setCheckoutData({
                                ...checkout_data,
                                sales: {
                                  ...checkout_data.sales,
                                  [key]: {
                                    ...checkout_data.sales[key],
                                    amount:
                                      e.target.value &&
                                        /[0-9,.]+/.test(e.target.value)
                                        ? parseFloat(e.target.value)
                                        : 0,
                                  },
                                },
                              });
                            }}
                          />
                        </Column>

                        <Column className="xs-12 sm-12 md-12 lg-1">
                          <Button
                            icon="delete_forever"
                            iconSize={21}
                            textColor="red"
                            size="lg"
                            fullWidth
                            onClick={() => {
                              setCheckoutData({
                                ...checkout_data,
                                sales: {
                                  ...checkout_data.sales,
                                  [key]: undefined,
                                },
                              })
                            }}
                          />
                        </Column>
                      </Grid>
                    ))
                ) : (
                  <NoDataContainer style={{ height: "70%" }}>
                    <img
                      src={require("../../../../assets/images/no-data-img.png")}
                      alt="Malesef bulunamadı!"
                    />
                    <p>
                      {context.t(
                        `['receipt/detail/id'].stockSales.noDataText`
                      )}
                    </p>
                  </NoDataContainer>
                )
              }
            </Services>
          </Column>
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-12 m-4">
          <ButtonContainer>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <Button
                icon="check"
                title={context.t(`receipts.detailButtons.confirmButton`)}
                backgroundColor="primary"
                textColor="white"
                fullWidth
                onClick={() => {
                  saveSingleReceipt();
                  setPackageChanges(false);
                  setProductDetailDialogModal(false);
                  setProductTransaction({
                    staff_id: null,
                    stock_item_id: null,
                    amount: null,
                  });
                  setProductsBySelectedStaffShow(false);
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <Button
                icon="close"
                title={context.t(`receipts.detailButtons.closeButton`)}
                backgroundColor="white"
                textColor="black"
                fullWidth
                outlined
                onClick={() => {
                  setProductDetailDialogModal(false);
                  setProductTransaction({
                    staff_id: null,
                    stock_item_id: null,
                    amount: null,
                  });
                  setProductsBySelectedStaffShow(false);
                }}
              />
            </Column>
          </ButtonContainer>
        </Column>
      </Grid>
    </AlertDialog>
  );
};
// Payment Dialog
export const _TypePaymentDetailDialog = ({
  payment_detail_dialog_modal,
  checkout_data,
  installment_data,
  setPaymentDetailDialogModal,
  setAddPaymentDialog,
  setInstallmentDialog,
  setCheckoutData,
  staffs,
  setSelectedInstallmentStatus,
  setInstallmentData,
  save_button_disabled,
  setSaveButtonDisabled,
  saveSingleReceipt
}) => {
  const context = useContext(AppContext);
  return (
    <AlertDialog
      open={payment_detail_dialog_modal}
      disableBackdropClick={false}
      fullWidth={true}
      maxWidth={false}
      // maxWidth="lg"
      closeHandler={() => {
        setPaymentDetailDialogModal(false);
      }}
      backgroundColor={"rgba(246,248,249)"}
      title={
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
          }}
        >
          <Clear
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPaymentDetailDialogModal(false);
            }}
          />
        </div>
      }
    >
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-12">
          {/* Servis başlığı */}
          <Column className="xs-12 sm-12 md-12 lg-6">
            <ServicesSectionHeading
              style={{
                backgroundColor: "rgba(173,255,47,0.08)",
                color: "green",
                border: "1px solid green",
              }}
            >
              <div
                style={{
                  justifySelf: "flex-start",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setAddPaymentDialog(true);
                }}
              >
                <AddBox />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "0 auto",
                }}
              >
                <Payment />
                <h3>
                  {context.t(`['receipt/detail/id'].payments.title`)}
                </h3>
              </div>
            </ServicesSectionHeading>
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-6">
            <ServicesSectionHeading
              style={{
                backgroundColor: "rgba(255,0,0, 0.08)",
                color: "red",
                border: "1px solid red",
              }}
            >
              <div
                style={{
                  justifySelf: "flex-start",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setInstallmentDialog(true);
                }}
              >
                <AddBox />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "0 auto",
                }}
              >
                <Payment />
                <h3>{context.t(`["receipt/detail/id"].installment.title`)}</h3>
              </div>
            </ServicesSectionHeading>
          </Column>
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-12">
          <Column className="xs-12 sm-12 md-4 lg-6">
            <Services
              className="no-scroll-bar"
              style={{ boxShadow: "inset 0 0 5px green" }}
            >
              <ListSection className="no-scroll-bar p-2">
                {Object.keys(checkout_data.paids).filter(
                  (key) => checkout_data.paids[key] !== undefined
                ).length > 0 ? (
                  Object.keys(checkout_data.paids).map(
                    (key, index) =>
                      checkout_data.paids[key] !== undefined && (
                        <Grid className="no-gutters-row" key={index}>
                          <Column className="xs-12 sm-12 md-12 mt-1 lg-auto">
                            <MuiThemeProvider theme={themeForDatePicker}>
                              <DatePickerInput
                                label={context.t(
                                  `['receipt/detail/id'].payments.dateInputLabel`
                                )}
                                value={
                                  new Date(
                                    moment(
                                      checkout_data.paids[key].payment_date
                                    )
                                      .local()
                                      .toISOString()
                                  )
                                }
                                onChange={(payment_date) => {
                                  setCheckoutData({
                                    ...checkout_data,
                                    paids: {
                                      ...checkout_data.paids,
                                      [key]: {
                                        ...checkout_data.paids[key],
                                        payment_date,
                                      },
                                    }
                                  });
                                }}
                              />
                            </MuiThemeProvider>
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-3">
                            <Select
                              label={context.t(
                                `['receipt/detail/id'].payments.typeInputLabel`
                              )}
                              items={[
                                {
                                  label: context.t(
                                    `['receipt/detail/id'].payments.typeCASH`
                                  ),
                                  value: "0",
                                },
                                {
                                  label: context.t(
                                    `['receipt/detail/id'].payments.typeCARD`
                                  ),
                                  value: "1",
                                },
                                {
                                  label: context.t(
                                    `['receipt/detail/id'].payments.typeEFT`
                                  ),
                                  value: "2",
                                },
                                {
                                  label: context.t(
                                    `['receipt/detail/id'].payments.typePOINT`
                                  ),
                                  value: "3",
                                },
                              ]}
                              selected={`${checkout_data.paids[key].payment_type}`}
                              labelKey="label"
                              valueKey="value"
                              handler={(payment_type) => {
                                setCheckoutData({
                                  ...checkout_data,
                                  paids: {
                                    ...checkout_data.paids,
                                    [key]: {
                                      ...checkout_data.paids[key],
                                      payment_type: parseInt(payment_type),
                                    },
                                  }
                                });
                              }}
                            />
                          </Column>
                          <Column className="xs-12 sm-12 md-12 lg-3">
                            {/* Select Staff For Paids */}

                            <StaffSelect
                              label={context.t(`["receipt/detail/id"].serviceTransactions.staffInputLabel`)}
                              items={staffs}
                              selected={
                                checkout_data.paids[key]?.staff_id
                                  ? checkout_data.paids[key]?.staff_id
                                  : ""
                              }
                              labelKey="full_name"
                              valueKey="id"
                              handler={(staff_id) => {
                                setCheckoutData({
                                  ...checkout_data,
                                  paids: {
                                    ...checkout_data.paids,
                                    [key]: {
                                      ...checkout_data.paids[key],
                                      staff_id: parseInt(staff_id),
                                    },
                                  }
                                });
                              }}
                            />
                          </Column>
                          <Column className="xs-12 sm-12 md-12 lg-3">
                            <MaskedInput
                              label={
                                checkout_data.paids[key].payment_type === 3
                                  ? context.t(
                                    `['receipt/detail/id'].payments.pointInputLabel`
                                  )
                                  : context.t(
                                    `['receipt/detail/id'].payments.amountInputLabel`
                                  ) +
                                  (context.state.currency
                                    ? "(" +
                                    context.state.currency +
                                    ")"
                                    : "₺")
                              }
                              mask="9999999999999"
                              maskChar=""
                              value={
                                checkout_data.paids[key].pay_amount !== null
                                  ? parseFloat(
                                    checkout_data.paids[key].pay_amount
                                  )
                                  : ""
                              }
                              onChange={(e) => {
                                setCheckoutData({
                                  ...checkout_data,
                                  paids: {
                                    ...checkout_data.paids,
                                    [key]: {
                                      ...checkout_data.paids[key],
                                      pay_amount:
                                        e.target.value &&
                                          /[0-9,.]+/.test(e.target.value)
                                          ? parseFloat(e.target.value)
                                          : null,
                                    },
                                  }
                                });
                              }}
                            />
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-1">
                            <Button
                              icon="delete_forever"
                              iconSize={21}
                              textColor="red"
                              size="lg"
                              fullWidth
                              onClick={() => {
                                setCheckoutData({
                                  ...checkout_data,
                                  paids: {
                                    ...checkout_data.paids,
                                    [key]: undefined,
                                  }
                                });
                              }}
                            />
                          </Column>
                        </Grid>
                      )
                  )
                ) : (
                  <NoDataContainer>
                    <img
                      src={require("../../../../assets/images/manypixels/online_payment_.svg")}
                      alt="Malesef bulunamadı!"
                    />
                    <p>
                      {context.t(
                        `['receipt/detail/id'].payments.noDataText`
                      )}
                    </p>
                  </NoDataContainer>
                )}
              </ListSection>
            </Services>
          </Column>

          <Column className="xs-12 sm-12 md-8 lg-6">
            <Services
              className="no-scroll-bar"
              style={{ boxShadow: "inset 0 0 5px red" }}
            >
              <ListSection className="no-scroll-bar p-2">
                {installment_data.length > 0 ? (
                  <>
                    {installment_data.map((installment, index) => {
                      return (
                        <Grid className="no-gutters-row" key={index}>
                          <Column className="xs-12 sm-12 md-12 mt-1 lg-3">
                            <MuiThemeProvider theme={themeForDatePicker}>
                              <DatePickerInput
                                label={`${context.t(
                                  `['receipt/detail/id'].payments.dateInputLabel`
                                )} / ${index + 1}. taksit`}
                                value={
                                  new Date(
                                    moment(installment.installment_date)
                                      .local()
                                      .toISOString()
                                  )
                                }
                                onChange={(installment_date) => {
                                  installment_data[index].installment_date =
                                    installment_date;
                                }}
                              />
                            </MuiThemeProvider>
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-3">
                            <Select
                              label={context.t(`["accounting/credit"].headings.is_paid`)}
                              items={[
                                {
                                  label: context.t(`receipts.customerStatus.waiting`),
                                  value: "0",
                                },
                                {
                                  label: context.t(`["accounting/credit"].payments.paid`),
                                  value: "1",
                                },
                                {
                                  label: context.t(`["accounting/credit"].payments.notPaid`),
                                  value: "2",
                                },
                              ]}
                              selected={`${installment.installment_status
                                ? installment.installment_status
                                : 0
                                }`}
                              labelKey="label"
                              valueKey="value"
                              handler={(installment_status) => {
                                installment_data[index].installment_status =
                                  parseInt(installment_status);
                                setSelectedInstallmentStatus(parseInt(installment_status))
                              }}
                            />
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-3">
                            <Select
                              label={context.t(
                                `['receipt/detail/id'].payments.typeInputLabel`
                              )}
                              items={[
                                {
                                  label: context.t(
                                    `['receipt/detail/id'].payments.typeCASH`
                                  ),
                                  value: "0",
                                },
                                {
                                  label: context.t(
                                    `['receipt/detail/id'].payments.typeCARD`
                                  ),
                                  value: "1",
                                },
                                {
                                  label: context.t(
                                    `['receipt/detail/id'].payments.typeEFT`
                                  ),
                                  value: "2",
                                },
                                // {
                                //   label: context.t(
                                //     `['receipt/detail/id'].payments.typePOINT`
                                //   ),
                                //   value: "3",
                                // },
                              ]}
                              selected={`${installment.installment_type}`}
                              labelKey="label"
                              valueKey="value"
                              handler={(installment_type) => {
                                installment_data[index].installment_type =
                                  parseInt(installment_type);
                              }}
                            />
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-2">
                            <MaskedInput
                              label={
                                installment.installment_type === 3
                                  ? context.t(
                                    `['receipt/detail/id'].payments.pointInputLabel`
                                  )
                                  : context.t(
                                    `['receipt/detail/id'].payments.amountInputLabel`
                                  ) +
                                  (context.state.currency
                                    ? "(" +
                                    context.state.currency +
                                    ")"
                                    : "₺")
                              }
                              value={
                                installment.installment_amount !== null
                                  ? parseFloat(
                                    installment.installment_amount
                                  ).toFixed(0)
                                  : ""
                              }
                              onChange={(e) => {
                                installment_data[index].installment_amount =
                                  e.target.value &&
                                    /[0-9,.]+/.test(e.target.value)
                                    ? parseFloat(e.target.value)
                                    : null;
                              }}
                            />
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-1">
                            <Button
                              icon="delete_forever"
                              iconSize={21}
                              textColor="red"
                              size="lg"
                              fullWidth
                              onClick={() => {
                                setInstallmentData([
                                  ...installment_data.slice(
                                    0,
                                    index
                                  ),
                                ]);
                              }}
                            />
                          </Column>
                        </Grid>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {Object.keys(checkout_data.installment).filter(
                      (key) => checkout_data.installment[key] !== undefined
                    ).length > 0 ? (
                      Object.keys(checkout_data.installment).map(
                        (key, index) =>
                          checkout_data.installment[key] !== undefined && (
                            <Grid className="no-gutters-row" key={index}>
                              <Column className="xs-12 sm-12 md-12 mt-1 lg-3">
                                <MuiThemeProvider theme={themeForDatePicker}>
                                  <DatePickerInput
                                    label={`${context.t(
                                      `['receipt/detail/id'].payments.dateInputLabel`
                                    )} / ${index + 1}. ${context.t(`["receipt/detail/id"].installmentText`)}`}
                                    value={
                                      new Date(
                                        moment(
                                          checkout_data.installment[key]
                                            .installment_date
                                        )
                                          .local()
                                          .toISOString()
                                      )
                                    }
                                    onChange={(installment_date) => {
                                      setCheckoutData({
                                        ...checkout_data,
                                        installment: {
                                          ...checkout_data.installment,
                                          [key]: {
                                            ...checkout_data.installment[
                                            key
                                            ],
                                            installment_date,
                                          },
                                        }
                                      });
                                    }}
                                  />
                                </MuiThemeProvider>
                              </Column>

                              <Column className="xs-12 sm-12 md-12 lg-3">
                                <Select
                                  label={context.t(`["accounting/credit"].headings.is_paid`)}
                                  items={[
                                    {
                                      label: context.t(`receipts.customerStatus.waiting`),
                                      value: "0",
                                    },
                                    {
                                      label: context.t(`["accounting/credit"].paid`),
                                      value: "1",
                                    },
                                    {
                                      label: context.t(`["accounting/credit"].notPaid`),
                                      value: "2",
                                    },
                                  ]}
                                  selected={`${checkout_data.installment[key].installment_status}`}
                                  labelKey="label"
                                  valueKey="value"
                                  handler={(installment_status) => {
                                    setCheckoutData({
                                      ...checkout_data,
                                      installment: {
                                        ...checkout_data.installment,
                                        [key]: {
                                          ...checkout_data.installment[key],
                                          installment_status:
                                            parseInt(installment_status),
                                        },
                                      }
                                    });
                                    setSelectedInstallmentStatus(parseInt(installment_status))
                                  }}
                                />
                              </Column>

                              <Column className="xs-12 sm-12 md-12 lg-3">
                                <Select
                                  label={context.t(
                                    `['receipt/detail/id'].payments.typeInputLabel`
                                  )}
                                  items={[
                                    {
                                      label: context.t(
                                        `['receipt/detail/id'].payments.typeCASH`
                                      ),
                                      value: "0",
                                    },
                                    {
                                      label: context.t(
                                        `['receipt/detail/id'].payments.typeCARD`
                                      ),
                                      value: "1",
                                    },
                                    {
                                      label: context.t(
                                        `['receipt/detail/id'].payments.typeEFT`
                                      ),
                                      value: "2",
                                    },
                                    // {
                                    //   label: context.t(
                                    //     `['receipt/detail/id'].payments.typePOINT`
                                    //   ),
                                    //   value: "3",
                                    // },
                                  ]}
                                  selected={`${checkout_data.installment[key].installment_type}`}
                                  labelKey="label"
                                  valueKey="value"
                                  handler={(installment_type) => {
                                    setCheckoutData({
                                      ...checkout_data,
                                      installment: {
                                        ...checkout_data.installment,
                                        [key]: {
                                          ...checkout_data.installment[key],
                                          installment_type:
                                            parseInt(installment_type),
                                        },
                                      }
                                    });
                                  }}
                                />
                              </Column>

                              <Column className="xs-12 sm-12 md-12 lg-2">
                                <MaskedInput
                                  label={
                                    checkout_data.installment[key]
                                      .installment_type === 3
                                      ? context.t(
                                        `['receipt/detail/id'].payments.pointInputLabel`
                                      )
                                      : context.t(
                                        `['receipt/detail/id'].payments.amountInputLabel`
                                      ) +
                                      (context.state.currency
                                        ? "(" +
                                        context.state.currency +
                                        ")"
                                        : "₺")
                                  }
                                  mask="9999999999999"
                                  maskChar=""
                                  value={
                                    checkout_data.installment[key]
                                      .installment_amount
                                      ? parseFloat(
                                        checkout_data.installment[key]
                                          .installment_amount
                                      )
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setCheckoutData({
                                      ...checkout_data,
                                      installment: {
                                        ...checkout_data.installment,
                                        [key]: {
                                          ...checkout_data.installment[key],
                                          installment_amount:
                                            e.target.value &&
                                              /[0-9,.]+/.test(e.target.value)
                                              ? parseFloat(e.target.value)
                                              : null,
                                        },
                                      }
                                    });
                                  }}
                                />
                              </Column>

                              <Column className="xs-12 sm-12 md-12 lg-1">
                                <Button
                                  icon="delete_forever"
                                  iconSize={21}
                                  textColor="red"
                                  size="lg"
                                  fullWidth
                                  onClick={
                                    () => {
                                      setCheckoutData({
                                        ...checkout_data,
                                        installment: {
                                          ...checkout_data.installment,
                                          [key]: undefined,
                                        }
                                      });
                                    }
                                  }
                                />
                              </Column>
                            </Grid>
                          )
                      )
                    ) : (
                      <NoDataContainer>
                        <img
                          src={require("../../../../assets/images/manypixels/online_payment_.svg")}
                          alt="Malesef bulunamadı!"
                        />
                        <p>
                          {context.t(
                            `['receipt/detail/id'].payments.noDataText`
                          )}
                        </p>
                      </NoDataContainer>
                    )}
                  </>
                )}
              </ListSection>
            </Services>
          </Column>
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-12 m-4">
          <ButtonContainer>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <Button
                icon="check"
                disabled={save_button_disabled}
                title={context.t(`receipts.detailButtons.confirmButton`)}
                backgroundColor="primary"
                textColor="white"
                fullWidth
                onClick={() => {
                  setPaymentDetailDialogModal(false);
                  setSaveButtonDisabled(true);
                  saveSingleReceipt();
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <Button
                icon="close"
                title={context.t(`receipts.detailButtons.closeButton`)}
                backgroundColor="white"
                textColor="black"
                fullWidth
                outlined
                onClick={() => {
                  setPaymentDetailDialogModal(false);
                }}
              />
            </Column>
          </ButtonContainer>
        </Column>
      </Grid>
    </AlertDialog>
  );
};

const InfoCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 5px;
  padding: 2px 5px;
  height: 40px;
  width: 40px;
  cursor: pointer;
`;

const InfoCardText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  span {
    font-size: 13px;
    font-weight: 500;
    padding: 5px;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
`;

const ReportsHeader = styled.div`
  width: 100%;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 500;
  display: flex;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  justify-content: start;
`;

const ListSection = styled.div`
  overflow-x: scroll;
  margin-bottom: auto;
  padding-left: 5px;
  padding-right: 5px;
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 32vh;
    object-fit: contain;
  }
  p {
    color: #407bff;
    font-size: 18px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;

const ServicesSectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--dark-primary);
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  margin-bottom:16px;

  h3{font - size: 18px; font-weight: 600; flex: 1; margin: 0; padding: 0}
`;

const StaffList = styled.div`
  height: 26vh;
  box-shadow: inset 0 0 5px #3189ec;
  border-radius: 10px;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  margin: 0;
`;

const ServicesList = styled.div`
  height: 22.5vh;
  box-shadow: inset 0 0 5px #3189ec;
  border-radius: 10px;
  overflow: scroll;
`;

const Services = styled.div`
  height: 63vh;
  border-radius: 10px;
  overflow: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PackageDetailContainer = styled.div`
  flex-direction: column;
  overflow: hidden;
`;
