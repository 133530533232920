import React, { useState, useContext } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";
import Select from "../../../theme/CustomMUI/Select";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Validate from "../../../functions/Validate";
import CSwitch from "../../../theme/CustomMUI/CSwitch";
import styled from "styled-components";
import { DateRangeOutlined } from "@material-ui/icons";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Backdrop } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

let years = [];
for (let i = 1950; i < 2025; i++) {
  years.push({ name: i, value: i });
}

const AddCustomer = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  // Customer Details
  const [customer, setCustomer] = useState({
    name: "",
    surname: "",
    phone: null,
    dialCode: null,
    secondary_phone: "",
    s_dialCode: "",
    email: null,
    sex: 0,
    description: null,
    birthday: null,

    related_company: null,
    send_sms: true,
    nationalityID: null,

    days: [
      {
        value: 1,
        name: 1,
      },
      {
        value: 2,
        name: 2,
      },
      {
        value: 3,
        name: 3,
      },
      {
        value: 4,
        name: 4,
      },
      {
        value: 5,
        name: 5,
      },
      {
        value: 6,
        name: 6,
      },
      {
        value: 7,
        name: 7,
      },
      {
        value: 8,
        name: 8,
      },
      {
        value: 9,
        name: 9,
      },
      {
        value: 10,
        name: 10,
      },
      {
        value: 11,
        name: 11,
      },
      {
        value: 12,
        name: 12,
      },
      {
        value: 13,
        name: 13,
      },
      {
        value: 14,
        name: 14,
      },
      {
        value: 15,
        name: 15,
      },
      {
        value: 16,
        name: 16,
      },
      {
        value: 17,
        name: 17,
      },
      {
        value: 18,
        name: 18,
      },
      {
        value: 19,
        name: 19,
      },
      {
        value: 20,
        name: 20,
      },
      {
        value: 21,
        name: 21,
      },
      {
        value: 22,
        name: 22,
      },
      {
        value: 23,
        name: 23,
      },
      {
        value: 24,
        name: 24,
      },
      {
        value: 25,
        name: 25,
      },
      {
        value: 26,
        name: 26,
      },
      {
        value: 27,
        name: 27,
      },
      {
        value: 28,
        name: 28,
      },
      {
        value: 29,
        name: 29,
      },
      {
        value: 30,
        name: 30,
      },
      {
        value: 31,
        name: 31,
      },
    ],
    selected_day: null,

    months: [
      {
        id: 0,
        value: "01",
        name: context.t(`customers.months.jan`),
      },
      {
        id: 1,
        value: "02",
        name: context.t(`customers.months.feb`),
      },
      {
        id: 2,
        value: "03",
        name: context.t(`customers.months.mar`),
      },
      {
        id: 3,
        value: "04",
        name: context.t(`customers.months.apr`),
      },
      {
        id: 4,
        value: "05",
        name: context.t(`customers.months.may`),
      },
      {
        id: 5,
        value: "06",
        name: context.t(`customers.months.jun`),
      },
      {
        id: 6,
        value: "07",
        name: context.t(`customers.months.jul`),
      },
      {
        id: 7,
        value: "08",
        name: context.t(`customers.months.aug`),
      },
      {
        id: 8,
        value: "09",
        name: context.t(`customers.months.sep`),
      },
      {
        id: 9,
        value: "10",
        name: context.t(`customers.months.oct`),
      },
      {
        id: 10,
        value: "11",
        name: context.t(`customers.months.nov`),
      },
      {
        id: 11,
        value: "12",
        name: context.t(`customers.months.dec`),
      },
    ],
    selected_month: null,
    selected_year: null,

    //Picker için
    real_selected_birthday: null,
  });

  const [selectedDay, setSelectedDay] = useState(moment(new Date() - 568080000000).format("DD"));
  const [selectedMonth, setSelectedMonth] = useState(moment(new Date() - 568080000000).format("MM"));
  const [selectedYear, setSelectedYear] = useState(moment(new Date() - 568080000000).format("YYYY"));

  const [foreignNumber, setForeignNumber] = useState(false);

  const [showBirthdayDate, setShowBirthdayDate] = useState(false);

  const [isInsertCustomerButtonClicked, setIsInsertCustomerButtonClicked] = useState(false);

  const [selectPhoneNumberList, setSelectPhoneNumberList] = useState([
    {
      id: 1,
      name: context.t(`['customers/add'].firstPhoneNumber`),
      type: 0,
    },
    {
      id: 2,
      name: context.t(`['customers/add'].secondPhoneNumber`),
      type: 1,
    },
    {
      id: 3,
      name: context.t(`['customers/add'].allPhoneNumber`),
      type: 2,
    },
  ]);

  const [selectPhoneNumber, setSelectPhoneNumber] = useState({
    id: 1,
    name: context.t(`['customers/add'].firstPhoneNumber`),
    type: 0,
  });

  const phoneNumberHandler = (value, data, event, formattedValue) => {
    setCustomer((prev) => ({
      ...prev,
      phone: value,
      dialCode: data.dialCode
    }))
  };

  const insertCustomer = async () => {
    let birthday;

    try {
      if (showBirthdayDate) {
        let birthday = new Date(
          `${moment(
            `${selectedYear}-${selectedMonth}-${selectedDay}`
          ).format("YYYY-MM-DD")}`
        ).toISOString();
        if (customer.birthday !== null && customer.birthday.length === 9) {
          const birthday_arr = customer.birthday.split("/");
          birthday = new Date(
            `${birthday_arr[2]}-${birthday_arr[1]}-${birthday_arr[0]}`
          );
          birthday.setHours(0, 0, 0, 0);
          birthday = birthday.toString();
        }

        validateCustomer() &&
          Axios.post(`${context.api_endpoint}/company/customer/insert`, {
            ...customer,
            name: customer.name.trim(),
            surname: customer.surname.trim(),
            ...(process.env.APP_NAME === 'management' ? {
              phone: customer.phone?.slice(customer?.dialCode.length),
              dialCode: customer.dialCode ? "+" + customer.dialCode : "",
            } : {
              phone: `${customer.phone}`.length === 10 ? customer.phone : "",
              dialCode:
                context.state.dialCode !== "" ||
                  context.state.dialCode !== null
                  ? context.state.dialCode
                  : "+90",
            }),
            secondary_phone: customer.secondary_phone.slice(
              customer.s_dialCode.length
            ),
            s_dialCode: customer.s_dialCode ? "+" + customer.s_dialCode : "",
            selectedPhone: selectPhoneNumber && selectPhoneNumber.type,
          })
            .then((response) => {
              if (response.status === 200) {
                toast.success(context.t(`customers.createSuccessToast`));
                history.push("/customers");
              }
              setIsInsertCustomerButtonClicked(false);
            })
            .catch((e) => {
              if (e.response.status === 401) {
                toast.error(e.response.data.message);
              } else {
                toast.warning(e.response.data.message);
              }
              setIsInsertCustomerButtonClicked(false);
            });
      } else {
        validateCustomer() &&
          Axios.post(`${context.api_endpoint}/company/customer/insert`, {
            ...customer,
            name: customer.name.trim(),
            surname: customer.surname.trim(),
            ...(process.env.APP_NAME === 'management' ? {
              phone: customer.phone?.slice(customer?.dialCode.length),
              dialCode: customer.dialCode ? "+" + customer.dialCode : "",
            } : {
              phone: `${customer.phone}`.length === 10 ? customer.phone : "",
              dialCode:
                context.state.dialCode !== "" ||
                  context.state.dialCode !== null
                  ? context.state.dialCode
                  : "+90",
            }),
            secondary_phone: customer.secondary_phone.slice(
              customer.s_dialCode.length
            ),
            s_dialCode: customer.s_dialCode ? "+" + customer.s_dialCode : "",
            birthday,
            selectedPhone: selectPhoneNumber && selectPhoneNumber.type,
          })
            .then((response) => {
              if (response.status === 200) {
                toast.success(context.t(`customers.createSuccessToast`));
                history.push("/customers");
              }
              setIsInsertCustomerButtonClicked(false);
            })
            .catch((e) => {
              if (e.response.status === 401) {
                toast.error(e.response.data.message);
              } else {
                toast.warning(e.response.data.message);
              }
              setIsInsertCustomerButtonClicked(false);
            });
      }
    } catch (error) {
      toast.warn(
        context.t(`customers.wrongDateWarnToast`)
      );
    }
  };

  const SecondaryPhone_handleOnChange = (value, data, event, formattedValue) => {
    setCustomer((prev) => ({
      ...prev,
      secondary_phone: value,
      s_dialCode: data.dialCode
    }))
  };

  const validateCustomer = () => {
    let validate_for_phone_number;

    if (customer.secondary_phone === null) {
      validate_for_phone_number = {
        field: "Telefon Numarası",
        value: customer.phone,
        condition: /\S{10}/,
      };
    } else {
      validate_for_phone_number = {
        field: "Telefon Numarası",
        value: customer.phone,
        condition: /^\s*|\S{10}/,
      };
    }

    return Validate([
      {
        field: "İsim",
        value: customer.name,
        condition: /.+[a-zA-Z ._-]/,
      },
      {
        field: "Soyisim",
        value: customer.surname,
        condition: /.+[a-zA-Z ._-]/,
      },
      validate_for_phone_number,
    ]);
  };


  return (
    <AuthContainer authorities={[0, 1]} limited_permission="customer">
      <Grid className="mb-3">
        <Column className="xs-12 sm-12 md-12 lg-3">
          <Input
            required
            label={context.t(`['customers/add'].nameInputLabel`)}
            placeholder={context.t(
              `['customers/add'].nameInputPlaceholder`
            )}
            value={customer.name !== null ? customer.name : ""}
            onChange={(e) => {
              let nameText = e.target.value;
              setCustomer({
                ...customer,
                name:
                  nameText.split(" ")[1] !== undefined
                    ? nameText.split(" ")[0] + " " + nameText.split(" ")[1]
                    : nameText.split(" ")[0] !== undefined
                      ? nameText.split(" ")[0]
                      : nameText,
              });
            }}
          />
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-3">
          <Input
            required
            label={context.t(`['customers/add'].surnameInputLabel`)}
            placeholder={context.t(
              `['customers/add'].surnameInputLabel`
            )}
            value={customer.surname !== null ? customer.surname : ""}
            onChange={(e) => {
              let surnameText = e.target.value;
              setCustomer({
                ...customer,
                surname:
                  surnameText.split(" ")[1] !== undefined
                    ? surnameText.split(" ")[0] +
                    " " +
                    surnameText.split(" ")[1]
                    : surnameText,
              });
            }}
          />
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-3">
          <Input
            style={{ position: "relative", zIndex: 12 }}
            label={context.t(
              `['customers/add/bulk'].preview.nationalID`
            )}
            placeholder={context.t(
              `['customers/add/bulk'].preview.nationalID`
            )}
            value={
              customer.nationalityID !== null
                ? customer.nationalityID.substring(0, 11)
                : ""
            }
            onChange={(e) => {
              const value = e.target.value;
              if (value.length > 11) {
                return;
              }
              setCustomer({
                ...customer,
                nationalityID: value,
              })
            }}
          />
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-3">
          <CSwitch
            title={context.t(`['customers/add'].genderInputLabel`)}
            label={context.t(`['customers/add'].genderMale`)}
            labelLeft={context.t(`['customers/add'].genderFemale`)}
            checked={!!customer.sex}
            color="primary"
            closeColor="purple"
            onChange={(checked) => {
              setCustomer({
                ...customer,
                sex: checked.target.checked === true ? 1 : 0,
              });
            }}
          />
        </Column>
      </Grid>

      <Grid className="mb-3">
        <Column className="xs-12 sm-12 md-12 lg-3">
          {process.env.APP_NAME === "management" ? (
            <PhoneInput
              countryCodeEditable={false}
              value={customer.phone !== null ? customer.phone : ""}
              onChange={phoneNumberHandler}
            />
          ) : (
            <MaskedInput
              required
              disabled={foreignNumber}
              label={context.t(`['customers/add'].phoneInputLabel`)}
              maskChar={""}
              mask="999 999 9999"
              startAdornment="+90"
              value={customer.phone !== null ? customer.phone : ""}
              placeholder="500 000 0000"
              onChange={(e) => {
                setCustomer({
                  ...customer,
                  phone:
                    e.target.value.substring(0, 1) === "5" &&
                    e.target.value.replace(/\s/g, "")
                });
              }}
            />
          )}
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-3">
          {process.env.APP_NAME === "management" ? (
            <PhoneInput
              specialLabel={context.t(
                `['customers/add'].secondPhoneInputLabel`
              )}
              countryCodeEditable={false}
              value={customer.secondary_phone}
              onChange={SecondaryPhone_handleOnChange}
            />
          ) : (
            <>
              <PhoneInput
                inputStyle={{ border: "none", width: "100%" }}
                country={"tr"}
                countryCodeEditable={false}
                value={customer.secondary_phone}
                onChange={SecondaryPhone_handleOnChange}
                specialLabel={context.t(
                  `['customers/add'].secondPhoneInputLabel`
                )}
              />
              <FormControlLabel
                style={{ marginTop: "0px" }}
                control={
                  <Checkbox
                    size="small"
                    checked={foreignNumber}
                    onChange={(e) => {
                      setCustomer({
                        ...customer,
                        phone: e.target.checked ? "" : ""
                      });
                      setForeignNumber(e.target.checked);
                    }}
                    name="foreign_number"
                    color="primary"
                  />
                }
                label={
                  <span style={{ fontSize: "14px" }}>
                    {context.t(
                      `component.CADialog.checkingForeingNumber`
                    )}
                  </span>
                }
              />
            </>
          )}
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-3">
          <Input
            label={context.t(`['customers/add'].mailInputLabel`)}
            placeholder={context.t(
              `['customers/add'].mailInputPlaceholder`
            )}
            value={customer.email !== null ? customer.email : ""}
            onChange={(e) => setCustomer({
              ...customer,
              email: e.target.value,
            })
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-3">
          <CSwitch
            title={context.t(`['customers/add'].sendSmsInputLabel`)}
            label={`${context.t(
              `['customers/add'].sendSmsFalse`
            )}/${context.t(`['customers/add'].sendSmsTrue`)}`}
            checked={!!customer.send_sms}
            color="primary"
            onChange={(send_sms) => setCustomer({
              ...customer,
              send_sms: send_sms.target.checked
            })
            }
          />
        </Column>
      </Grid>

      <Grid>
        {showBirthdayDate ? (
          <Column
            className="xs-12 sm-12 md-12 lg-3"
            style={{ display: "flex", position: "relative" }}
          >
            <div style={{ position: "absolute", top: "-2vh", left: "30px" }}>
              <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                {context.t(`[customers/add].birthdayInputLabel`)}
              </span>
            </div>
            <CostumSelect
              className="mt-1"
              style={{ cursor: "pointer", width: "60%" }}
              value={
                selectedDay
                  ? selectedDay
                  : moment(new Date() - 568080000000).format("DD")
              }
              onChange={(e) => {
                setSelectedDay(e.target.value)
              }}
            >
              {customer.days.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </CostumSelect>

            <CostumSelect
              className="m-1"
              style={{ cursor: "pointer" }}
              value={
                selectedMonth
                  ? selectedMonth
                  : moment(new Date() - 568080000000).format("MM")
              }
              onChange={(e) => {
                setSelectedMonth(e.target.value);
              }}
            >
              {customer.months.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </CostumSelect>

            <CostumSelect
              className="mt-1"
              style={{ cursor: "pointer", width: "75%" }}
              value={
                selectedYear === ""
                  ? moment(new Date() - 568080000000).format("YYYY")
                  : selectedYear
              }
              onChange={(e) => {
                setSelectedYear(e.target.value);
              }}
            >
              {years.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </CostumSelect>
          </Column>
        ) : (
          <Column className="xs-12 sm-12 md-12 lg-3">
            <div
              style={{
                boxShadow: "0px 2px 12px rgb(0 0 0 / 4%)",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                height: "59px",
              }}
              onClick={() => {
                setShowBirthdayDate(true);
              }}
            >
              <DateRangeOutlined
                color="disabled"
                style={{ padding: "10px" }}
              />
              <span>
                {context.t(`[customers/add].birthdayInputLabel`)}
              </span>
            </div>
          </Column>
        )}

        <Column className="xs-12 sm-12 md-12 lg-3">
          <Input
            label={context.t(
              `['customers/add'].relatedBusinessInputLabel`
            )}
            placeholder={context.t(
              `['customers/add'].relatedBusinessInputPlaceholder`
            )}
            value={
              customer.related_company !== null
                ? customer.related_company
                : ""
            }
            onChange={(e) => setCustomer({
              ...customer,
              related_company: e.target.value,
            })
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-3">
          <Select
            label={context.t(
              `['customers/add'].selectPhoneNumberForMessage`
            )}
            items={selectPhoneNumberList}
            labelKey="name"
            valueKey="id"
            selected={
              selectPhoneNumber
                ? selectPhoneNumber.id
                : selectPhoneNumberList[0].id
            }
            returnFullObject
            handler={(filterPicker) => {
              setSelectPhoneNumber(filterPicker);
            }}
          />
        </Column>
        <Column className="xs-12 sm-12">
          <Input
            label={context.t(`['customers/add'].descriptionInputLabel`)}
            value={customer.description !== null ? customer.description : ""}
            multiline={true}
            rows={3}
            onChange={(e) => setCustomer({
              ...customer,
              description: e.target.value,
            })
            }
          />
        </Column>

        <Column className="xs-12 sm-12">
          <Button
            type="submit"
            title={context.t(
              `['customers/add'].createNewCustomerButtonTitle`
            )}
            icon="check"
            backgroundColor="primary"
            textColor="white"
            fullWidth
            disabled={isInsertCustomerButtonClicked}
            onClick={() => {
              setIsInsertCustomerButtonClicked(true);
              setTimeout(() => {
                setIsInsertCustomerButtonClicked(false);
              }, 1000);
              insertCustomer();
            }}
          />
        </Column>
      </Grid>
    </AuthContainer>
  );
}

export default AddCustomer;

// const SectionTitle = (props) => (
//   <Column className="xs-12 sm-12">
//     <SectionHeading>{props.children}</SectionHeading>
//   </Column>
// );

// const SectionHeading = styled.h3`
//   font-size: 18px;
//   font-weight: 600;
//   flex: 1;
//   width: 100%;
//   display: block;
//   margin-bottom: 8px;
// `;

const CostumSelect = styled.select`
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #b2b2b2;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  height: 46px;
  margin-bottom: 0px;
  margin-top: -2px;
`;
