/* eslint-disable */
import React, { useContext, useRef } from "react";
import styled from "styled-components";

import { Grid, Column } from "../../../../theme/Grid";
import Button from "../../../../theme/Button";
import Table from "../../../../theme/Table";
import Input from "../../../../theme/CustomMUI/Input";
import Select from "../../../../theme/CustomMUI/Select";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import MaskedInput from "../../../../theme/CustomMUI/MaskedInput";
import { toast } from "react-toastify";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import { WhatsApp } from "@material-ui/icons";
import { TextField } from "@material-ui/core";
import ReactToPrint from "react-to-print";
import AddIcon from "@material-ui/icons/Add";
import BackupIcon from "@material-ui/icons/Backup";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AppContext from "../../../../context/store";

/**
 * açılan sözleşme pop-up içeriğinin düzenlendiği alan
 * tab:0
 */
export const _ContractDialogEntrance = ({
  setContractTab,
  getCustomerAgreements,
  checkout_data,
}) => {
  const context = useContext(AppContext);
  return (
    <Grid>
      <Column className="sx-12 sm-12 md-12 lg-12 mb-1">
        <div
          style={{
            background: "rgb(49, 169, 243, 0.14)",
            borderRadius: "4px",
            display: "flex",
            padding: "10px",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            border: "1px solid rgb(91, 187, 245, 0.41)",
          }}
          onClick={() => {
            setContractTab(1);
          }}
        >
          <div
            style={{
              padding: "4px 10px 0",
              color: "#31A9F3",
            }}
          >
            <AddIcon />
          </div>
          <div style={{ color: "#31A9F3" }}>{context.t(`["settings/agreements"].createNewAgreement`)}</div>
        </div>
      </Column>

      <Column className="sx-12 sm-12 md-12 lg-12 mb-1">
        <div
          style={{
            background: "rgb(29, 139, 62, 0.21)",
            borderRadius: "4px",
            display: "flex",
            padding: "10px",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            border: "1px solid rgb(58, 154, 87, 0.35)",
          }}
          onClick={() => {
            setContractTab(2);
          }}
        >
          <div style={{ padding: "4px 10px 0", color: "#1D8B3E" }}>
            <BackupIcon />
          </div>
          <div style={{ color: "#1D8B3E" }}>{context.t(`["receipt/detail/id"].uploadAgreementTitle`)}</div>
        </div>
      </Column>

      <Column className="sx-12 sm-12 md-12 lg-12 mb-5">
        <div
          style={{
            background: "rgb(254, 151, 151, 0.19)",
            borderRadius: "4px",
            display: "flex",
            padding: "10px",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            border: "1px solid rgb(185, 178, 178, 0.65)",
          }}
          onClick={() => {
            getCustomerAgreements(1, checkout_data.receipt_id);
            setContractTab(3);
          }}
        >
          <div
            style={{
              padding: "4px 10px 0",
              color: "#FC8E8E",
            }}
          >
            <ListAltIcon />
          </div>
          <div style={{ color: "#FC8E8E" }}>{context.t(`["receipt/detail/id"].savedAgreementTitle`)}</div>
        </div>
      </Column>
    </Grid>
  );
};

/**
 * açılan sözleşme pop-up içeriğinin düzenlendiği alan
 * tab:1
 */
export const ContractDialogCreateNewContract = ({
  contract_patterns,
  checkout_data,
  selected_pattern,
  setSelectedPattern,
  selected_agreement_dialog_show,
  setSelectedAgreementDialogShow,
  newAgreementDetail,
  setNewAgreementDetail,
  contract_info_ful_name,
  setContractInfoFulName,
  contract_info_phone,
  setContractInfoPhone,
  contract_info_id_number,
  setContractInfoIdNumber,
  contract_info_tax_number,
  setContractInfoTaxNumber,
  contract_info_address,
  setContractInfoAddress,
  contract_patterns_wo_clauses,
  logo_src,
  staffArr,
  servicesArr,
  getAllTotalService,
  clauses,
  Signature,
  content_to_signiture,
  setContentToSigniture,
  send_to_signiture_aproval_modal,
  setSendToSignitureAprovalModal,
  sendAgreementToMobile,
}) => {
  const inputRefPDF = useRef();
  const context = useContext(AppContext);

  return (
    <>
      <h1 style={{ textAlign: "center" }}>{context.t(`["settings/agreements"].createNewAgreement`)}</h1>
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-12">
          <Select
            label={context.t(`["receipt/detail/id"].selectAgreementTemplateTitle`)}
            items={[...contract_patterns_wo_clauses]}
            valueKey="id"
            labelKey="template_name"
            handler={(id) => {
              setSelectedPattern(
                contract_patterns.filter((pat) => pat.Template.id == id)[0]
              );
              setNewAgreementDetail(true);
            }}
            selected={selected_pattern.Template?.id}
          />
        </Column>
        {!newAgreementDetail ? (
          <></>
        ) : (
          <Grid className="mb-4">
            <Column
              className="sx-12 sm-12 md-12 lg-12 mb-3"
              style={{ textAlign: "center" }}
            >
              {context.t(`["settings/agreements"].createTamplateArea.personalInformation`)}
            </Column>
            {/* Ad Soyad */}
            <Column className="sx-12 sm-12 md-12 lg-6">
              <Input
                label={context.t(`sms.send.autoText.nameSurname`)}
                fullWidth
                value={contract_info_ful_name}
                onChange={(e) => {
                  setContractInfoFulName(e.target.value);
                }}
              />
            </Column>
            {/* Telefon numarası */}
            {selected_pattern.Template.phone && (
              <Column className="sx-12 sm-12 md-12 lg-6">
                <MaskedInput
                  mask="999 999 9999"
                  maskChar=""
                  label={context.t(`customers.headings.phone`)}
                  fullWidth
                  value={contract_info_phone}
                  onChange={(e) => {
                    setContractInfoPhone(e.target.value);
                  }}
                />
              </Column>
            )}
            {/* TC no */}
            {selected_pattern.Template.nationality_id && (
              <Column className="sx-12 sm-12 md-12 lg-6">
                <MaskedInput
                  label={context.t(`["receipt/detail/id"].nationalIDTitle`)}
                  fullWidth
                  mask="99999999999"
                  maskChar=""
                  value={contract_info_id_number}
                  onChange={(e) => {
                    setContractInfoIdNumber(e.target.value);
                  }}
                />
              </Column>
            )}
            {/* Vergi No */}
            {selected_pattern.Template.tax_no && (
              <Column className="sx-12 sm-12 md-12 lg-6">
                <Input
                  label={context.t(`["receipt/detail/id"].taxIDTitle`)}
                  type="number"
                  fullWidth
                  value={contract_info_tax_number}
                  onChange={(e) => {
                    setContractInfoTaxNumber(e.target.value);
                  }}
                />
              </Column>
            )}
            {/* Adress */}
            {selected_pattern.Template.address && (
              <Column className="sx-12 sm-12 md-12 lg-12">
                <Input
                  label={context.t(`["receipt/detail/id"].addressTitle`)}
                  fullWidth
                  value={contract_info_address}
                  multiline={true}
                  onChange={(e) => {
                    setContractInfoAddress(e.target.value);
                  }}
                />
              </Column>
            )}
          </Grid>
        )}
      </Grid>

      <AlertDialog
        title={""}
        open={selected_agreement_dialog_show}
        fullWidth
        maxWidth="md"
        closeHandler={() => setSelectedAgreementDialogShow(false)}
        disableBackdropClick={false}
      >
        <Column className="xs-12 sm-12 md-12 lg-2 mt-1">
          <div
            ref={inputRefPDF}
            style={{
              margin: "0 20px 0 20px",
            }}
          >
            <Column className="sx-12 sm-12 md-12 lg-12">
              <div
                className="mt-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  fontFamily: "roboto",
                  fontSize: "0.8em",
                }}
              >
                {/* Sözleşme başlığı */}

                <div
                  style={{
                    textAlign: "center",
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      marginLeft: "4.5vw",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "80%",
                        height: "20vh",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        style={{ objectFit: "cover", width: "100%" }}
                        src={`${context.api_endpoint}/${context.state.company_id}/${logo_src}`}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h1>{selected_pattern.Template?.company_name}</h1>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <h2>{selected_pattern.Template?.template_name}</h2>
                    </div>
                    <div
                      style={{
                        textAlign: "end",
                        width: "60%",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "1.4em",
                        }}
                      >
                        {moment().format("DD/MM/YYYY")}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Sözleşmede kişisel bilgilerin olduğu bölüm */}

                <div
                  style={{
                    border: "1px solid black",
                    width: "80%",
                    borderRadius: "3px",
                    padding: "5px 10px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {/* Ad soyad */}
                  <div
                    style={{
                      width: "45%",
                      fontWeight: "bold",
                    }}
                  >
                    {context.t(
                      `[settings/agreements].personal_info.name_and_surname`
                    )}
                    :{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {contract_info_ful_name}
                    </span>
                  </div>
                  {/* Telefon Numarası */}
                  {selected_pattern.Template?.phone ? (
                    <div
                      style={{
                        width: "45%",
                        fontWeight: "bold",
                      }}
                    >
                      {context.t(`[settings/agreements].personal_info.phone`)}:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {contract_info_phone}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* tc No */}
                  {selected_pattern.Template?.nationality_id ? (
                    <div
                      style={{
                        width: "45%",
                        fontWeight: "bold",
                      }}
                    >
                      {context.t(
                        `[settings/agreements].personal_info.id_number`
                      )}
                      :
                      <span style={{ fontWeight: "normal" }}>
                        {contract_info_id_number}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* vergi no */}
                  {selected_pattern.Template?.tax_no ? (
                    <div
                      style={{
                        width: "45%",
                        fontWeight: "bold",
                      }}
                    >
                      {context.t(
                        `[settings/agreements].personal_info.tax_number`
                      )}
                      :{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {contract_info_tax_number}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* adres */}
                  {selected_pattern.Template?.address ? (
                    <div
                      style={{
                        width: "90%",
                        fontWeight: "bold",
                      }}
                    >
                      {context.t(`[settings/agreements].personal_info.address`)}
                      :{" "}
                      <span
                        style={{
                          fontWeight: "normal",
                          overflowWrap: "break-word",
                        }}
                      >
                        {contract_info_address}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {/* Sözleşmede ödeme bilgilerinin olduğu bölüm */}

                {selected_pattern.Template?.services_employee_name ||
                  selected_pattern.Template?.show_service ? (
                  <div
                    style={{
                      border: "1px solid black",
                      width: "80%",
                      borderRadius: "3px",
                      margin: "10px 0 0 0",
                      padding: "5px 10px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      {selected_pattern.Template.services_employee_name ? (
                        <div style={{ width: "45%", fontWeight: "bold" }}>
                          {context.t(`[reports/staff].name`)}
                        </div>
                      ) : (
                        <></>
                      )}
                      {selected_pattern.Template.show_service ? (
                        <div style={{ width: "45%", fontWeight: "bold" }}>
                          {context.t(`["receipt/detail/id"].headingTitles.service`)}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div style={{ display: "flex", marginTop: "10px" }}>
                      {selected_pattern.Template.services_employee_name ? (
                        <div
                          style={{
                            width: "45%",
                            marginTop: "5px",
                          }}
                        >
                          {/* Çalışan listesi */}
                          {staffArr.map((item) => (
                            <div>{item}</div>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}

                      {selected_pattern.Template.show_service ? (
                        <div style={{ width: "45%", marginTop: "5px" }}>
                          {/* Hizmet Listesi hizmetleri */}
                          {servicesArr.map((item) => (
                            <div>{item}</div>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {selected_pattern.Template?.show_amount ? (
                  <div
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "bold",
                      margin: "10px 70px 10px auto",
                    }}
                  >
                    {context.t(`["receipt/detail/id"].headingTitles.netTotal`)} {parseFloat(getAllTotalService()).toFixed(2)}
                    {context.state.currency ? context.state.currency : "₺"}
                  </div>
                ) : (
                  <></>
                )}

                {/* Sözleşmenin maddelerinin içerdiği bölüm */}

                {clauses
                  .filter((clauseInfo) =>
                    selected_pattern.Clauses.includes(clauseInfo.id)
                  )
                  .filter((el) => el.type === 2).length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "85%",
                        borderBottom: "1px solid #b3b3b3",
                      }}
                    >
                      <span style={{ paddingRight: "20px", fontSize: "16px" }}>
                        {context.t(`definePopup.buttons.approve`)}
                      </span>
                      <span style={{ fontSize: "16px" }}>{context.t(`definePopup.buttons.cancel`)}</span>
                    </div>
                  )}

                {selected_pattern.Clauses &&
                  selected_pattern.Clauses.map((el, index) => {
                    return (
                      <>
                        {clauses.filter(
                          (clauseInfo) =>
                            clauseInfo.id === el && clauseInfo.type === 2
                        ).length > 0 ? (
                          <div
                            style={{
                              width: "85%",
                              padding: "5px 0px",
                              display: "flex",
                              flexWrap: "wrap",
                              textAlign: "start",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                id="content-to-send"
                                style={{ width: "85%" }}
                              >
                                {clauses
                                  .filter(
                                    (clauseInfo) => clauseInfo.id === el
                                  )[0]
                                  .content.split("\n")
                                  .map((str) => (
                                    <p contentEditable="true">{str}</p>
                                  ))}
                              </span>
                              <div
                                style={{
                                  height: "18px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  marginRight: "4px",
                                  width: "12%",
                                }}
                                id="checkbox-outliner"
                              >
                                <input
                                  className="agr-checkbox"
                                  type="checkbox"
                                  name={el}
                                  id={el}
                                  onClick={() => {
                                    if (
                                      document.getElementById(`${el}`).checked
                                    ) {
                                      document.getElementById(
                                        `${el}${index}`
                                      ).checked = false;
                                    } else {
                                      document.getElementById(
                                        `${el}${index}`
                                      ).checked = true;
                                    }
                                  }}
                                />
                                <input
                                  className="agr-checkbox"
                                  type="checkbox"
                                  name={`${el}${index}`}
                                  id={`${el}${index}`}
                                  onClick={() => {
                                    // document.querySelector(
                                    //   `#${el}${index}`
                                    // );
                                    if (
                                      document.getElementById(`${el}${index}`)
                                        .checked
                                    ) {
                                      document.getElementById(
                                        `${el}`
                                      ).checked = false;
                                    } else {
                                      document.getElementById(
                                        `${el}`
                                      ).checked = true;
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}

                {clauses
                  .filter((clauseInfo) =>
                    selected_pattern.Clauses.includes(clauseInfo.id)
                  )
                  .filter((el) => el.type === 1).length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "85%",
                        borderBottom: "1px solid #b3b3b3",
                      }}
                    ></div>
                  )}

                {selected_pattern.Clauses &&
                  selected_pattern.Clauses.map((el, index) => {
                    return (
                      <>
                        {clauses.filter(
                          (clauseInfo) =>
                            clauseInfo.id === el && clauseInfo.type !== 2
                        ).length > 0 ? (
                          <div
                            style={{
                              width: "85%",
                              padding: "5px 0px",
                              display: "flex",
                              flexWrap: "wrap",
                              textAlign: "start",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <span id="content-to-send">
                                {clauses
                                  .filter(
                                    (clauseInfo) => clauseInfo.id === el
                                  )[0]
                                  .content.split("\n")
                                  .map((str) => (
                                    <p contentEditable="true">{str}</p>
                                  ))}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}

                {/* Footer */}
                <div
                  style={{
                    width: "85%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "20px",
                    padding: "20px 0",
                  }}
                >
                  <div
                    style={{
                      width: "70%",
                    }}
                  >
                    <div>{selected_pattern.Template?.company_name}</div>
                    <span>{selected_pattern.Template?.company_info}</span>
                  </div>
                  <div>
                    <div>{contract_info_ful_name}</div>
                  </div>
                </div>
              </div>
            </Column>
          </div>
        </Column>

        <Column
          className="xs-12 sm-12 md-12 lg-12 mt-3"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <div style={{ width: "25%" }}>
            <ReactToPrint
              trigger={() => (
                <Button
                  title={
                    process.env.APP_NAME === "management" ? "Print" : "Yazdır"
                  }
                  fullWidth
                  textColor="white"
                  backgroundColor="green"
                />
              )}
              content={() => inputRefPDF.current}
            />
          </div>
          <div
            style={{
              width: "25%",
              display: "flex",
            }}
          >
            <Button
              icon={Signature()}
              backgroundColor="primary-opacity"
              title={context.t(`["receipt/detail/id"].sendToSignatureTitle`)}
              outlined
              fullWidth
              textColor="primary"
              onClick={async () => {
                await setContentToSigniture({
                  ...content_to_signiture,
                  receipt_id: parseInt(checkout_data.receipt_id),
                  title: selected_pattern.Template?.template_name,
                  name: contract_info_ful_name,
                  company_name: selected_pattern.Template?.company_name,
                  company_info: selected_pattern.Template?.company_info,
                  address: selected_pattern.Template?.address
                    ? contract_info_address
                    : null,
                  nationality_id: selected_pattern.Template?.nationality_id
                    ? contract_info_id_number
                    : null,
                  tax_no: selected_pattern.Template?.tax_no
                    ? contract_info_tax_number
                    : null,
                  phone: selected_pattern.Template?.phone
                    ? contract_info_phone
                    : null,
                  date: moment().format("DD/MM/YYYY"),
                  show_service: selected_pattern.Template?.show_service,
                  show_amount: selected_pattern.Template?.show_amount,
                  show_staff: selected_pattern.Template?.services_employee_name,
                  clauses: [
                    ...[
                      ...inputRefPDF.current.querySelectorAll(
                        "#content-to-send"
                      ),
                    ]
                      .map((item) => item.innerText)
                      .filter(
                        (item, index) =>
                          index >=
                          [
                            ...inputRefPDF.current.querySelectorAll(
                              "#checkbox-outliner"
                            ),
                          ].length
                      )
                      .map((item) => {
                        return {
                          content: item,
                          type: 1,
                          confirm: false,
                        };
                      }),
                    ...[
                      ...inputRefPDF.current.querySelectorAll(
                        "#checkbox-outliner"
                      ),
                    ]
                      .map((item) => item.children)
                      .map((item) => item[0])
                      .map((item) => item.checked)
                      .map((item, index) => {
                        return {
                          content: [
                            ...inputRefPDF.current.querySelectorAll(
                              "#content-to-send"
                            ),
                          ].map((item) => item.innerText)[index],
                          type: 2,
                          confirm: item,
                        };
                      }),
                  ],
                });
                await setSendToSignitureAprovalModal(true);
              }}
            />
          </div>
          <div style={{ width: "25%" }}>
            <Button
              icon="clear"
              title={context.t(`receipts.detailButtons.closeButton`)}
              outlined
              fullWidth
              textColor="#ccc"
              onClick={() => {
                setSelectedAgreementDialogShow(false);
              }}
            />
          </div>
        </Column>
      </AlertDialog>

      <AlertDialog
        title={context.t(`["receipt/detail/id"].sendToSignatureAlertDialogTitle`)}
        open={send_to_signiture_aproval_modal}
        fullWidth
        maxWidth="sm"
        closeHandler={() => setSendToSignitureAprovalModal(false)}
        disableBackdropClick={true}
        buttons={[
          {
            title: context.t(`receipts.detailButtons.confirmButton`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {
              sendAgreementToMobile();
            },
          },
          {
            title: context.t(`["receipt/detail/id"].applyDiscount.discardButtonTitle`),
            icon: "close",
            textColor: "#ccc",
            outlined: true,
            onClick: async () => {
              await setSendToSignitureAprovalModal(false);
            },
          },
        ]}
      >
        <span>
          {context.t(`["receipt/detail/id"].sendToSignatureAlertDialogText`)}
        </span>
      </AlertDialog>
    </>
  );
};

/**
 * açılan sözleşme pop-up içeriğinin düzenlendiği alan
 * tab:2
 */
export const _ContractDialogUploadContract = ({
  file,
  setFile,
  agreement_customer_for_receipt,
  setAgreementCustomerForReceipt,
  agreement_title,
  setAgreementTitle,
  uploadAgreement,
}) => {
  const context = useContext(AppContext);
  return (
    <>
      <h1 style={{ textAlign: "center" }}>{context.t(`["receipt/detail/id"].uploadAgreementTitle`)}</h1>
      <Grid>
        <Column className="sx-12 sm-12 md-12 lg-12 mb-5">
          <input
            id="PDFUploadInput"
            type="file"
            accept="application/pdf"
            style={{ display: "none" }}
            onChange={(e) => {
              setFile(e);
            }}
          />
          <ImageUploadBox htmlFor="PDFUploadInput">
            <i className="material-icons">cloud_upload</i>
            {context.t(`["receipt/detail/id"].uploadAgreementText`)}
          </ImageUploadBox>
        </Column>

        {file && (
          <Column
            className="sx-12 sm-12 md-12 lg-12 mb-2"
            style={{ borderTop: "1px solid #ccc", paddingTop: "2vh" }}
          >
            <Column className="sx-12 sm-12 md-12 lg-4">
              <Input
                label={context.t(`sms.send.autoText.nameSurname`)}
                fullWidth
                value={agreement_customer_for_receipt}
                onChange={(e) => {
                  setAgreementCustomerForReceipt(e.target.value);
                }}
              />
            </Column>
            <Column className="sx-12 sm-12 md-12 lg-4">
              <TextField
                label={context.t(`customers.contractHeading.agreement`)}
                variant="outlined"
                fullWidth
                value={agreement_title}
                inputProps={{ maxLength: 45 }}
                onChange={(e) => {
                  setAgreementTitle(e.target.value);
                }}
              />
            </Column>
            <Column className="sx-12 sm-12 md-12 lg-4">
              <Button
                size="lg"
                title={context.t(`appointments.saveButtonTitle`)}
                fullWidth
                backgroundColor="primary-opacity"
                textColor="primary"
                onClick={() => {
                  if (!agreement_title) {
                    toast.warning(
                      context.t(`["receipt/detail/id"].uploadAgreementWarnToast2`)
                    );
                  } else {
                    uploadAgreement(file);
                  }
                }}
              />
            </Column>
          </Column>
        )}
      </Grid>
    </>
  );
};

/**
 * açılan sözleşme pop-up içeriğinin düzenlendiği alan
 * tab:3
 */
export const _ContractDialogContracts = ({
  customer_agreements,
  agreement_load,
  pagination_agreement,
  wp_aproval_modal,
  setWpAprovalModal,
  send_wp_id,
  setSendWpId,
  send_wp_customer_name,
  setSendWpCustomerName,
  send_wp_agreement_name,
  setSendWpAgreementName,
  sms_aproval_modal,
  setSmsAprovalModal,
  send_sms_id,
  setSendSmsId,
  send_sms_customer_name,
  setSendSmsCustomerName,
  send_sms_agreement_name,
  setSendSmsAgreementName,
  delete_agreement_dialog,
  setDeleteAgreementDialog,
  sendSMSAgreement,
  sendWPAgreement,
  agreement_delete_id,
  setAgreementDeleteId,
  deleteAgreement,
}) => {
  const context = useContext(AppContext);
  return (
    <>
      <h1 style={{ textAlign: "center" }}>{context.t(`["receipt/detail/id"].savedAgreementTitle`)}</h1>
      <Grid>
        <Column className="sx-12 sm-12 md-12 lg-12 mb-5">
          <Table
            refreshAction={() =>
              getAgreements(pagination_agreement.page)
            }
            headings={{
              date: {
                label: context.t(`customers.contractHeading.date`),
              },
              name: {
                label: context.t(`sms.send.autoText.nameSurname`),
              },
              agreement_name: {
                label: context.t(`customers.contractHeading.agreement`),
              },
              bytes: {
                label: context.t(`customers.contractHeading.bytes`),
                suffix: "mb",
              },
              _: {
                label: context.t(`customers.contractHeading.transactions`),
              },
            }}
            rows={customer_agreements}
            loaded={agreement_load}
            pagination={pagination_agreement}
            buttons={[
              {
                title: "",
                icon: "find_in_page",
                textColor: "blue",
                onClick: (row) => window.open(row.url, "_blank"),
              },
              {
                title: "",
                icon: <WhatsApp fontSize="small" />,
                textColor: "green",
                onClick: (row) => {
                  if (!context.state.whatsapp_permission) {
                    toast.warning(
                      context.t(`customers.whatsappWarnToast`)
                    );
                  } else {
                    setWpAprovalModal(true);
                    setSendWpId(row.id);
                    setSendWpCustomerName(row.name);
                    setSendWpAgreementName(row.agreement_name);
                  }
                },
              },
              {
                title: "",
                icon: "sms",
                textColor: "orange",
                onClick: (row) => {
                  setSmsAprovalModal(true);
                  setSendSmsId(row.id);
                  setSendSmsCustomerName(row.name);
                  setSendSmsAgreementName(row.agreement_name);
                },
              },
              {
                title: "",
                icon: "delete_forever",
                textColor: "red",
                onClick: (row) => {
                  setAgreementDeleteId(row.id);
                  setDeleteAgreementDialog(true);
                },
              },
            ]}
          />
        </Column>

        {/* **
      sms onayı için açılan dialog
      */}
        <AlertDialog
          title={context.t(`customers.sendContractViaSMS`)}
          open={sms_aproval_modal}
          closeHandler={() => setSmsAprovalModal(false)}
          buttons={[
            {
              title: context.t(`component.sms_review.sendButtonTitle`),
              icon: "send",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                sendSMSAgreement(send_sms_id);
              },
            },
            {
              title: context.t(`component.sms_review.discardButtonTitle`),
              icon: "clear",
              textColor: "grey",
              onClick: () => {
                setSendSmsId(null);
                setSendSmsCustomerName(null);
                setSendSmsAgreementName(null);
              },
            },
          ]}
        >
          {context.t(`customers.sendContractMessage`).replace("%s", send_sms_customer_name).replace("%v", send_sms_agreement_name)}
        </AlertDialog>

        {/* **
      wp onayı için açılan dialog
      */}
        <AlertDialog
          title={context.t(`customers.sendContractViaWP`)}
          // title="Kayıtlı sözleşmeyi Whatsapp ile iletmek ister misiniz?"
          open={wp_aproval_modal}
          closeHandler={() => setWpAprovalModal(false)}
          buttons={[
            {
              title: context.t(`component.sms_review.sendButtonTitle`),
              icon: "send",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                sendWPAgreement(send_wp_id);
              },
            },
            {
              title: context.t(`component.sms_review.discardButtonTitle`),
              icon: "clear",
              textColor: "grey",
              onClick: () => {
                setSendWpId(null);
                setSendWpCustomerName(null);
                setSendWpAgreementName(null);
              },
            },
          ]}
        >
          {context.t(`customers.sendContractMessage`).replace("%s", send_wp_customer_name).replace("%v", send_wp_agreement_name)}
        </AlertDialog>

        <AlertDialog
          title={context.t(`customers.deleteContractTitle`)}
          open={delete_agreement_dialog}
          closeHandler={() => setDeleteAgreementDialog(false)}
          buttons={[
            {
              title: context.t(`customers.deleteContractAccept`),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                deleteAgreement(agreement_delete_id);
              },
            },
            {
              title: context.t(`branches.discardEditingButtonTitle`),
              icon: "close",
              textColor: "#ccc",
            },
          ]}
        >
          <Alert severity="warning">
            <b>{context.t(`customers.deleteContractWarn1`)}</b>
            <br />
            {context.t(`customers.deleteContractWarn2`)}
          </Alert>
        </AlertDialog>
      </Grid>
    </>
  );
};

const ImageUploadBox = styled.label`
  display: flex;
  width: -webkit-fill-available;
  padding: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #fefefe;
  border: 2px dotted #eaeaea;
  border-radius: 5px;
  cursor: pointer;
  color: #808080;
  font-size: 15px;
  font-weight: 500;
  transition: all ease 0.35s;

  &:hover {
    border: 2px dotted #dadada;
    color: #000;
  }

  i.material-icons {
    font-size: 21px;
    margin-right: 8px;
  }
`;
