import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
// import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";
import Select from "../../../theme/CustomMUI/Select";
import CardSection from "../../../components/Cards/CardSection";
import Axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import Validate from "../../../functions/Validate";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import CurrencyFormat from "react-currency-format";
import ConvertTimeToZulu from "../../../functions/ConvertTimeToZulu";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import { flushSync } from "react-dom";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import { Add, DateRange } from "@material-ui/icons";
import RemainingDebt from "../../../assets/images/svg/remaining_debt.svg";
import styled from "styled-components";
import Loader from "../../../assets/images/loader.gif";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import { DATE } from "@amcharts/amcharts4/core";

const ExpenseTransactions = () => {
  const context = useContext(AppContext);
  const location = useLocation();

  const [addNew, setAddNew] = useState({
    json_id: null,
    description: null,
    amount: null,
    transaction_date: ConvertTimeToZulu(moment().unix()), //now
  });
  const [payment, setPayment] = useState([
    {
      label: context.t(`["accounting/expense"].payments.cash`),
      value: "0",
    },
    {
      label: context.t(`["accounting/expense"].payments.creditCard`),
      value: "1",
    },
    {
      label: context.t(`["accounting/expense"].payments.transfer`),
      value: "2",
    },
    {
      label: context.t(`["accounting/expense"].payments.cusPoint`),
      value: "3",
    },
  ]);

  const [selectedPayment, setSelectedPayment] = useState(null);
  const [spender, setSpender] = useState("");

  const [typesLoaded, setTypesLoaded] = useState(false);
  const [expenseTypes, setExpenseTypes] = useState([]);

  const [loaded, setLoaded] = useState(false);
  const [expenseTransactions, setExpenseTransactions] = useState({
    replacements: {
      json_id: {},
    },
    pagination: {
      page: 1,
      total_page: 1,
      onChange: (page) => {
        setPages(page)
      },
    },
    data: [],
  });
  const setPages = (page) => {
    setExpenseTransactions((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        page: page,
      }
    }))
  }
  const [start, setStart] = useState(
    location.state
      ? `${location.state?.detail.start.split("/")[2]}-${location.state?.detail.start.split("/")[1]
      }-${location.state?.detail.start.split("/")[0]}`
      : moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState(
    location.state
      ? `${location.state?.detail.end.split("/")[2]}-${location.state?.detail.end.split("/")[1]
      }-${location.state?.detail.end.split("/")[0]}`
      : moment().format("YYYY-MM-DD")
  );
  const [filter, setFilter] = useState({
    start_date: moment().subtract(1, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });
  const [reportsDate, setReportsDate] = useState({
    between: {
      start: location.state
        ? `${location.state?.detail.start.split("/")[0]}/${location.state?.detail.start.split("/")[1]
        }/${location.state?.detail.start.split("/")[2]}`
        : moment().subtract(1, "days").format("DD/MM/YYYY"),
      end: location.state
        ? `${location.state?.detail.end.split("/")[0]}/${location.state?.detail.end.split("/")[1]
        }/${location.state?.detail.end.split("/")[2]}`
        : moment().format("DD/MM/YYYY"),
    },
  });
  const [filterPicker, setFilterPicker] = useState([
    {
      id: 1,
      label: "Bugün",
      offset: 1,
    },
    {
      id: 2,
      label: "Bu Hafta",
      offset: 3,
    },
    {
      id: 3,
      label: "Bu Ay",
      offset: 4,
    },
  ]);

  const [selectedFilterPicker, setSelectedFilterPicker] = useState(
    location.state
      ? null
      : {
        id: 1,
        label: "Bugün",
        offset: 1,
      },
  );

  const [isInsertNewExpenseButtonClicked, setIsInsertNewExpenseButtonClicked] = useState(false); // Control that button is checked to stop sending too request.

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState(null);

  const [newExpenseDialogModal, setNewExpenseDialogModal] = useState(false);
  const [totalExpense, setTotalExpense] = useState(null);
  const [comingData, setComingData] = useState(location.state ? location.state : null);

  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const [readyToUseFilter, setReadyToUseFilter] = useState(null);
  const [types, setTypes] = useState(null)
  const [result, setResult] = useState(null)
  //TODO : Get expense types from server
  const getExpenseTypes = () => {
    Axios.get(`${context.api_endpoint}/company/expense/categories`)
      .then(({ data }) => {
        setTypes(data?.data?.name ? JSON.parse(data?.data?.name) : null)
        setResult(Object.entries(JSON.parse(data?.data?.name))
          ?.filter(([_, value]) => value !== null && value.trim() !== "")
          ?.map(([key, value]) => ({ json_id: key, label: value })))
      })
      .then(() => {
        setTypesLoaded(true);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    if (types) {
      setExpenseTransactions((prev) => ({
        ...prev,
        replacements: {
          json_id: { ...types }
        }
      }))
    }
  }, [types, expenseTransactions.data])

  useEffect(() => {
    if (result) {
      setExpenseTypes(result);
    }
  }, [result])

  //TODO : List of Expense Transactions
  const getExpenseData = () => {
    setLoaded(false);
    Axios.get(
      `${context.api_endpoint}/company/accounting/expenses`,
      selectedFilterPicker === null
        ? {
          params: {
            page: expenseTransactions.pagination.page ? expenseTransactions.pagination.page : 1,
            isbetween: true,
            start: moment(start).format("YYYY-MM-DD"),
            end: moment(end).format("YYYY-MM-DD"),
          },
        }
        : {
          params: {
            page: expenseTransactions.pagination.page ? expenseTransactions.pagination.page : 1,
            isbetween: false,
            offset: selectedFilterPicker.offset,
          },
        }
    )
      .then(({ data }) => {
        setLoaded(true);
        setReportsDate({
          between: {
            start: moment(data.data.start_time.split("T")[0])
              .local()
              .format("DD/MM/YYYY"),
            end: moment(data.data.end_time.split("T")[0])
              .local()
              .format("DD/MM/YYYY"),
          },
        });
        setExpenseTransactions({
          ...expenseTransactions,
          data: [
            ...data.data.records.map((item) => {
              return {
                ...item,
                created_at: moment(item.created_at).format("LLL"),
                transaction_date: moment(item.transaction_date).format("LL"),
                payment_type:
                  item.payment_type !== null
                    ? payment.find(
                      (pay) => pay.value === item.payment_type.toString()
                    ).label
                    : "-",
                spender: item.spender ? item.spender : "",
              };
            }),
          ],
        });
        setTotalExpense(data.data.total_expense);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : Add new expense
  const insertNewExpense = () => {
    validateNewExpense() &&
      Axios.post(`${context.api_endpoint}/company/expense/insert`, {
        ...addNew,
        payment_type: selectedPayment
          ? parseInt(selectedPayment.value)
          : null,
        spender: spender ? spender : "",
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success(context.t(`['accounting/expense'].insertToast`));
            setIsInsertNewExpenseButtonClicked(false);
            getExpenseData();
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
          setIsInsertNewExpenseButtonClicked(false);
        });
  };

  //TODO : Clear Data
  const clearData = async () => {
    await setAddNew({
      json_id: null,
      description: "",
      amount: null,
      transaction_date: ConvertTimeToZulu(moment().unix()), //now
    });
    await setSelectedPayment(null);
    await setSpender("");
  };

  //TODO : Delete Expense
  const deleteSingleExpense = (expense_id) => {
    Axios.delete(
      `${context.api_endpoint}/company/expense/delete/${expense_id}`
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`['accounting/expense'].deleteToast`));
          getExpenseData();
        }
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          toast.error(e?.response?.data?.message);
        } else {
          toast.warning(e?.response?.data?.message);
        }
      });
  };

  useEffect(() => {
    getExpenseTypes();
  }, [newExpenseDialogModal]);

  useEffect(() => {
    getExpenseData();
  }, [selectedFilterPicker, expenseTransactions.pagination.page])

  const handleChange = (event, maskedvalue, floatvalue) => {
    console.log("maskedValue:: ", maskedvalue);
  }

  const validateNewExpense = () => {
    return Validate([
      {
        field: "Gider Tipi",
        value: addNew.json_id,
        condition: /\S+/,
      },
      {
        field: "Gider Tutarı",
        value: addNew.amount,
        condition: Number,
      },
    ]);
  };

  return (
    <AuthContainer
      makePlanUpgrade={[2]}
      authorities={[1]}
      limited_permission="accounting"
    >
      <Grid>
        <Column
          className="xs-12 sm-12 md-12 lg-12 mb-1 pb-2"
          style={{ borderBottom: "1px solid #b2b2b2" }}
        >
          <Column
            className="xs-12 sm-12 md-12 lg-6"
            style={{ height: "63px", display: "flex", alignItems: "center" }}
          >
            <ShortButtonContainer>
              <ShortButton
                onClick={async () => {
                  await setSelectedFilterPicker({
                    id: 1,
                    label: "Bugün",
                    offset: 1,
                  });
                  await getExpenseData();
                }}
                style={{
                  backgroundColor: `${selectedFilterPicker?.id === 1
                    ? "rgb(49, 169, 243, 0.14)"
                    : ""
                    }`,
                  border: "1px solid rgb(91, 187, 245, 0.41)",
                  color: "rgb(49, 169, 243)",
                }}
              >
                {context.t(`component.dateRangeStats.today`)}
              </ShortButton>
              <ShortButton
                onClick={async () => {
                  await setSelectedFilterPicker({
                    id: 2,
                    label: "Bu Hafta",
                    offset: 3,
                  });
                  await getExpenseData();
                }}
                style={{
                  backgroundColor: `${selectedFilterPicker?.id === 2
                    ? "rgb(29, 139, 62, 0.21)"
                    : ""
                    }`,
                  border: "1px solid rgb(58, 154, 87, 0.35)",
                  color: "rgb(29, 139, 62)",
                }}
              >
                {context.t(`component.dateRangeStats.week`)}
              </ShortButton>
              <ShortButton
                onClick={async () => {
                  await setSelectedFilterPicker({
                    id: 3,
                    label: "Bu Ay",
                    offset: 4,
                  });
                  await getExpenseData();
                }}
                style={{
                  backgroundColor: `${selectedFilterPicker?.id === 3
                    ? "rgb(254, 151, 151, 0.19)"
                    : ""
                    }`,
                  border: "1px solid rgb(185, 178, 178, 0.65)",
                  color: "rgb(254, 111, 111)",
                }}
              >
                {context.t(`component.dateRangeStats.month`)}
              </ShortButton>
            </ShortButtonContainer>
          </Column>
          <Column
            className="xs-12 sm-12 md-12 lg-6"
            style={{ borderLeft: "1px solid #b2b2b2" }}
          >
            <FilterContainer>
              <FilterButton
                onClick={() => {
                  setFilterModalOpen(true);
                  setSelectedFilterPicker(null);
                  setReadyToUseFilter(1);
                  setFilter({
                    start_date: moment()
                      .local()
                      .subtract(1, "days")
                      .toISOString(),
                    end_date: moment().local().toISOString(),
                  });
                  setReportsDate({
                    between: {
                      start: moment()
                        .local()
                        .subtract(1, "days")
                        .format("DD/MM/YYYY"),
                      end: moment().local().format("DD/MM/YYYY"),
                    },
                  });
                }}
              >
                {" "}
                <DateRange />
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "1.2em",
                    paddingLeft: "1vw",
                  }}
                >
                  {reportsDate.between.start} -{" "}
                  {reportsDate.between.end}
                </span>
              </FilterButton>

              <FilterButton
                style={{
                  cursor: "auto",
                  backgroundColor: "rgba(208, 233, 233, 0.65)",
                  color: "#54AEAE",
                  border: "1px solid rgba(208, 233, 233, 0.8)",
                }}
              >
                <img
                  src={RemainingDebt}
                  alt="remaining_debt"
                  style={{ marginRight: "10px", color: "red" }}
                />
                {context.t(`["accounting/expense"].totalExpense`)}{" "}
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "1.2em",
                    paddingLeft: "1vw",
                  }}
                >
                  {`${parseInt(totalExpense)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}${context.state.currency
                      ? context.state.currency
                      : "₺"
                    }`}
                </span>
              </FilterButton>
            </FilterContainer>
          </Column>
        </Column>
      </Grid>

      <Grid>
        <Column
          className="xs-12 sm-12 md-12 lg-12"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Column className="xs-12 sm-12 md-12 lg-3">
            <FilterButton
              style={{
                height: "36px",
                marginBottom: "0",
                color: "#3189ec",
                border: "1px solid #3189ec",
                background: "rgba(49, 137, 236, 0.08)",
              }}
              onClick={() => {
                setNewExpenseDialogModal(true);
              }}
            >
              <Add />
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "1.2em",
                  paddingLeft: "1vw",
                }}
              >
                {context.t(`[accounting/expense].add.title`)}
              </span>
            </FilterButton>
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-3">
            <ExcelButton
              route="accounting/expenses"
              size="lg"
              fullWidth={true}
              requestData={
                selectedFilterPicker === null
                  ? {
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(end).format("YYYY-MM-DD"),
                    isbetween: true,
                    is_excel: true,
                  }
                  : {
                    isbetween: false,
                    offset: selectedFilterPicker.offset,
                    is_excel: true,
                  }
              }
            />{" "}
          </Column>
        </Column>
      </Grid>

      <Grid>
        <Column className="xs-12 sm-12">
          <CardSection
            title={context.t(`['accounting/expense'].table.title`)}
            className="no-gutters-all"
            includesColumn={false}
          >
            {loaded ? (
              <Table
                refreshAction={() =>
                  getExpenseData(
                    expenseTransactions.pagination.page
                  )
                }
                loaded={loaded}
                headings={{
                  created_at: {
                    label: context.t(
                      `['accounting/expense'].table.headings.createdAt`
                    ),
                    style: { width: 170 },
                  },
                  transaction_date: {
                    label: context.t(
                      `['accounting/expense'].table.headings.transactionDate`
                    ),
                    // sortable: {
                    //   0: [
                    //     {
                    //       field: "`expenses`.`transaction_date`",
                    //       order: "ASC",
                    //     },
                    //   ],
                    //   1: [
                    //     {
                    //       field: "`expenses`.`transaction_date`",
                    //       order: "DESC",
                    //     },
                    //   ],
                    // },
                  },
                  json_id: {
                    label: context.t(
                      `['accounting/expense'].table.headings.type`
                    ),
                  },
                  description: {
                    label: context.t(
                      `['accounting/expense'].table.headings.description`
                    ),
                    limited_line: 1,
                    style: { width: 300 },
                  },
                  payment_type: {
                    label: context.t(
                      `['accounting/expense'].add.paymentType`
                    ),
                  },
                  spender: {
                    label: context.t(
                      `['accounting/expense'].add.spending`
                    ),
                  },
                  amount: {
                    label: context.t(
                      `['accounting/expense'].table.headings.amount`
                    ),
                    suffix: context.state.currency
                      ? context.state.currency
                      : "₺",
                  },
                  _: { label: context.t(`component.actionHeadingText`) },
                }}
                rows={expenseTransactions.data}
                pagination={expenseTransactions.pagination}
                replacements={expenseTransactions.replacements}
                buttons={[
                  {
                    title: context.t(
                      `['accounting/expense'].table.deleteButtonTitle`
                    ),
                    icon: "delete_forever",
                    textColor: "red",
                    onClick: (row) => {
                      setDeleteConfirm(true);
                      setDeleteRowId(row.id);
                    },
                  },
                ]}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <img
                  src={
                    process.env.APP_NAME === "salon"
                      ? Loader
                      : process.env.APP_NAME === "en"
                        ? LoaderEN
                        : LoaderSM
                  }
                  width="100"
                  height="100"
                  alt="loading"
                />
              </div>
            )}
          </CardSection>
        </Column>
      </Grid>

      <AlertDialog
        title={`${context.t(`['accounting/expense'].add.dialogTitle`)}`}
        open={newExpenseDialogModal}
        closeHandler={() => {
          setNewExpenseDialogModal(false);
        }}
        maxWidth="md"
        buttons={[
          {
            title: `${context.t(
              `['accounting/expense'].add.buttonTitle`
            )}`,
            icon: "check",
            backgroundColor: "primary",
            textColor: "white",
            fullWidth: true,
            onClick: async () => {
              if (selectedPayment !== null) {
                setIsInsertNewExpenseButtonClicked(true);
                insertNewExpense();
                await clearData();
              } else {
                toast.warning(`${context.t(`['accounting/expense'].add.buttonTitle`)}`);
              }
            },
          },
          {
            title: `${context.t(`['accounting/expense'].add.cancelButtonTitle`)}`,
            icon: "close",
            textColor: "#ccc",
            outlined: true,
            onClick: () => {
              setNewExpenseDialogModal(false);
              clearData();
            },
          },
        ]}
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12 mb-3">
            <Column className="xs-12 sm-12 md-12 lg-3">
              {console.log(expenseTypes)}
              <Select
                label={context.t(
                  `['accounting/expense'].add.typeInputLabel`
                )}
                items={typesLoaded ? expenseTypes : null}
                labelKey="label"
                valueKey="json_id"
                selected={addNew.json_id !== null ? addNew.json_id : ""}
                handler={(json_id) =>
                  setAddNew({
                    ...addNew,
                    json_id,
                  })
                }
              />
            </Column>

            <Column className="xs-12 sm-12 md-12 lg-3">
              <CurrencyFormat
                label={context.t(
                  `['accounting/expense'].add.amountInputLabel`
                )}
                placeholder={context.t(
                  `['accounting/expense'].add.amountInputPlaceholder`
                )}
                selected={addNew.amount !== null ? addNew.amount : ""}
                customInput={Input}
                decimalScale={2}
                value={addNew.amount ? addNew.amount : ""}
                suffix={
                  context.state.currency
                    ? context.state.currency
                    : "₺"
                }
                onValueChange={(e) => {
                  let amountValue = parseFloat(e.floatValue) || 0.0;

                  if (Number.isInteger(amountValue)) {
                    amountValue = amountValue.toFixed(2);
                  }

                  if (Number.isNaN(e.floatValue)) {
                    amountValue = 0.0;
                  }

                  if (typeof amountValue === "string") {
                    amountValue = parseFloat(amountValue);
                  }

                  setAddNew({
                    ...addNew,
                    amount: amountValue,
                  });
                }}
              />
            </Column>

            <Column className="xs-12 sm-12 md-12 lg-3">
              <Select
                label={context.t(
                  `['accounting/credit'].payments.typeInputLabel`
                )}
                placeholder={context.t(
                  `['accounting/credit'].payments.typeInputPlaceholder`
                )}
                items={payment}
                selected={
                  selectedPayment &&
                  selectedPayment.value
                }
                labelKey="label"
                valueKey="value"
                handler={async (payment_type) => {
                  await setSelectedPayment({
                    ...selectedPayment,
                    value: payment_type,
                  });
                }}
              />
            </Column>

            <Column className="xs-12 sm-12 md-12 lg-3">
              <Input
                label={context.t(`['accounting/expense'].add.spending`)}
                placeholder={context.t(
                  `['accounting/expense'].add.spendingPlaceholder`
                )}
                value={spender ? spender : ""}
                onChange={(e) => {
                  setSpender(e.target.value);
                }}
              />
            </Column>
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-12">
            <Column className="xs-12 sm-12 md-12 lg-6">
              <Input
                label={context.t(
                  `['accounting/expense'].add.descriptionInputLabel`
                )}
                placeholder={context.t(
                  `['accounting/expense'].add.descriptionInputHint`
                )}
                multiline={true}
                rows={2}
                value={
                  addNew.description
                    ? addNew.description
                    : ""
                }
                onChange={(e) => {
                  setAddNew({
                    ...addNew,
                    description: e.target.value
                  });
                }}
              />
            </Column>

            <Column className="xs-12 sm-12 md-12 lg-6">
              <DatePickerInput
                label={context.t(
                  `['accounting/expense'].add.processDate`
                )}
                value={addNew.transaction_date}
                onChange={async (process_date) => {
                  await setAddNew({
                    ...addNew,
                    transaction_date: ConvertTimeToZulu(
                      moment(process_date).unix()
                    ),
                  });
                }}
              />
            </Column>
          </Column>
        </Grid>
      </AlertDialog>

      <AlertDialog
        title={context.t(`['accounting/expense'].add.dialogQATitle`)}
        fullWidth
        open={deleteConfirm}
        closeHandler={() => setDeleteConfirm(false)}
        buttons={[
          {
            title: context.t(`services.delete.confirmButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            timer: 5,
            onClick: () => deleteSingleExpense(deleteRowId),
          },
          {
            title: context.t(`services.delete.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          <b>{context.t(`['accounting/expense'].dialogQAWarn1`)}</b>
          <br />
          {context.t(`['accounting/expense'].dialogQAWarn2`)}
        </Alert>
      </AlertDialog>

      <AlertDialog
        title={
          <h3 style={{ textAlign: "center" }}>
            {context.t(`accounting.filterArea.header`)}
          </h3>
        }
        disableBackdropClick={false}
        open={filterModalOpen}
        maxWidth="sm"
        closeHandler={() => {
          setFilterModalOpen(false);
        }}
        buttons={[
          {
            title: context.t(`accounting.filterArea.approve`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: async () => {
              await getExpenseData();
            },
          },
          {
            title: context.t(`accounting.filterArea.cancel`),
            icon: "close",
            textColor: "grey",
            onClick: () => {
              setReadyToUseFilter(null);
              setSelectedFilterPicker({
                id: 1,
                label: "Bugün",
                offset: 1,
              });
            },
          },
        ]}
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12 mt-3 mb-5">
            <Column className="xs-12 sm-12 md-12 lg-6">
              <ShortButtonContainer
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <ShortButton
                  style={{
                    border: "1px solid rgba(249,186,132, 0.51)",
                    color: "rgb(219,146,92)",
                    backgroundColor: `${readyToUseFilter === 1
                      ? "rgba(249,186,132, 0.21)"
                      : ""
                      }`,
                  }}
                  onClick={() => {
                    setReadyToUseFilter(1);
                    setStart(moment().local().subtract(1, "days").toISOString());
                    setEnd(moment().local().toISOString());
                    setReportsDate({
                      between: {
                        start: moment()
                          .local()
                          .subtract(1, "days")
                          .format("DD/MM/YYYY"),
                        end: moment().local().format("DD/MM/YYYY"),
                      },
                    });
                  }}
                >
                  {context.t(`accounting.filterArea.yesterday`)}
                </ShortButton>
                <ShortButton
                  style={{
                    border: "1px solid rgb(91, 187, 245, 0.41)",
                    color: "rgb(49, 169, 243)",
                    backgroundColor: `${readyToUseFilter === 2
                      ? "rgb(49, 169, 243, 0.14)"
                      : ""
                      }`,
                  }}
                  onClick={() => {
                    setReadyToUseFilter(2);
                    setStart(moment().local().subtract(7, "days").toISOString());
                    setEnd(moment().local().toISOString());
                    setReportsDate({
                      between: {
                        start: moment()
                          .local()
                          .subtract(7, "days")
                          .format("DD/MM/YYYY"),
                        end: moment().local().format("DD/MM/YYYY"),
                      },
                    });
                  }}
                >
                  {context.t(`accounting.filterArea.lastSevenDays`)}
                </ShortButton>
                <ShortButton
                  style={{
                    border: "1px solid rgb(58, 154, 87, 0.35)",
                    color: "rgb(29, 139, 62)",
                    backgroundColor: `${readyToUseFilter === 3
                      ? "rgb(29, 139, 62, 0.21)"
                      : ""
                      }`,
                  }}
                  onClick={() => {
                    setReadyToUseFilter(3);
                    setStart(moment().local().subtract(15, "days").toISOString());
                    setEnd(moment().local().toISOString());
                    setReportsDate({
                      between: {
                        start: moment()
                          .local()
                          .subtract(15, "days")
                          .format("DD/MM/YYYY"),
                        end: moment().local().format("DD/MM/YYYY"),
                      },
                    });
                  }}
                >
                  {context.t(`accounting.filterArea.lastFifteenDays`)}
                </ShortButton>
                <ShortButton
                  style={{
                    border: "1px solid rgb(185, 178, 178, 0.65)",
                    color: "rgb(254, 111, 111)",
                    backgroundColor: `${readyToUseFilter === 4
                      ? "rgb(254, 151, 151, 0.19)"
                      : ""
                      }`,
                  }}
                  onClick={() => {
                    setReadyToUseFilter(4);
                    setStart(moment().local().subtract(30, "days").toISOString());
                    setEnd(moment().local().toISOString());
                    setReportsDate({
                      between: {
                        start: moment()
                          .local()
                          .subtract(30, "days")
                          .format("DD/MM/YYYY"),
                        end: moment().local().format("DD/MM/YYYY"),
                      },
                    });
                  }}
                >
                  {context.t(`accounting.filterArea.lastThirtyDays`)}
                </ShortButton>
              </ShortButtonContainer>
            </Column>

            <Column
              className="xs-12 sm-12 md-12 lg-6"
              style={{ height: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderLeft: "1px solid #b2b2b2",
                  height: "100%",
                }}
              >
                <Column className="xs-12 sm-12 md-12 lg-12">
                  <DatePickerInput
                    label={context.t(`reports.startInputLabel`)}
                    value={new Date(start)}
                    maxDate={new Date(end)}
                    onChange={(date) => {
                      setReadyToUseFilter(null);
                      setStart(moment(date).local().toISOString());
                      setReportsDate({
                        ...reportsDate,
                        between: {
                          ...reportsDate.between,
                          start: moment(date).local().format("DD/MM/YYYY"),
                        },
                      });
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12">
                  <DatePickerInput
                    label={context.t(`reports.endInputLabel`)}
                    value={new Date(String(end).split("T")[0])}
                    minDate={start}
                    onChange={(date) => {
                      setReadyToUseFilter(null);
                      setEnd(moment(date).local().toISOString());
                      setReportsDate({
                        ...reportsDate,
                        between: {
                          ...reportsDate.between,
                          end: moment(date).local().format("DD/MM/YYYY"),
                        },
                      });
                    }}
                  />
                </Column>
              </div>
            </Column>
          </Column>
        </Grid>
      </AlertDialog>
    </AuthContainer>
  );
}

const ShortButton = styled.div`
  border-radius: 8px;
  width: 12vw;
  padding: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
`;

const ShortButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const FilterButton = styled.div`
  border-radius: 8px;
  padding: 1vh 2.5vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  margin-bottom: 10px;
  color: rgb(219, 146, 92);
  border: 1px solid rgba(249, 186, 132, 0.51);
  background-color: rgba(249, 186, 132, 0.21);
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

export default ExpenseTransactions;
