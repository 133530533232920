import { useHistory, useLocation } from "react-router-dom";

const useHandleParams = () => {
  const history = useHistory();
  const location = useLocation();

  const handleParams = (type, value) => {
    if (type && value) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(type, value);
      history.push({ search: searchParams.toString() });
    } else {
      console.error('useHandleParams fonksiyonuna boş type veya value attın.');
    }
  };

  return handleParams;
};

export default useHandleParams;
