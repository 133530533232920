/* eslint-disable array-callback-return */
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../context/store";
import Axios from "axios";
import { TimerOff, InfoOutlined, Launch } from "@material-ui/icons";
import {
  FormLabel,
  Tooltip,
  IconButton,
  TextField,
  MenuItem,
} from "@material-ui/core";
import Loader from "../../../assets/images/loader.gif";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import { toast } from "react-toastify";
import { ButtonBase } from "@material-ui/core";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import Input from "../../../theme/CustomMUI/Input";
import styled from "styled-components";
import Button from "../../../theme/Button";
import { phoneNumberDisplay } from "../../../functions/PhoneNumberDisplay";
import { Column, Grid } from "../../../theme/Grid";
import CSwitch from "../../../theme/CustomMUI/CSwitch";
import TimeSelect from "../../../theme/TimeSelect";
import Select from "../../../theme/CustomMUI/Select";
import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";
import AppointmentCalendar from "../../../functions/NewAppointmentCalendar";
import _ from "lodash";
import CustomerAddDialog from "../../../components/Dialogs/CustomerAddDialog";
import CenterDialogModal from "../../../components/Alerts/CenterDialogModal";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import Switch from "@material-ui/core/Switch";
import HomeChcek from "../../../assets/images/svg/home-check.svg";
import HomeChcekGreen from "../../../assets/images/svg/home-check-green.svg";
import Approved from "../../../assets/images/svg/approved.svg";
import ApprovedBlue from "../../../assets/images/svg/approved-blue.svg";
import NotCome from "../../../assets/images/svg/not-come.svg";
import NotComeRed from "../../../assets/images/svg/not-come-red.svg";
import Waiting from "../../../assets/images/svg/waiting.svg";
import WaitingOrange from "../../../assets/images/svg/waiting-orange.svg";
import Process from "../../../assets/images/svg/process.svg";
import ProcessPurple from "../../../assets/images/svg/process-purple.svg";
import Quit from "../../../assets/images/svg/quit.svg";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import CustomerSearchInput from "../../../theme/CustomMUI/CustomerSearchInput";
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "#3189ec",
        fontSize: "12px",
        backgroundColor: "white",
        boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
      },
    },
  },
});

const WeekAppointment = (props) => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [appointment, setAppointment] = useState([]);

  const [drag_drop_delete_appointment_id, setDrag_drop_delete_appointment_id] =
    useState(null);
  const [customer_add_modal, setCustomerAddModal] = useState(false);
  const [
    create_appointment_modal_collective,
    setCreateAppointmentModalCollective,
  ] = useState(false);
  const [new_appointment, setNewAppointment] = useState({
    services: {},
  });
  const [dialogModal, setDialogModal] = useState({
    isOpen: false,
    message: "",
    type: "warning",
  });

  const [clock_off, setClockOff] = useState([]);
  const [selected_clock, setSelectedClock] = useState(null);
  const [appointment_detail_modal_clock, setAppointmentDetailModalClock] =
    useState(false);
  const [create_appointment_modal_clock, setCreateAppointmentModalClock] =
    useState(false);

  const [tableLargeRow] = useState(false);
  const [isMenuOpen] = useState(false);
  const [appointment_detail_modal, setAppointmentDetailModal] = useState(false);
  const [cancelAppointmentDialog, setCancelAppointmentDialog] = useState(false);
  const [time_upgrade, setTimeUpgrade] = useState(false);
  const [open_update_appointment_dialog, setOpenUpdateAppointmentDialog] =
    useState(false);
  const [create_appointment_modal, setCreateAppointmentModal] = useState(false);
  const [insertData, setInsertData] = useState({
    selected_staff: null,
    selected_service: null,
    selected_package: null,
    selected_customer: null,
    selected_date: new Date().toISOString().split("T")[0],
    selected_time_clock_off_start: null,
    selected_time_clock_off_end: null,
    selected_note: "",
    note: null,
    add_date: null,
    services_by_staff: [],
    selected_description: "",
    selected_time: null,
    hours: [...BetweenTimeGenerator(context.state.company_shift)],
  });
  const [send_sms, setSendSMS] = useState(true);
  // const [services, setServices] = useState([]);
  const [_staffs, setStaffs] = useState([]);
  const [staff_week, setStaffWeek] = useState([]);
  const [now_indicator] = useState(
    `${AppointmentCalendar(
      null,
      context.state.company_shift,
      null
    ).getNowIndicatorTopMargin()}%`
  );

  const [info_message_seen, setInfoMessageSeen] = useState(false);
  const [turn_off_all_day, setTurnOffAllDay] = useState(false);
  const [repetition_frequency_modal, setRepetitionFrequencyModal] =
    useState(false);
  const [repetition] = useState([
    {
      id: 1,
      name: "Her gün",
      count: 1,
    },
    {
      id: 2,
      name: "2 günde bir",
      count: 2,
    },
    {
      id: 3,
      name: "3 günde bir",
      count: 3,
    },
    {
      id: 4,
      name: "4 günde bir",
      count: 4,
    },
    {
      id: 5,
      name: "5 günde bir",
      count: 5,
    },
    {
      id: 6,
      name: "Haftada bir",
      count: 7,
    },
    {
      id: 7,
      name: "2 haftada bir",
      count: 14,
    },
    {
      id: 8,
      name: "3 haftada bir",
      count: 21,
    },
    {
      id: 9,
      name: "4 haftada bir",
      count: 28,
    },
    {
      id: 10,
      name: "Her ay",
      count: 30,
    },
    {
      id: 11,
      name: "45 günde bir",
      count: 45,
    },
    {
      id: 12,
      name: "2 ayda bir",
      count: 60,
    },
    {
      id: 13,
      name: "3 ayda bir",
      count: 90,
    },
    {
      id: 14,
      name: "6 ayda bir",
      count: 180,
    },
  ]);
  const [update_appoint_disable, setUpdateAppointDisable] = useState(true);
  const [hour_detail, setHourDetail] = useState({
    start_hour: context.state.company_shift.start_hour,
    end_hour: context.state.company_shift.end_hour,
  });
  const [change_end_hour, setChangeEndHour] = useState(false);
  const [appointment_note, setAppointmentNote] = useState(null);
  const [appointment_hover, setAppointmentHover] = useState(null);
  const [is_hover, setIsHover] = useState(false);
  const [selected_staff_hover, setSelectedStaffHover] = useState(null);

  const [selected_repetition, setSelectedRepetition] = useState(null);
  const [repetition_count, setRepetitionCount] = useState(0);
  const [online_appointment, setOnlineAppointment] = useState(false);
  const [recurenct_modal, setRecurrentModal] = useState(false);
  const [appointment_date, setAppointmentDate] = useState(new Date());
  const [serviceOrPackage, setServiceOrPackage] = useState(1);
  const [payloadObj, setPayloadObj] = useState([]);
  const [openAppointmentPackage, setOpenAppointmentPackage] = useState(false);
  const [cancelAppointmentArray, setCancelAppointmentArray] = useState([]);

  //TODO : Month Appointment 五条悟
  const getWeekAppointments = async () => {
    await Axios.post(`${context.api_endpoint}/company/appointments/week`, {
      start_date: moment(props.start_date).format("YYYY-MM-DD"),
      choice: 0,
      staff_id: [],
    })
      .then(({ data }) => {
        console.log("week", data);
        // week appointment
        setStaffs([...data.data]);
        setLoaded(true);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  //TODO GET Staff
  const beforeInsertRequiredFields = () => {
    Axios.get(`${context.api_endpoint}/company/appointments/index2`)
      .then(({ data }) => {
        const staffs = [
          ...data.data.map((staff) => {
            staff.services.forEach((service, index) => {
              if (
                service.service.category_name !== null &&
                service.service.category_name.toLowerCase().includes("erkek")
              ) {
                service.service.name += " (E)";
              } else if (
                service.service.category_name !== null &&
                service.service.category_name.toLowerCase().includes("kadın")
              ) {
                service.service.name += " (K)";
              }
            });
            return staff;
          }),
        ];
        // setServices([...data.data.services]);
        setStaffWeek([
          ...staffs
            .map((staff) => {
              return staff;
            })
            .filter((staff) => staff !== null),
        ]);
        setLoaded(true);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : İnsert Appointment
  const insertAppointment = () => {
    let appointment_time = insertData.selected_time
      ? insertData.selected_time.split(":")
      : ["09", "00"];
    let appointment_date = `${moment(insertData.selected_date).format(
      "YYYY-MM-DD"
    )}T${appointment_time[0]}:${appointment_time[1]}:00Z`;
    Axios.post(`${context.api_endpoint}/company/appointment/insert`, {
      staff_id: insertData.selected_staff.id,
      customer_id: insertData.selected_customer.id,
      [serviceOrPackage === 1 ? "service_id" : "packet_id"]:
        serviceOrPackage === 1
          ? insertData.selected_service.id
          : insertData.selected_package.id,
      send_sms: insertData.selected_customer
        ? insertData.selected_customer.send_sms === false
          ? false
          : true
        : false,
      appointment_date,
      note: insertData.note,
      is_web: true,
    })

      .then(async (response) => {
        if (response.status === 201) {
          toast.success(context.t(`appointments.insertToast`));
          setCreateAppointmentModal(false);
          setLoaded(false);
          await getWeekAppointments();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : İnsert Appointment
  const bulkInsertAppointment = () => {
    let appointment_time = insertData.selected_time
      ? insertData.selected_time.split(":")
      : ["09", "00"];
    let appointment_date = `${moment(insertData.selected_date).format(
      "YYYY-MM-DD"
    )}T${appointment_time[0]}:${appointment_time[1]}:00Z`;

    Axios.post(`${context.api_endpoint}/company/appointment/bulk/insert`, {
      customer_id: insertData.selected_customer.id,
      services: [
        ...Object.keys(new_appointment.services)
          .filter((key) => new_appointment.services[key] !== undefined)
          .map((item) =>
            Object.assign({ ...new_appointment.services[item] }, {})
          ),
      ],
      send_sms: insertData.selected_customer
        ? insertData.selected_customer.send_sms === false
          ? false
          : true
        : false,
      appointment_date,
      note: insertData.note,
    })
      .then(async (response) => {
        if (response.status === 201) {
          toast.success(context.t(`appointments.insertToast`));
          await getWeekAppointments();
          beforeInsertRequiredFields();
          setCreateAppointmentModal(false);
          setLoaded(false);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : Search Customer
  const searchCustomerByKey = async (key) => {
    let arr = [];
    await Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    })
      .then(({ data }) => {
        arr = [...data.data.records];
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
    return arr.map((item) => {
      return {
        id: item.id,
        full_name: `${item.full_name} (*** ${item.phone.slice(6, 11)})`,
        send_sms: item.send_sms,
        description: item.description,
      };
    });
  };

  //TODO: Get Close Appointment Clock Time
  const getAppointmentClockTime = async () => {
    const appointment_date = moment(insertData.selected_date)
      .isoWeekday(1)
      .format("YYYY-MM-DD");
    await Axios.get(
      `${context.api_endpoint}/company/appointment/staff/active/date`,
      {
        params: {
          date: `${appointment_date}T00:00:00.000Z`,
          choice: 1,
        },
      }
    )
      .then(({ data }) => {
        setClockOff(data?.data?.map((item) => item));
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO: Close Appointment Delete
  const deleteAppointmentClockTime = async (clock_id) => {
    await Axios.delete(
      `${context.api_endpoint}/company/appointment/staff/active/date/${clock_id}`
    )
      .then(({ data }) => {
        if (data.status === 200) {
          toast.success(data.data);
          setAppointmentDetailModalClock(false);
          getAppointmentClockTime();
        } else {
          toast.error(data.message);
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO: Close modal when user clicks outside of it
  const closeAppointmentClockTime = async () => {
    let appointment_date_clock_off_start = new Date(insertData.selected_date);
    let appointment_time_start =
      insertData.selected_time_clock_off_start.split(":");
    appointment_date_clock_off_start = `${moment(
      appointment_date_clock_off_start
    ).format("YYYY-MM-DD")}T${appointment_time_start[0]}:${appointment_time_start[1]
      }:00Z`;

    let appointment_date_clock_off_end = new Date(insertData.selected_date);
    let appointment_time_end =
      insertData.selected_time_clock_off_end.split(":");
    appointment_date_clock_off_end = `${moment(
      appointment_date_clock_off_end
    ).format("YYYY-MM-DD")}T${appointment_time_end[0]}:${appointment_time_end[1]
      }:00Z`;

    await Axios.post(
      `${context.api_endpoint}/company/appointment/staff/active/date`,
      {
        staff_id: insertData.selected_staff.id,
        start_hour: appointment_date_clock_off_start,
        end_hour: appointment_date_clock_off_end,
        all_hours_closed: turn_off_all_day === false ? false : true,
        day_closed: false,
        note: insertData.note ?? "",
        repetition_frequency: selected_repetition
          ? selected_repetition.count
          : 0,
        repetition_count: repetition_count ? parseInt(repetition_count) : 0,
        is_platform_included: online_appointment === true ? true : false,
      }
    )
      .then(({ data }) => {
        if (data.status === 200) {
          toast.success("Başarıyla eklendi.");
          setCreateAppointmentModalClock(false);
          getAppointmentClockTime();
        } else {
          toast.error("Bir hata oluştu");
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO: Update Appointment
  const updateAppoint = async (apoint_id) => {
    const time = parseInt(time_upgrade);

    const time_hours =
      parseInt(
        Math.floor(
          moment(appointment.appointment_end_date)
            .format("HH:mm")
            .split(":")[0] * 60
        ) +
        parseInt(
          moment(appointment.appointment_end_date)
            .format("HH:mm")
            .split(":")[1]
        )
      ) -
      parseInt(
        Math.floor(
          moment(appointment.appointment_start_date)
            .format("HH:mm")
            .split(":")[0] * 60
        ) +
        parseInt(
          moment(appointment.appointment_start_date)
            .format("HH:mm")
            .split(":")[1]
        )
      );

    let time_appointment_start = new Date(appointment_date);
    time_appointment_start = `${moment(time_appointment_start).format(
      "YYYY-MM-DD"
    )}T${hour_detail.start_hour}:00Z`;

    let time_appointment_end = new Date(appointment_date);
    time_appointment_end = `${moment(time_appointment_end).format(
      "YYYY-MM-DD"
    )}T${hour_detail.end_hour}:00Z`;

    if (hour_detail.start_hour > hour_detail.end_hour) {
      toast.warning("Bitiş saati Başlangıç saatinden küçük olamaz!");
    } else {
      await Axios.put(
        `${context.api_endpoint}/company/appointment/update`,
        isNaN(time)
          ? {
            id: apoint_id ? apoint_id : 1,
            appointment_start_date: time_appointment_start,
            appointment_end_date: time_appointment_end,
            send_sms: send_sms,
            note: appointment_note,
          }
          : {
            id: apoint_id ? apoint_id : 1,
            appointment_start_date: time_appointment_start,
            send_sms: send_sms,
            appointment_time: time ? time : time_hours,
            note: appointment_note,
          }
      )
        .then(({ data }) => {
          if (data.status === 201) {
            setAppointmentDetailModal(false);
            setTimeUpgrade(null);
            getWeekAppointments();
            toast.success(
              context.t(
                `appointments.updateAppointment.updateAppointmentSuccessToast`
              )
            );
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    }
  };

  //TODO: Services by Staffs
  const searchServicesByKey = async (key) => {
    let arr = [];
    await Axios.get(`${context.api_endpoint}/company/services/filter`, {
      params: {
        id: insertData.selected_staff ? insertData.selected_staff.id : null,
        key: key === " " ? "" : key,
        paginate: 1,
      },
    })
      .then(({ data }) => {
        arr = [
          ...data.data.filter(
            (m) => m && m.is_passive !== undefined && m.is_passive === false
          ),
        ];
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
    return arr;
  };

  const searchPackagesById = async (key) => {
    let arr = [];
    console.log("key", key);
    await Axios.get(
      `${context.api_endpoint}/company/packet/byuser/${insertData.selected_staff ? insertData.selected_staff.id : null
      }`,
      {
        params: {
          page: 1,
        },
      }
    )
      .then(({ data }) => {
        console.log("packagesData: ", data);
        const packages = data.data
          .filter((m) => m.packet !== null)
          .map((p) => {
            console.log("p", p);
            if (
              p.packet.name !== null &&
              p.packet.name.toLowerCase().includes("erkek")
            ) {
              p.packet.name += " (E)";
            } else if (
              p.packet.name !== null &&
              p.packet.name.toLowerCase().includes("kadın")
            ) {
              p.packet.name += " (K)";
            }
            return p.packet;
          });
        arr = [...packages];
      })
      .catch((e) => {
        console.log(e);
      });
    return arr;
  };

  //TODO : transfer to a function
  const transferAppointment = async () => {
    let appointment_date = new Date(insertData.selected_date);
    Axios.post(`${context.api_endpoint}/company/appointment/transfer`, {
      id: drag_drop_delete_appointment_id,
      staff_id: insertData.selected_staff.id,
      appointment_date: `${moment(appointment_date).format("YYYY-MM-DD")}T${insertData.selected_time.split(":")[0]
        }:${insertData.selected_time.split(":")[1]}:00Z`,
      send_sms: true,
    })
      .then(async () => {
        toast.success(
          context.t(
            `appointments.updateAppointment.updateAppointmentSuccessToast`
          )
        );
        await getWeekAppointments();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO: Insert Random Customer
  const insertRandomCustomer = () => {
    Axios.post(`${context.api_endpoint}/company/customer/insert/walkin`)
      .then((response) => {
        // console.log(response)
        if (response.status === 201) {
          // console.log(response.data.data.id)
          setInsertData({
            ...insertData,
            selected_customer: response.data.data.id,
          });
        }
      })
      .catch((e) => {
        // console.log(e.response.data.message)
        toast.warning(e.response.data.message);
      });
  };

  //TODO : go to receipt detail page
  const openReceiptByAppointment = (appointment_id) => {
    Axios.post(
      `${context.api_endpoint}/company/receipt/insert/by/appointment`,
      appointment.packet !== null
        ? {
          appointment_id,
          period_day_between: payloadObj,
        }
        : {
          appointment_id,
        }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`dashboard.openReceiptOnSuccessToast`));
          history.push(`/receipts/detail/${response.data.data.id}`);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const addServiceInPackageSale = () => {
    setNewAppointment({
      ...new_appointment,
      services: {
        ...new_appointment.services,
        [Object.keys(new_appointment.services).length + 1]: {
          service_id: null,
          staff_id: context.state.user.id,
        },
      },
    });
  };

  const deleteServiceInPackageSale = (key) => {
    setNewAppointment({
      ...new_appointment,
      services: {
        ...new_appointment.services,
        [key]: undefined,
      },
    });
  };

  const fieldChanger = (section, key, payload) => {
    setNewAppointment({
      ...new_appointment,
      [section]: {
        ...new_appointment[section],
        [key]: {
          ...new_appointment[section][key],
          ...payload,
        },
      },
    });
  };

  //TODO: Handle open of warning modal
  const handleOpen = (message, type) => {
    setDialogModal({
      isOpen: true,
      message: message,
      type: type,
    });
  };
  //TODO: Handle close of warning modal
  const handleClose = () => {
    setDialogModal({
      ...dialogModal,
      isOpen: false,
    });
  };

  //TODO : Customer state change to cancel
  const updateAppointmentState = (appointmentId, customerState) => {
    Axios.put(`${context.api_endpoint}/company/appointment/customer/state`, {
      appointment_id: appointmentId,
      customer_state: customerState,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(
            context.t(
              `appointments.detail.appointmentStatus.changeStatusSuccessToast`
            )
          );
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : time upgrade modal
  const upgradeAppointmentTime = (timeId) => {
    const time = parseInt(time_upgrade);
    Axios.post(`${context.api_endpoint}/company/appointment/update/enddate`, {
      appointment_id: timeId ?? null,
      time,
    })
      .then(async () => {
        setLoaded(true);
        setAppointmentDetailModal(false);
        setTimeUpgrade(null);
        toast.success(
          context.t(`appointments.detail.appointmentalAlertMessage`)
        );
        await getWeekAppointments();
        toast.success(
          context.t(
            `appointments.updateAppointment.updateAppointmentSuccessToast`
          )
        );
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODOD: Cancel Appointment
  const cancelAppointment = (appointment_id) => {
    Axios.post(`${context.api_endpoint}/company/appointment/cancel`, {
      appointment_id,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`appointments.deleteToast`));
        }
        getWeekAppointments();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const groupStaffByAppointmentDate = (staff) => {
    return (
      _.chain(staff)
        /*.filter(item => {
              const startDate = moment(new Date(item.appointment_start_date));
              const endDate = moment(new Date(item.appointment_end_date));
              return moment(state.selected_date).isBetween(startDate, endDate);
          })*/
        .groupBy("appointment_start_date")
        .filter((item) => item)
        .map((value, key) => ({ date: key, appointments: value }))
        .value()
    );
  };

  //TODO : useEffect
  useEffect(() => {
    beforeInsertRequiredFields();
    getWeekAppointments();
    getAppointmentClockTime();
  }, [props.start_date]);
  useEffect(() => {
    if (insertData.selected_package?.is_passive) {
      toast.warn(context.t("appointments.create.select_package_alert"));
      setInsertData((prev) => ({
        ...prev,
        selected_package: null,
      }));
      console.log("insertData.selected_package", insertData.selected_package);
    }
  }, [insertData.selected_package]);
  return (
    <>
      {/* WEEK FIELD */}
      {loaded ? (
        <div style={{ width: "90wv", overflowX: "scroll" }}>
          <Grid className="mt-4">
            <Column>
              <Grid className="no-gutters-all">
                <Column style={{ width: "40px" }}>
                  <TableView>
                    <tbody>
                      {insertData.hours.map((hour) => (
                        <TableRow
                          style={{ border: 0 }}
                          className="hour-metric"
                          key={hour}
                        >
                          <TableColumn
                            largerow={tableLargeRow ? 1 : 0}
                            week={1}
                            style={{ border: 0, borderLeft: "initial" }}
                          >
                            <HourLabel
                              className={
                                moment(
                                  `${moment(insertData.selected_date).format(
                                    "YYYY-MM-DD"
                                  )} ${hour}:00`
                                ).isBefore(moment())
                                  ? "_past"
                                  : "_future"
                              }
                            >
                              {hour}
                            </HourLabel>
                          </TableColumn>
                        </TableRow>
                      ))}
                    </tbody>
                  </TableView>
                </Column>

                <div id="appointment-container" style={{ marginLeft: "10px" }}>
                  <ArtboardContainer
                    id="ArtboardContainer"
                    staffCount={_staffs.length}
                    largerow={tableLargeRow ? 1 : 0}
                  >
                    <TableView
                      id="table-employees-header"
                      style={{
                        position: "relative",
                        transition: "all 0.2s linear",
                        zIndex: "11",
                      }}
                    >
                      <thead>
                        <TableRow style={{ border: 0 }}>
                          {_staffs &&
                            _staffs.map((staff, index) => {
                              return (
                                <TableColumn
                                  // ref="calenderRef"
                                  className="stickyNav"
                                  key={index}
                                  style={{
                                    border: "0 1px solid #EFEFEF",
                                  }}
                                  largerow={tableLargeRow ? 1 : 0}
                                  week={1}
                                >
                                  <ColumnInner
                                    staffCount={_staffs.length}
                                    largerow={tableLargeRow ? 1 : 0}
                                  >
                                    <StaffHeading background={staff.color}>
                                      <h3>
                                        {moment(staff.date).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </h3>
                                    </StaffHeading>
                                  </ColumnInner>
                                </TableColumn>
                              );
                            })}
                        </TableRow>
                      </thead>
                    </TableView>

                    <TableContainer
                      style={{
                        zIndex: isMenuOpen ? "-1" : "10",
                      }}
                    >
                      {moment(insertData.selected_date).format("LL") ===
                        moment(new Date()).format("LL") && (
                          <DateNowIndicator
                            style={{
                              top: now_indicator,
                              background: "red",
                            }}
                          />
                        )}
                      {
                        // Randevu Kartları
                        _staffs.map((staff) => {
                          return groupStaffByAppointmentDate(
                            staff.appointments
                          ).map((appointmentGroup) => {
                            let appointments = appointmentGroup.appointments;

                            return appointments
                              ?.filter((el) =>
                                props?.selected_id?.includes(el?.staff?.id)
                              )
                              ?.map((appointment, index) => {
                                let appointmentIndexFromGroup =
                                  appointments.indexOf(appointment);
                                const theme = createTheme({
                                  overrides: {
                                    MuiTooltip: {
                                      tooltip: {
                                        zIndex: "999999999",
                                        backgroundColor:
                                          appointment.customer_state === 0
                                            ? "#CBECC9"
                                            : appointment.customer_state === 1
                                              ? "#ED8286"
                                              : appointment.customer_state === 3
                                                ? "#D6FAFA"
                                                : appointment.customer_state === 4
                                                  ? "#EDDEFC"
                                                  : "#FFDDB0",
                                        borderRadius: "10px",
                                        width: "250px",
                                        boxShadow:
                                          "0px 0px 5px rgba(0,0,0,0.2)",
                                      },
                                    },
                                  },
                                });
                                return (
                                  <MuiThemeProvider theme={theme}>
                                    <Tooltip
                                      title={
                                        <TooltipCardInner>
                                          <TooltipCardRow
                                            style={{
                                              justifyContent: "normal",
                                              marginBottom: "5px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginLeft: "8px",
                                              }}
                                            >
                                              <span
                                                className="limited-line-1"
                                                style={{
                                                  fontSize: "20px",
                                                  marginTop: "5px",
                                                }}
                                              >
                                                {
                                                  appointment.customer
                                                    ?.full_name
                                                }
                                              </span>
                                              <span>
                                                {moment(
                                                  appointment.appointment_start_date
                                                )
                                                  .tz("Europe/Istanbul")
                                                  .format("HH:mm")}
                                                -
                                                {moment(
                                                  appointment.appointment_end_date
                                                )
                                                  .tz("Europe/Istanbul")
                                                  .format("HH:mm")}
                                                {" / "}
                                                {appointment.customer_state ===
                                                  0 ? (
                                                  <span
                                                    style={{
                                                      fontSize: "12px",
                                                      color: "#528859",
                                                    }}
                                                  >
                                                    Geldi
                                                  </span>
                                                ) : (
                                                  <>
                                                    {appointment.customer_state ===
                                                      1 ? (
                                                      <span
                                                        style={{
                                                          fontSize: "12px",
                                                          color: "#9A2020",
                                                        }}
                                                      >
                                                        Gelmedi
                                                      </span>
                                                    ) : (
                                                      <>
                                                        {appointment.customer_state ===
                                                          3 ? (
                                                          <span
                                                            style={{
                                                              fontSize: "12px",
                                                              color: "#0b8bc1",
                                                            }}
                                                          >
                                                            Onaylı Randevu
                                                          </span>
                                                        ) : (
                                                          <>
                                                            {appointment.customer_state ===
                                                              4 ? (
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "12px",
                                                                  color:
                                                                    "#c4aec0",
                                                                }}
                                                              >
                                                                İşlemde
                                                              </span>
                                                            ) : (
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "12px",
                                                                  color:
                                                                    "#995A1F",
                                                                }}
                                                              >
                                                                Yeni
                                                              </span>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </span>
                                            </div>
                                          </TooltipCardRow>
                                          <div
                                            style={{
                                              border:
                                                "1px solid rgba(162, 162, 162, 0.12)",
                                              marginBottom: "5px",
                                            }}
                                          ></div>

                                          <TooltipCardRow>
                                            <span className="limited-line-1">
                                              {appointment.service &&
                                                appointment.service.name}
                                            </span>
                                            {((context.state.user.permission ===
                                              2 &&
                                              context.state.limited_permission
                                                .customer_info !== 0) ||
                                              context.state.user.permission !==
                                              2) && (
                                                <span
                                                  style={{
                                                    marginLeft: "105px",
                                                  }}
                                                >
                                                  0{appointment.customer?.phone}
                                                </span>
                                              )}
                                          </TooltipCardRow>
                                          <TooltipCardRow>
                                            <span>
                                              {appointment.staff.full_name}
                                            </span>
                                          </TooltipCardRow>
                                          <div
                                            style={{
                                              border:
                                                "1px solid rgba(162, 162, 162, 0.12)",
                                              marginBottom: "5px",
                                              marginTop: "5px",
                                            }}
                                          ></div>
                                          <TooltipCardRow>
                                            Not:
                                            <span
                                              style={{
                                                marginLeft: "10px",
                                                marginTop: "-2px",
                                              }}
                                            >
                                              {appointment.note
                                                ? appointment.note
                                                : "-"}
                                            </span>
                                          </TooltipCardRow>
                                        </TooltipCardInner>
                                      }
                                      placement="top"
                                    >
                                      <AppointmentCard
                                        staffCount={_staffs.length}
                                        largerow={tableLargeRow ? 1 : 0}
                                        draggable={true}
                                        onDragStart={(e) => {
                                          setIsHover(false);
                                          e.dataTransfer.setData(
                                            "appointment",
                                            JSON.stringify(appointment)
                                          );
                                          setInsertData({
                                            ...insertData,
                                            selected_staff: appointment.staff,
                                          });
                                        }}
                                        onDragCapture={(e) => {
                                          e.currentTarget.style.opacity = "0.7";
                                          e.currentTarget.style.border =
                                            "1px solid black";
                                          e.currentTarget.style.background =
                                            "white";
                                          e.currentTarget.style.color = "black";
                                        }}
                                        onDragEnd={(e) => {
                                          e.currentTarget.style.opacity = "1";
                                          e.currentTarget.style.border = "none";
                                          e.currentTarget.style.background =
                                            AppointmentCalendar(
                                              appointment
                                            ).getCardBackgroundColor();
                                          e.currentTarget.style.color =
                                            AppointmentCalendar(
                                              appointment
                                            ).getCardTextColor();
                                        }}
                                        key={
                                          _staffs.indexOf(staff) +
                                          "-" +
                                          appointmentGroup.date +
                                          appointment.appointment_start_date +
                                          index
                                        }
                                        onMouseOver={() => {
                                          if (info_message_seen === false) {
                                            setInfoMessageSeen(true);
                                            toast.info(
                                              context.t(
                                                `appointments.onMouseOverCardToast`
                                              )
                                            );
                                          }
                                        }}
                                        onMouseEnter={() => {
                                          setAppointmentHover(appointment);
                                          setSelectedStaffHover(staff);
                                          setIsHover(true);
                                        }}
                                        onMouseLeave={() => {
                                          setIsHover(false);
                                        }}
                                        backgroundColor={AppointmentCalendar(
                                          appointment
                                        ).getCardBackgroundColor()}
                                        textColor={AppointmentCalendar(
                                          appointment
                                        ).getCardTextColor()}
                                        style={{
                                          // left: offsetX,
                                          ...AppointmentCalendar(
                                            appointment,
                                            context.state.company_shift,
                                            staff.appointments,
                                            {
                                              groupCount: appointments.length,
                                              appointmentIndexFromGroup,
                                              staffIndexOf:
                                                _staffs.indexOf(staff),
                                              week: true,
                                            },
                                            tableLargeRow,
                                            _staffs.length
                                          ).getStyle(),
                                        }}
                                        onClick={() => {
                                          setHourDetail({
                                            start_hour: moment(
                                              appointment.appointment_start_date
                                            ).format("HH:mm"),
                                            end_hour: moment(
                                              appointment.appointment_end_date
                                            ).format("HH:mm"),
                                          });
                                          setAppointmentDate(
                                            moment(
                                              appointment.appointment_start_date
                                            ).format("YYYY-MM-DD")
                                          );
                                          setAppointment(appointment);
                                          setAppointmentNote(appointment.note);
                                          setInsertData({
                                            ...insertData,
                                            selected_staff: staff,
                                          });
                                          setAppointmentDetailModal(true);
                                        }}
                                      >
                                        <AppointmentCardInner>
                                          <AppointmentRow>
                                            {/* <Person /> */}
                                            <span
                                              className="limited-line-1"
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {appointment.customer?.full_name}
                                            </span>
                                            {appointment.customer_state ===
                                              0 ? (
                                              <img
                                                src={HomeChcek}
                                                alt="home-check"
                                              />
                                            ) : (
                                              <>
                                                {appointment.customer_state ===
                                                  1 ? (
                                                  <img
                                                    src={NotCome}
                                                    alt="note-come"
                                                  />
                                                ) : (
                                                  <>
                                                    {appointment.customer_state ===
                                                      3 ? (
                                                      <img
                                                        src={Approved}
                                                        alt="approved"
                                                      />
                                                    ) : (
                                                      <>
                                                        {appointment.customer_state ===
                                                          4 ? (
                                                          <img
                                                            style={{
                                                              transform:
                                                                "scaleX(-1)",
                                                            }}
                                                            src={Process}
                                                            alt="process"
                                                          />
                                                        ) : (
                                                          <img
                                                            src={Waiting}
                                                            alt="waiting"
                                                          />
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </AppointmentRow>

                                          <AppointmentRow>
                                            {/* <Layers /> */}
                                            <span>
                                              {appointment.service &&
                                                appointment.service.name}
                                            </span>
                                          </AppointmentRow>

                                          <AppointmentRow>
                                            {/* <AccessAlarm /> */}
                                            <span
                                            // style={{ fontWeight: "bold" }}
                                            >
                                              {moment(
                                                appointment.appointment_start_date
                                              )
                                                .tz("Europe/Istanbul")
                                                .format("HH:mm")}{" "}
                                              -{" "}
                                              {moment(
                                                appointment.appointment_end_date
                                              )
                                                .tz("Europe/Istanbul")
                                                .format("HH:mm")}
                                            </span>
                                          </AppointmentRow>
                                        </AppointmentCardInner>
                                      </AppointmentCard>
                                    </Tooltip>
                                  </MuiThemeProvider>
                                );
                              });
                          });
                        })
                      }

                      {/* Randevu Kartları */}
                      <TableView>
                        <tbody>
                          {insertData.hours.map((hour) => (
                            <TableRow key={hour + "it_askdjask"}>
                              {_staffs.map((staff, index) => (
                                <TableColumn
                                  staffCount={_staffs.length}
                                  key={index + "_ti_sdkjs_" + index}
                                  largerow={tableLargeRow ? 1 : 0}
                                  week={1}
                                  onDragOver={(e) => {
                                    e.preventDefault();
                                    e.target.style.border = "2px solid black";
                                  }}
                                  onDragLeave={(e) => {
                                    e.target.style.border = "none";
                                  }}
                                  onDropCapture={(e) => {
                                    e.target.style.border = "none";
                                  }}
                                  onDrop={async (e) => {
                                    e.preventDefault();
                                    let data = JSON.parse(
                                      e.dataTransfer.getData("appointment")
                                    );
                                    setInsertData({
                                      ...insertData,
                                      add_date: index,
                                      selected_time: hour,
                                      selected_time_clock_off_start: hour,
                                      selected_time_clock_off_end: hour,
                                      selected_date: staff.date,
                                      selected_service: data.service,
                                      selected_package: data.packet,
                                      selected_staff: insertData.selected_staff,
                                      selected_customer: data.customer,
                                      note: data.note,
                                    });
                                    setSendSMS(true);
                                    setDrag_drop_delete_appointment_id(data.id);
                                    setOpenUpdateAppointmentDialog(true);
                                  }}
                                >
                                  <ColumnInner
                                    staffCount={_staffs.length}
                                    largerow={tableLargeRow ? 1 : 0}
                                  >
                                    <ButtonBase
                                      style={{
                                        background:
                                          new Date(
                                            `${moment(insertData.selected_date)
                                              .tz("Europe/Istanbul")
                                              .format("YYYY-MM-DD")} ${hour}`
                                          ) <
                                          new Date(
                                            `${moment()
                                              .tz("Europe/Istanbul")
                                              .format("YYYY-MM-DD HH:mm")}`
                                          ) &&
                                          "repeating-linear-gradient(-45deg,#dee2e6 1%,white 2%)",
                                      }}
                                      onClick={() => {
                                        //  Her saate randevu alınma durumu kontrolü
                                        if (
                                          _staffs.appointments &&
                                          _staffs.appointments.length > 0 &&
                                          _staffs.appointments
                                            .map(
                                              (item) =>
                                                hour ===
                                                moment(
                                                  item.appointment_start_date
                                                )
                                                  .tz("Europe/Istanbul")
                                                  .format("HH:mm")
                                            )
                                            .filter((item) => item === true)
                                            .length > 0
                                        ) {
                                          toast.warning(
                                            "Oluşturmaya çalıştığınız randevu saatinde başlayan bir randevu mevcut!",
                                            {
                                              autoClose: 10 * 1000,
                                            }
                                          );
                                        }
                                        setInsertData({
                                          ...insertData,
                                          selected_staff: staff,
                                          selected_time: hour,
                                          selected_service: null,
                                          selected_package: null,
                                          selected_time_clock_off_start: hour,
                                          selected_time_clock_off_end: hour,
                                          selected_customer: null,
                                          note: null,
                                          selected_date: staff.date,

                                          // services_by_staff: [
                                          //   ...staff.services.map((service) => {
                                          //     return service.service !== null
                                          //       ? {
                                          //           ...service.service,
                                          //         }
                                          //       : undefined;
                                          //   }),
                                          // ].filter((item) => item !== undefined),
                                        });
                                        setSendSMS(true);
                                        setServiceOrPackage(1);
                                        setCreateAppointmentModal(true);

                                        // Geçmiş bir saate randevu alma durumu kontrolü
                                        // if (
                                        //   new Date(
                                        //     `${moment(
                                        //       state.selected_date
                                        //     ).format("YYYY-MM-DD")} ${hour}`
                                        //   ) < new Date()
                                        // ) {
                                        //   toast.warning(
                                        //     context.t(
                                        //       `appointments.pastCreateErrorToast`
                                        //     )
                                        //   );
                                        // } else {
                                        //   // Aynı saate randevu alınamama durumu kontrolü

                                        // }
                                      }}
                                    />
                                  </ColumnInner>
                                </TableColumn>
                              ))}
                            </TableRow>
                          ))}
                        </tbody>
                      </TableView>
                    </TableContainer>
                  </ArtboardContainer>
                </div>
              </Grid>
            </Column>
            <Column className="xs-12 sm-12 mt-4 mb-2">
              <MuiThemeProvider theme={theme}>
                <Tooltip
                  style={{ marginTop: "-15px", marginLeft: "15px" }}
                  placement="top-end"
                  title={
                    <div>
                      <ColorMean>
                        <div
                          className="color"
                          style={{ backgroundColor: "#FFDDB0" }}
                        >
                          <div className="overlay" />
                        </div>
                        <span>
                          {context.t(
                            (context.current_flag &
                              context.state.FLAGS.SALON_RANDEVU &&
                              `appointments.definitionYellowText`) ||
                            `appointments.definitionYellowText_en`
                          )}
                        </span>
                      </ColorMean>

                      <ColorMean>
                        <div
                          className="color"
                          style={{ backgroundColor: "#D6FAFA" }}
                        >
                          <div className="overlay" />
                        </div>
                        <span>
                          {context.t(
                            (context.current_flag &
                              context.state.FLAGS.SALON_RANDEVU &&
                              `appointments.definitionGreenText`) ||
                            `appointments.definitionGreenText_en`
                          )}
                        </span>
                      </ColorMean>

                      <ColorMean>
                        <div
                          className="color"
                          style={{ backgroundColor: "#CBECC9" }}
                        >
                          <div className="overlay" />
                        </div>
                        <span>
                          {context.t("appointments.definitionLightText")}
                        </span>
                      </ColorMean>

                      <ColorMean>
                        <div
                          className="color"
                          style={{ backgroundColor: "#EDDEFC" }}
                        >
                          <div className="overlay" />
                        </div>
                        <span>
                          {context.t("appointments.definitionPurpleText")}
                        </span>
                      </ColorMean>

                      <ColorMean>
                        <div
                          className="color"
                          style={{ backgroundColor: "#ED8286" }}
                        >
                          <div className="overlay" />
                        </div>
                        <span>
                          {context.t(
                            (context.current_flag &
                              context.state.FLAGS.SALON_RANDEVU &&
                              `appointments.definitionRedText`) ||
                            `appointments.definitionRedText_en`
                          )}
                        </span>
                      </ColorMean>
                    </div>
                  }
                >
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </MuiThemeProvider>
            </Column>
          </Grid>
        </div>
      ) : (
        <AlignContainer>
          <img
            src={
              process.env.APP_NAME === "salon"
                ? Loader
                : process.env.APP_NAME === "en"
                  ? LoaderEN
                  : LoaderSM
            }
            width="100"
            height="100"
            alt="loading"
          />
        </AlignContainer>
      )}
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        fullWidth={true}
        maxWidth="sm"
        title={context.t(`appointments.detail.clockOffDetail`)}
        open={appointment_detail_modal_clock}
        closeHandler={() => setAppointmentDetailModalClock(false)}
        disableBackdropClick={false}
        buttons={[
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: context.t(`appointments.detail.deleteButton`),
            icon: "check",
            backgroundColor: "red-opacity",
            textColor: "red",
            onClick: () =>
              selected_clock.is_recurrent === true
                ? setRecurrentModal(true)
                : deleteAppointmentClockTime(selected_clock.id),
          },
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: context.t(`appointments.detail.closeButtonTitle`),
            icon: "close",
            backgroundColor: "black-opacity",
            textColor: "grey",
            onClick: () => setTimeUpgrade(null),
          },
        ].filter((btn) => btn.show & context.app.ID)}
      >
        {selected_clock && (
          <>
            <div style={{ display: "flex" }}>
              <DetailCard
                icon="alarm"
                color="teal"
                label={context.t(`appointments.detail.clockOffDateInputLabel`)}
                value={`${moment(selected_clock.start_hour)
                  .tz("Europe/Istanbul")
                  .format("LLL")} - ${moment(selected_clock.end_hour)
                    .tz("Europe/Istanbul")
                    .format("HH:mm")}`}
              />
            </div>

            <DetailCard
              icon="how_to_reg"
              color="blue"
              label={context.t(`appointments.detail.clockOffStaffInputLabel`)}
              value={insertData.selected_staff?.full_name ?? "-"}
            />

            <DetailCard
              icon="description"
              color="orange"
              label={context.t(`appointments.detail.clockOffNoteInputLabel`)}
              value={
                selected_clock.note !== null &&
                  selected_clock.note !== undefined &&
                  selected_clock.note !== ""
                  ? selected_clock.note
                  : "-"
              }
            />
          </>
        )}
      </AlertDialog>
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        fullWidth={true}
        maxWidth="sm"
        open={recurenct_modal}
        closeHandler={() => setRecurrentModal(false)}
        disableBackdropClick={false}
        buttons={[
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: "Evet",
            icon: "check",
            backgroundColor: "green-opacity",
            textColor: "green",
            onClick: () => {
              deleteAppointmentClockTime(selected_clock.id, 1);
            },
          },
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: "Hayır",
            icon: "close",
            backgroundColor: "red-opacity",
            textColor: "red",
            onClick: () => {
              deleteAppointmentClockTime(selected_clock.id);
            },
          },
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: "Vazgeç",
            icon: "close",
            backgroundColor: "grey-opacity",
            textColor: "grey",
            onClick: () => setTimeUpgrade(null),
          },
        ].filter((btn) => btn.show & context.app.ID)}
      >
        <Alert severity="warning">
          <p>
            Bu tekrarlayan bir etkinliktir. Diğer tekrarlayan etkinlikleri de
            silmek ister misiniz?
          </p>
        </Alert>
      </AlertDialog>
      {/* Apoointment detail dialog */}
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        fullWidth={true}
        maxWidth="md"
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h2 className="MuiTypography-root MuiTypography-h6">
                {context.t(`appointments.detail.title`)}
              </h2>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {appointment && (
                <>
                  {appointment.customer_state === 0 ? (
                    <DetailDiv
                      style={{
                        backgroundColor: "rgba(181, 206, 180, 0.65)",
                      }}
                    >
                      <img src={HomeChcekGreen} alt="home-check-green" />
                      <span
                        style={{
                          color: "#628F5F",
                        }}
                      >
                        Geldi
                      </span>
                    </DetailDiv>
                  ) : (
                    <>
                      {appointment.customer_state === 1 ? (
                        <DetailDiv
                          style={{
                            backgroundColor: "rgba(220, 145, 148, 0.65)",
                          }}
                        >
                          <img src={NotComeRed} alt="note-come-red" />
                          <span
                            style={{
                              color: "#D4242B",
                            }}
                          >
                            Gelmedi
                          </span>
                        </DetailDiv>
                      ) : (
                        <>
                          {appointment.customer_state === 3 ? (
                            <DetailDiv
                              style={{
                                backgroundColor: "rgba(208, 233, 233, 0.65)",
                              }}
                            >
                              <img src={ApprovedBlue} alt="approved-blue" />
                              <span
                                style={{
                                  color: "#54AEAE",
                                }}
                              >
                                Onaylı
                              </span>
                            </DetailDiv>
                          ) : (
                            <>
                              {appointment.customer_state === 4 ? (
                                <DetailDiv
                                  style={{
                                    backgroundColor:
                                      "rgba(198, 163, 233, 0.65)",
                                  }}
                                >
                                  <img
                                    style={{
                                      transform: "scaleX(-1)",
                                    }}
                                    src={ProcessPurple}
                                    alt="process-purple"
                                  />
                                  <span
                                    style={{
                                      color: "#7A45AE",
                                    }}
                                  >
                                    İşlemde
                                  </span>
                                </DetailDiv>
                              ) : (
                                <DetailDiv
                                  style={{
                                    backgroundColor:
                                      "rgba(255, 226, 188, 0.65)",
                                  }}
                                >
                                  <img
                                    src={WaitingOrange}
                                    alt="waiting-orange"
                                  />
                                  <span
                                    style={{
                                      color: "#FFB95E",
                                    }}
                                  >
                                    Yeni
                                  </span>
                                </DetailDiv>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              <Button
                title={"Kaydet"}
                backgroundColor="primary-opacity"
                disabled={update_appoint_disable}
                textColor="primary"
                style={{
                  width: "120px",
                  marginLeft: "15px",
                }}
                fullWidth
                onClick={() => updateAppoint(appointment.id)}
              />
              <img
                src={Quit}
                alt="quit"
                style={{
                  marginLeft: "10px",
                  marginBottom: "35px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (
                    update_appoint_disable === false &&
                    updateAppoint(appointment.id)
                  ) {
                    toast.warning(
                      "Yapmış olduğunuz değişiklikler kaydetmeyi unutmayınız!"
                    );
                  } else {
                    setAppointmentDetailModal(false);
                  }
                }}
              />
            </div>
          </div>
        }
        open={appointment_detail_modal}
        closeHandler={() => setAppointmentDetailModal(false)}
        disableBackdropClick={false}
        buttons={[
          {
            show:
              context.state.FLAGS.SALON_RANDEVU |
              context.state.FLAGS.EN_RANDEVU,
            title: `${appointment?.receipt ? "Adisyona Git" : "Adisyon Aç"}`,
            iconComponent: Launch,
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () =>
              appointment?.receipt
                ? history.push(`/receipts/detail/${appointment?.receipt.id}`)
                : context.state.company_license.accounting === 1 ||
                  context.state.company_license.accounting === 3
                  ? appointment.packet !== null
                    ? (setOpenAppointmentPackage(true),
                      setPayloadObj(
                        appointment.packet.packet_details.map((m) => ({
                          id: m?.service?.id,
                          time: 7,
                        }))
                      ))
                    : openReceiptByAppointment(appointment.id)
                  : toast.warning(
                    "Bu özelliği kullanabilmek için Premium paket satın almalısınız."
                  ),
          },
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: context.t(`appointments.detail.deleteButtonTitle`),
            backgroundColor: "red-opacity",
            iconComponent: TimerOff,
            textColor: "red",
            onClick: () => setCancelAppointmentDialog(true),
          },
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: "Randevu Geçmişi",
            icon: "event",
            backgroundColor: "purple-opacity",
            textColor: "purple",
            onClick: () =>
              history.push(`/customers/detail/${appointment.customer.id}`, {
                tab: 2,
              }),
          },
        ].filter((btn) => btn.show & context.app.ID)}
      >
        {appointment && (
          <>
            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                <DatePickerInput
                  label={context.t(`appointments.detail.dateInputLabel`)}
                  value={appointment_date}
                  format="DD MMMM dddd HH:mm"
                  onChange={async (selected_date) => {
                    setAppointmentDate(selected_date);
                    setUpdateAppointDisable(false);
                  }}
                />
              </Column>

              <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                <TimeSelect
                  label={context.t(`['settings/hours'].startHour`)}
                  type="all"
                  value={
                    hour_detail.start_hour !== null
                      ? hour_detail.start_hour
                      : ""
                  }
                  fullWidth={true}
                  size="medium"
                  minuteStep={5}
                  handler={(time) => {
                    setHourDetail({ ...hour_detail, start_hour: time });
                    setUpdateAppointDisable(false);
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                <Input
                  type="number"
                  disabled={change_end_hour}
                  label={context.t(`appointments.detail.appointmentTime`)}
                  placeholder={context.t(
                    `appointments.detail.appointmentTimeExtension`
                  )}
                  value={
                    time_upgrade !== null
                      ? time_upgrade
                      : parseInt(
                        Math.floor(
                          moment(appointment.appointment_end_date)
                            .format("HH:mm")
                            .split(":")[0] * 60
                        ) +
                        parseInt(
                          moment(appointment.appointment_end_date)
                            .format("HH:mm")
                            .split(":")[1]
                        )
                      ) -
                      parseInt(
                        Math.floor(
                          moment(appointment.appointment_start_date)
                            .format("HH:mm")
                            .split(":")[0] * 60
                        ) +
                        parseInt(
                          moment(appointment.appointment_start_date)
                            .format("HH:mm")
                            .split(":")[1]
                        )
                      )
                  }
                  onChange={(e) => {
                    setTimeUpgrade(e.target.value);
                    setUpdateAppointDisable(false);
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                <TimeSelect
                  label={context.t(`['settings/hours'].endHour`)}
                  type="all"
                  disabled={time_upgrade === null ? false : true}
                  value={
                    hour_detail.end_hour !== null ? hour_detail.end_hour : ""
                  }
                  fullWidth={true}
                  size="medium"
                  minuteStep={5}
                  handler={(time) => {
                    setHourDetail({ ...hour_detail, end_hour: time });
                    setChangeEndHour(true);
                    setUpdateAppointDisable(false);
                  }}
                />
              </Column>
            </Grid>

            <Column className="xs-12 sm-12 md-4 mb-2">
              <CSwitch
                label={context.t(`appointments.create.sendSmsInputLabel`)}
                checked={send_sms}
                color="primary"
                onChange={(send_sms) => {
                  setSendSMS(send_sms.target.checked);
                  setUpdateAppointDisable(false);
                }}
              />
            </Column>

            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                {appointment.service && (
                  <DetailCard
                    icon="layers"
                    color="purple"
                    label={context.t(`appointments.detail.serviceInputLabel`)}
                    value={appointment.service?.name ?? "-"}
                    sabValue={
                      appointment.service?.amount
                        ? `${appointment.service?.amount} TL`
                        : "0"
                    }
                  />
                )}
                {appointment.packet && (
                  <DetailCard
                    icon={<LocalMallIcon />}
                    color="pink"
                    label="Uygulanacak Paket"
                    value={appointment.packet?.name ?? "-"}
                    sabValue={
                      appointment.packet?.all_amount
                        ? `${appointment.packet?.all_amount} TL`
                        : "0"
                    }
                  />
                )}
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                <DetailCard
                  icon="how_to_reg"
                  color="red"
                  label={context.t(`appointments.detail.staffInputLabel`)}
                  value={appointment.staff?.full_name ?? "-"}
                />
              </Column>
            </Grid>

            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                <DetailCard
                  icon="person"
                  color="blue"
                  label={context.t(`appointments.detail.customerInputLabel`)}
                  value={appointment.customer?.full_name ?? "-"}
                  iconValue={"edit"}
                  click={() => {
                    history.push(
                      `/customers/detail/${appointment.customer.id}`
                    );
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                {" "}
                <DetailCard
                  icon="call"
                  color="green"
                  label={context.t(
                    `appointments.detail.customerPhoneInputLabel`
                  )}
                  value={
                    context.state.user.permission === 2 &&
                      context.state.limited_permission.customer_info === 0
                      ? phoneNumberDisplay(appointment.customer.phone)
                      : appointment.customer
                        ? `0${appointment.customer.phone}`
                        : "-"
                  }
                />
              </Column>
            </Grid>

            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                <Input
                  label={context.t(`appointments.detail.noteInputLabel`)}
                  placeholder={"Randevu Notu Giriniz"}
                  value={
                    appointment_note !== null
                      ? appointment_note
                      : appointment.note
                  }
                  onChange={(e) => {
                    setAppointmentNote(e.target.value);
                    setUpdateAppointDisable(false);
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                <DetailCard
                  icon="description"
                  color="black"
                  label={context.t(`appointments.detail.customerNote`)}
                  value={
                    appointment?.customer?.description !== null &&
                      appointment?.customer?.description !== undefined &&
                      appointment?.customer?.description !== ""
                      ? appointment?.customer?.description
                      : "-"
                  }
                  iconValue={"edit"}
                  click={() => {
                    history.push(
                      `/customers/detail/${appointment.customer.id}`
                    );
                  }}
                />
              </Column>
            </Grid>

            <FormLabel component="legend">
              {context.t(`appointments.detail.appointmentStatus.title`)}
            </FormLabel>

            <DivStatus className="mt-2 mb-2">
              {appointment.customer_state === 0 ? (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      backgroundColor: "rgba(181, 206, 180, 0.65)",
                    }}
                  >
                    <img src={HomeChcekGreen} alt="home-check-green" />
                    <span
                      style={{
                        color: "#628F5F",
                      }}
                    >
                      Geldi
                    </span>
                  </DetailDivOption>
                </Column>
              ) : (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAppointment({
                        ...appointment,
                        customer_state: 0,
                      });
                      updateAppointmentState(appointment.id, 0);
                    }}
                  >
                    <img src={HomeChcekGreen} alt="home-check-green" />
                    <span
                      style={{
                        color: "#628F5F",
                      }}
                    >
                      Geldi
                    </span>
                  </DetailDivOption>
                </Column>
                // <FormControlLabel
                //   style={{
                //     width: "24%",
                //   }}
                //   value="female"
                //   control={<Radio value="0" />}
                //   label={

                //   }
                // />
              )}
              {appointment.customer_state === 1 ? (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      backgroundColor: "rgba(220, 145, 148, 0.65)",
                    }}
                  >
                    <img src={NotComeRed} alt="note-come-red" />
                    <span
                      style={{
                        color: "#D4242B",
                      }}
                    >
                      Gelmedi
                    </span>
                  </DetailDivOption>
                </Column>
              ) : (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAppointment({
                        ...appointment,
                        customer_state: 1,
                      });
                      updateAppointmentState(appointment.id, 1);
                    }}
                  >
                    <img src={NotComeRed} alt="note-come-red" />
                    <span
                      style={{
                        color: "#D4242B",
                      }}
                    >
                      Gelmedi
                    </span>
                  </DetailDivOption>
                </Column>
                // <FormControlLabel
                //   style={{
                //     width: "24%",
                //   }}
                //   value="male"
                //   control={<Radio value="1" />}
                //   label={

                //   }
                // />
              )}
              {appointment.customer_state === 2 ? (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      backgroundColor: "rgba(255, 226, 188, 0.65)",
                    }}
                  >
                    <img src={WaitingOrange} alt="waiting-orange" />
                    <span
                      style={{
                        color: "#FFB95E",
                      }}
                    >
                      Yeni
                    </span>
                  </DetailDivOption>
                </Column>
              ) : (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAppointment({
                        ...appointment,
                        customer_state: 2,
                      });
                      updateAppointmentState(appointment.id, 2);
                    }}
                  >
                    <img src={WaitingOrange} alt="waiting-orange" />
                    <span
                      style={{
                        color: "#FFB95E",
                      }}
                    >
                      Yeni
                    </span>
                  </DetailDivOption>
                </Column>
                // <FormControlLabel
                //   style={{
                //     width: "24%",
                //   }}
                //   value="other"
                //   control={<Radio value="2" />}
                //   label={

                //   }
                // />
              )}
              {appointment.customer_state === 3 ? (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      backgroundColor: "rgba(208, 233, 233, 0.65)",
                    }}
                  >
                    <img src={ApprovedBlue} alt="approved-blue" />
                    <span
                      style={{
                        color: "#54AEAE",
                      }}
                    >
                      Onaylı
                    </span>
                  </DetailDivOption>
                </Column>
              ) : (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAppointment({
                        ...appointment,
                        customer_state: 3,
                      });
                      updateAppointmentState(appointment.id, 3);
                    }}
                  >
                    <img src={ApprovedBlue} alt="approved-blue" />
                    <span
                      style={{
                        color: "#54AEAE",
                      }}
                    >
                      Onaylı
                    </span>
                  </DetailDivOption>
                </Column>
                // <FormControlLabel
                //   style={{
                //     width: "24%",
                //   }}
                //   value="other"
                //   control={<Radio value="3" />}
                //   label={

                //   }
                // />
              )}
              {appointment.customer_state === 4 ? (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      backgroundColor: "rgba(198, 163, 233, 0.65)",
                    }}
                  >
                    <img
                      style={{
                        transform: "scaleX(-1)",
                      }}
                      src={ProcessPurple}
                      alt="process-purple"
                    />
                    <span
                      style={{
                        color: "#7A45AE",
                      }}
                    >
                      İşlemde
                    </span>
                  </DetailDivOption>
                </Column>
              ) : (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAppointment({
                        ...appointment,
                        customer_state: 4,
                      });
                      updateAppointmentState(appointment.id, 4);
                    }}
                  >
                    <img
                      style={{
                        transform: "scaleX(-1)",
                      }}
                      src={ProcessPurple}
                      alt="process-purple"
                    />
                    <span
                      style={{
                        color: "#7A45AE",
                      }}
                    >
                      İşlemde
                    </span>
                  </DetailDivOption>
                </Column>
                // <FormControlLabel
                //   style={{
                //     width: "24%",
                //   }}
                //   value="other"
                //   control={<Radio value="4" />}
                //   label={

                //   }
                // />
              )}
            </DivStatus>
            {/* </RadioGroup> */}

            {/* <Select
            label={"Randevu Durumu"}
            items={[
              { label: "Geldi", value: 0 },
              { label: "Gelmedi", value: 1 },
              { label: "Bekleniyor", value: 2 },
            ]}
            selected={`${_appointment.customer_state}`}
            labelKey="label"
            valueKey="value"
            handler={(value) => {
              setState({
                _appointment: {
                  ..._appointment,
                  customer_state: parseInt(value),
                },
              });
              changeAppointmentStatus(
                _appointment.id,
                parseInt(value)
              );
            }}
          /> */}
          </>
        )}
      </AlertDialog>
      {/* Transfer Appointment Dialog */}
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        maxWidth="sm"
        fullWidth={true}
        title={context.t(`appointments.updateAppointment.title`)}
        open={open_update_appointment_dialog}
        closeHandler={() => setOpenUpdateAppointmentDialog(false)}
        buttons={[
          {
            title: context.t(
              `appointments.updateAppointment.confirmButtonTitle`
            ),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            timer: 5,
            onClick: async () => {
              await transferAppointment();
            },
          },
          {
            title: context.t(
              `appointments.updateAppointment.discardButtonTitle`
            ),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          <b>{context.t(`appointments.updateAppointment.alertBoldText`)}</b>
          <br />
          {context.t(`appointments.updateAppointment.alertText`)} <br />
          {insertData.selected_service &&
            `${context.t(`appointments.updateAppointment.service`)}: ${insertData.selected_service.name
            }`}
          <br />
          {insertData.selected_staff &&
            `${context.t(`appointments.updateAppointment.staff`)}: ${insertData.selected_staff.full_name
            }`}
          <br />
          {insertData.selected_date &&
            `${context.t(`appointments.updateAppointment.time`)}: ${moment(
              insertData.selected_date
            ).format("DD/MM/YYYY")} ${" "} ${insertData.selected_time}`}
          <br />
          {insertData.selected_customer &&
            `${context.t(`appointments.updateAppointment.customer`)}: ${insertData.selected_customer.full_name
            }`}
        </Alert>
      </AlertDialog>
      {/* İnsert Appointment Dialog */}-
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        maxWidth="md"
        fullWidth={true}
        open={create_appointment_modal}
        closeHandler={() => setCreateAppointmentModal(false)}
        className="bringBack"
        buttonDisabledTimeout
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h2 className="MuiTypography-root MuiTypography-h6">
                {context.t(`appointments.create.title`)}
              </h2>
              <h5
                style={{
                  color: "#4293ed",
                }}
              >
                {insertData.selected_time
                  ? moment(insertData.selected_date)
                    .set("hours", insertData.selected_time.split(":")[0])
                    .set("minutes", insertData.selected_time.split(":")[1])
                    .format("DD MMMM dddd YYYY - HH:mm")
                  : null}
              </h5>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Button
                  title={context.t(`appointments.create.groupAppointment`)}
                  className="bg-purple-opacity"
                  textColor="purple"
                  fullWidth
                  onClick={() => {
                    setCreateAppointmentModal(false);
                    setCreateAppointmentModalCollective(true);
                  }}
                />
              </div>
              <div>
                <Button
                  title={context.t(`appointments.create.clockOff`)}
                  className="bg-grey-opacity ml-1"
                  textColor="grey"
                  fullWidth
                  onClick={() => {
                    setCreateAppointmentModal(false);
                    setCreateAppointmentModalClock(true);
                  }}
                />
              </div>
              <div>
                <Button
                  icon="add"
                  title={context.t(
                    `appointments.create.createNewCustomerButtonTitle`
                  )}
                  backgroundColor="primary-opacity"
                  textColor="primary"
                  className="ml-2"
                  fullWidth
                  onClick={() => {
                    setCreateAppointmentModal(false);
                    setCustomerAddModal(true);
                  }}
                />
              </div>
            </div>
          </div>
        }
        buttons={[
          {
            title: context.t(`appointments.create.completeButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            disabledHandleClose: true,
            onClick: () => {
              (insertData.selected_service || insertData.selected_package) &&
                insertData.selected_customer
                ? insertAppointment()
                : handleOpen(
                  context.t(`appointments.create.onWarningToast`),
                  "warning"
                );
            },
          },
          {
            title: context.t(`appointments.create.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
            backgroundColor: "grey-opacity",
          },
        ]}
      >
        <Grid className="no-gutters-row">
          <Column className="xs-12 sm-12 md-12 lg-6 xl-6">
            <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
              <Select
                label={context.t(`appointments.create.selectedStaffInputLabel`)}
                items={staff_week}
                labelKey="full_name"
                valueKey="id"
                selected={
                  insertData.selected_staff !== null
                    ? `${insertData.selected_staff.id}`
                    : ""
                }
                returnFullObject
                handler={(selected_staff) =>
                  setInsertData({
                    ...insertData,
                    selected_staff,
                    selected_service: null,
                    selected_package: null,
                    // services_by_staff: selected_staff.services?.map(
                    //   (service) => service.service
                    // ),
                  })
                }
              />
            </Column>

            <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  gap: "1px",
                  alignItems: "center",
                }}
              >
                {context.t(`appointments.create.selecting_service`)}
                <Switch
                  checked={serviceOrPackage === 2 ? true : false}
                  onChange={(e) => {
                    setServiceOrPackage(
                      (prev) => (prev = e.target.checked ? 2 : 1)
                    );
                  }}
                  color="default"
                  inputProps={{
                    "aria-label": "checkbox with default color",
                  }}
                />
                {context.t(`appointments.create.selecting_package`)}
              </div>
              {serviceOrPackage === 1 && (
                <AutocompleteInput
                  label={context.t(
                    `appointments.create.selectedServiceInputLabel`
                  )}
                  labelKey="name"
                  valueKey="id"
                  selected={
                    insertData.selected_service !== null
                      ? insertData.selected_service
                      : ""
                  }
                  onRemove={() => {
                    setInsertData({
                      ...insertData,
                      selected_service: null,
                    });
                  }}
                  returnFullObject
                  selectedHandler={(selected_service) => {
                    setInsertData({
                      ...insertData,
                      selected_service,
                    });
                  }}
                  asyncDataService={async (keyword) =>
                    keyword.length > 0 ? searchServicesByKey(keyword) : []
                  }
                />
              )}
              {serviceOrPackage === 2 && (
                <AutocompleteInput
                  label={context.t(
                    `appointments.create.selectedPackageInputLabel`
                  )}
                  labelKey="name"
                  valueKey="id"
                  selected={
                    insertData.selected_package !== null
                      ? insertData.selected_package
                      : ""
                  }
                  onRemove={() => {
                    setInsertData({
                      ...insertData,
                      selected_package: null,
                    });
                  }}
                  returnFullObject
                  selectedHandler={(selected_package) => {
                    setInsertData({
                      ...insertData,
                      selected_package,
                    });
                  }}
                  asyncDataService={async (keyword) =>
                    keyword.length > 0 ? searchPackagesById(keyword) : []
                  }
                />
              )}
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
              <CustomerSearchInput
                label={context.t(
                  `appointments.create.selectedCustomerInputLabel`
                )}
                placeholder={context.t(
                  `appointments.create.selectedCustomerInputPlaceholder`
                )}
                labelKey="full_name"
                valueKey="id"
                selected={
                  insertData.selected_customer !== null
                    ? insertData.selected_customer
                    : ""
                }
                returnFullObject
                withPhone
                selectedHandler={(selected_customer) => {
                  setInsertData({
                    ...insertData,
                    selected_customer,
                    send_sms: selected_customer.send_sms,
                    selected_description: selected_customer.description,
                  });
                }}
              />
              {/* <AutocompleteInput
                label={context.t(
                  `appointments.create.selectedCustomerInputLabel`
                )}
                placeholder={context.t(
                  `appointments.create.selectedCustomerInputPlaceholder`
                )}
                labelKey="full_name"
                valueKey="id"
                selected={
                  insertData.selected_customer !== null
                    ? insertData.selected_customer
                    : ""
                }
                returnFullObject
                selectedHandler={(selected_customer) => {
                  setInsertData({
                    ...insertData,
                    selected_customer,
                    send_sms: selected_customer.send_sms,
                    selected_description: selected_customer.description,
                  });
                }}
                asyncDataService={async (keyword) =>
                  keyword.length > 2 ? searchCustomerByKey(keyword) : []
                }
              /> */}
            </Column>
            <Column className="xs-12 sm-12 md-12">
              <Input
                label={context.t(`appointments.create.noteInputLabel`)}
                onChange={(e) =>
                  setInsertData({
                    ...insertData,
                    note: e.target.value,
                  })
                }
              />
            </Column>
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-6 xl-6">
            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-12 flex">
                <TimeSelect
                  label={context.t(`['settings/hours'].startInputLabelHour`)}
                  type="hour"
                  value={
                    insertData.selected_time !== null
                      ? insertData.selected_time.split(":")[0]
                      : ""
                  }
                  fullWidth={true}
                  size="medium"
                  companyShiftTime={true}
                  startHour={context.state.company_shift.start_hour}
                  endHour={context.state.company_shift.end_hour}
                  // minuteStep={5}
                  handler={async (value) => {
                    await setInsertData({
                      ...insertData,
                      selected_time:
                        insertData.selected_time !== null
                          ? `${value}:${insertData.selected_time.split(":")[1]}`
                          : `${value}:00`,
                    });
                  }}
                />
                <TimeSelect
                  label={context.t(`['settings/hours'].startInputLabelMinute`)}
                  type="minute"
                  value={
                    insertData.selected_time !== null
                      ? insertData.selected_time.split(":")[1]
                      : ""
                  }
                  selectedHour={insertData.selected_time}
                  lastHour={insertData.hours[insertData.hours.length - 1]}
                  fullWidth={true}
                  size="medium"
                  handler={async (time) => {
                    await setInsertData({
                      ...insertData,
                      selected_time:
                        insertData.selected_time !== null
                          ? `${insertData.selected_time.split(":")[0]}:${time}`
                          : null,
                    });
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-12 mt-2">
                <Input
                  disabled
                  label={context.t(`appointments.create.description`)}
                  multiline
                  rows={5}
                  value={
                    insertData.selected_customer &&
                      insertData.selected_description != null
                      ? insertData.selected_description
                      : context.t(`appointments.create.defaultValue`)
                  }
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 mt-1">
                <CSwitch
                  label={context.t(`appointments.create.sendSmsInputLabel`)}
                  checked={send_sms}
                  color="primary"
                  disabled={
                    insertData.selected_customer &&
                    insertData.selected_customer.send_sms === false
                  }
                  onChange={(checked) => {
                    setSendSMS(checked.target.checked);
                  }}
                />
              </Column>
            </Grid>
          </Column>
        </Grid>
      </AlertDialog>
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        isWarningDialogOpen={dialogModal.isOpen}
        maxWidth="sm"
        fullWidth={true}
        className="bringBack"
        buttonDisabledTimeout
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h2 className="MuiTypography-root MuiTypography-h6">
                {context.t(`appointments.create.clockOff`)}
              </h2>
            </div>
            <div>
              {turn_off_all_day === false ? (
                <Button
                  title={"Tüm Günü Kapat"}
                  className="bg-blue-opacity"
                  fullWidth
                  onClick={async () => {
                    setTurnOffAllDay(true);
                  }}
                />
              ) : (
                <Button
                  title={"İptal"}
                  className="bg-red-opacity"
                  textColor="red"
                  fullWidth
                  onClick={async () => {
                    setTurnOffAllDay(false);
                  }}
                />
              )}
            </div>
            <div>
              {repetition_frequency_modal === false ? (
                <Button
                  title={"Tekrarlayan"}
                  className="bg-blue-opacity"
                  fullWidth
                  onClick={async () => {
                    setRepetitionFrequencyModal(true);
                  }}
                />
              ) : (
                <Button
                  title={"İptal"}
                  className="bg-red-opacity"
                  textColor="red"
                  fullWidth
                  onClick={async () => {
                    setRepetitionFrequencyModal(false);
                  }}
                />
              )}
            </div>
          </div>
        }
        open={create_appointment_modal_clock}
        closeHandler={() => setCreateAppointmentModalClock(false)}
        buttons={[
          {
            title: context.t(`appointments.create.completeButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            disabledHandleClose: true,
            onClick: () => {
              insertData.selected_staff.name
                ? closeAppointmentClockTime()
                : toast.warning("Personel Seçimi Yapınız.");
            },
          },
          {
            title: context.t(`appointments.create.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
            onClick: () => {
              setCreateAppointmentModalClock(false);
            },
          },
        ]}
      >
        <Grid className="no-gutters-row">
          <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
            <Select
              label={context.t(`appointments.create.selectedStaffInputLabel`)}
              items={staff_week}
              labelKey="full_name"
              valueKey="id"
              selected={
                insertData.selected_staff !== null
                  ? `${insertData.selected_staff.id}`
                  : ""
              }
              returnFullObject
              handler={(selected_staff) =>
                setInsertData({
                  ...insertData,
                  selected_staff,
                })
              }
            />
          </Column>
        </Grid>
        {turn_off_all_day === false ? (
          <>
            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-12 flex">
                <TimeSelect
                  label={context.t(`['settings/hours'].startInputLabelHour`)}
                  type="hour"
                  value={
                    insertData.selected_time_clock_off_start !== null
                      ? insertData.selected_time_clock_off_start.split(":")[0]
                      : ""
                  }
                  fullWidth={true}
                  size="medium"
                  companyShiftTime={true}
                  startHour={context.state.company_shift.start_hour}
                  endHour={context.state.company_shift.end_hour}
                  // minuteStep={5}
                  handler={(time) => {
                    // After 7 PM restrict Minute selection to 30 max
                    setInsertData({
                      ...insertData,
                      selected_time_clock_off_start: moment(
                        insertData.selected_date
                      )
                        .set("hours", time)
                        .set(
                          "minutes",
                          insertData.selected_time_clock_off_start.split(":")[1]
                        )
                        .format("HH:mm"),
                    });
                  }}
                />
                <TimeSelect
                  label={context.t(`['settings/hours'].startInputLabelMinute`)}
                  type="minute"
                  value={
                    insertData.selected_time_clock_off_start !== null
                      ? insertData.selected_time_clock_off_start.split(":")[1]
                      : ""
                  }
                  selectedHour={insertData.selected_time_clock_off_start}
                  lastHour={insertData.hours[insertData.hours.length - 1]}
                  fullWidth={true}
                  size="medium"
                  handler={(time) => {
                    setInsertData({
                      ...insertData,
                      selected_time_clock_off_start: moment(
                        insertData.selected_date
                      )
                        .set(
                          "hours",
                          insertData.selected_time_clock_off_start.split(":")[0]
                        )
                        .set("minutes", time)
                        .format("HH:mm"),
                    });
                  }}
                />
              </Column>
            </Grid>
            <Grid className="no-gutters-row mt-2">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-12 flex">
                <TimeSelect
                  label={context.t(`['settings/hours'].endInputLabelHour`)}
                  type="hour"
                  value={
                    insertData.selected_time_clock_off_end !== null
                      ? insertData.selected_time_clock_off_end.split(":")[0]
                      : ""
                  }
                  fullWidth={true}
                  size="medium"
                  companyShiftTime={true}
                  startHour={context.state.company_shift.start_hour}
                  endHour={context.state.company_shift.end_hour}
                  // minuteStep={5}
                  handler={(time) => {
                    // After 7 PM restrict Minute selection to 30 max
                    setInsertData({
                      ...insertData,
                      selected_time_clock_off_end: moment(
                        insertData.selected_date
                      )
                        .set("hours", time)
                        .set(
                          "minutes",
                          insertData.selected_time_clock_off_end.split(":")[1]
                        )
                        .format("HH:mm"),
                    });
                  }}
                />
                <TimeSelect
                  label={context.t(`['settings/hours'].endInputLabelMinute`)}
                  type="minute"
                  value={
                    insertData.selected_time_clock_off_end !== null
                      ? insertData.selected_time_clock_off_end.split(":")[1]
                      : ""
                  }
                  selectedHour={insertData.selected_time_clock_off_end}
                  lastHour={insertData.hours[insertData.hours.length - 1]}
                  fullWidth={true}
                  size="medium"
                  handler={(time) => {
                    setInsertData({
                      ...insertData,
                      selected_time_clock_off_end: moment(
                        insertData.selected_date
                      )
                        .set(
                          "hours",
                          insertData.selected_time_clock_off_end.split(":")[0]
                        )
                        .set("minutes", time)
                        .format("HH:mm"),
                    });
                  }}
                />
              </Column>
            </Grid>
          </>
        ) : (
          <></>
        )}
        <Grid className="no-gutters-row mt-2">
          <Column className="xs-12 sm-12 md-4 mb-2 mt-2">
            <CSwitch
              label={"Online Randevu"}
              checked={online_appointment}
              color="primary"
              onChange={(online_appointment) => {
                setOnlineAppointment(online_appointment.target.checked);
              }}
            />
          </Column>
          <Column className="xs-12 sm-12 md-8">
            <Input
              label={context.t(`appointments.create.noteInputLabel`)}
              multiline
              rows={1}
              onChange={(e) =>
                setInsertData({
                  ...insertData,
                  note: e.target.value,
                })
              }
            />
          </Column>
        </Grid>
        {repetition_frequency_modal ? (
          <Grid className="no-gutters-row">
            <Column className="xs-12 sm-12 md-6">
              <Select
                label={"Tekrar Sıklığı"}
                items={repetition}
                labelKey="name"
                valueKey="id"
                selected={
                  selected_repetition !== null
                    ? `${selected_repetition.id}`
                    : ""
                }
                returnFullObject
                handler={async (selected_repetition) => {
                  setSelectedRepetition(selected_repetition);
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-6">
              <Input
                label={"Tekrar Sayısı"}
                type="number"
                onChange={(e) => setRepetitionCount(e.target.value)}
              />
            </Column>
          </Grid>
        ) : (
          <></>
        )}
      </AlertDialog>
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        maxWidth="xs"
        fullWidth={true}
        className="bringBack"
        buttonDisabledTimeout
        open={openAppointmentPackage}
      >
        <Alert severity="warning">
          <p style={{ fontSize: "14px" }}>
            Adisyon açabilmek için paket içerisindeki hizmetlerin seans
            aralığını belirlemeniz gerekmektedir.
          </p>
        </Alert>
        {appointment?.packet?.packet_details.map((m, i) => (
          <Grid
            style={{
              marginTop: "15px",
              marginBottom: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Column className="sx-12 sm-12 md-12 lg-7">
              <p style={{ fontSize: "10px" }}>Hizmet Adı</p>

              <h4>{m?.service?.name}</h4>
            </Column>
            <Column className="sx-12 sm-12 md-12 lg-5">
              <TextField
                select
                fullWidth
                label="Seans Aralığı (Gün)"
                value={payloadObj[i]?.time}
                onChange={(e) => {
                  const updatedPayload = [...payloadObj];
                  updatedPayload[i] = {
                    ...updatedPayload[i],
                    time: Number(e.target.value),
                  };
                  setPayloadObj(updatedPayload);
                }}
              >
                {Array.from({ length: 50 }, (_, index) => index + 1).map(
                  (number) => (
                    <MenuItem key={number} value={number}>
                      {number}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Column>
          </Grid>
        ))}

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Button
            title="Onayla"
            textColor="primary"
            backgroundColor="primary-opacity"
            fullWidth
            onClick={() => {
              context.state.company_license.accounting === 1 ||
                context.state.company_license.accounting === 3
                ? appointment.packet !== null &&
                openReceiptByAppointment(appointment.id)
                : toast.warning(
                  "Bu özelliği kullanabilmek için Premium paket satın almalısınız."
                );
            }}
          />

          <Button
            onClick={() => openAppointmentPackage(false)}
            fullWidth
            backgroundColor="red-opacity"
            textColor="red"
            title="Vazgeç"
          />
        </div>
      </AlertDialog>
      <CustomerAddDialog
        open={customer_add_modal}
        createdCustomerHandler={async (data) => {
          setInsertData({
            ...insertData,
            selected_customer: {
              ...data,
              full_name: `${data.name} ${data.surname}`,
            },
          });
          setCreateAppointmentModal(true);
        }}
        closeHandler={() => setCustomerAddModal(false)}
      />
      {/* Toplu Randevu Oluşturma */}
      {create_appointment_modal_collective === true ? (
        <AlertDialog
          style={{ zIndex: "12 !important" }}
          isWarningDialogOpen={dialogModal.isOpen}
          maxWidth="md"
          fullWidth={true}
          className="bringBack"
          buttonDisabledTimeout
          title={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h2 className="MuiTypography-root MuiTypography-h6">
                  {context.t(`appointments.create.groupAppointment`)}
                </h2>
                <h5
                  style={{
                    color: "#4293ed",
                  }}
                >
                  {insertData.selected_time
                    ? moment(insertData.selected_date)
                      .set("hours", insertData.selected_time.split(":")[0])
                      .set("minutes", insertData.selected_time.split(":")[1])
                      .format("DD MMMM dddd YYYY - HH:mm")
                    : null}
                </h5>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Button
                    title={context.t(
                      `appointments.create.addEmployeeButtonTitle`
                    )}
                    className="bg-green-opacity"
                    textColor="green"
                    fullWidth
                    onClick={() => addServiceInPackageSale()}
                  />
                </div>
                <div>
                  <Button
                    icon="add"
                    title={context.t(
                      `appointments.create.createNewCustomerButtonTitle`
                    )}
                    backgroundColor="primary-opacity"
                    textColor="primary"
                    className="ml-1"
                    fullWidth
                    onClick={() => {
                      setCreateAppointmentModal(false);
                      setCustomerAddModal(true);
                    }}
                  />
                </div>
              </div>
            </div>
          }
          open={create_appointment_modal_collective}
          closeHandler={() => setCreateAppointmentModalCollective(false)}
          buttons={[
            {
              title: context.t(`appointments.create.completeButtonTitle`),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              disabledHandleClose: true,
              onClick: () => {
                insertData.selected_customer &&
                  Object.keys(new_appointment.services).filter(
                    (key) => new_appointment.services[key] !== undefined
                  ).length >= 2
                  ? bulkInsertAppointment()
                  : handleOpen(
                    context.t(`appointments.create.noDataText`),
                    "warning"
                  );
                setCreateAppointmentModalCollective(false);
              },
            },
            {
              title: context.t(`appointments.create.discardButtonTitle`),
              icon: "close",
              textColor: "grey",
              onClick: () => {
                setCreateAppointmentModalCollective(false);
              },
            },
          ]}
        >
          <>
            {Object.keys(new_appointment.services).filter(
              (key) => new_appointment.services[key] !== undefined
            ).length > 0 ? (
              Object.keys(new_appointment.services)
                .filter((key) => new_appointment.services[key] !== undefined)
                .map((item, index) => (
                  <Grid key={index} className="no-gutters-row">
                    <Column className="xs-12 sm-12 md-12 lg-12 xl-5">
                      <Select
                        label={context.t(
                          `['packages/sales/add'].staffInputLabel`
                        )}
                        items={staff_week}
                        labelKey="full_name"
                        valueKey="id"
                        selected={
                          new_appointment.services[item].staff_id ?? null
                        }
                        handler={async (staff_id) => {
                          const selected_staff = staff_week.filter(
                            (item) => item.id === parseInt(staff_id)
                          )[0];
                          fieldChanger("services", item, {
                            staff_id: parseInt(staff_id),
                          });
                          setInsertData({
                            ...insertData,
                            selected_staff,
                          });
                        }}
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                      <AutocompleteInput
                        label={context.t(
                          `appointments.create.selectedServiceInputLabel`
                        )}
                        labelKey="name"
                        valueKey="id"
                        selected={
                          insertData.selected_service !== null
                            ? insertData.selected_service
                            : ""
                        }
                        onRemove={() => {
                          setInsertData({
                            ...insertData,
                            selected_service: null,
                          });
                        }}
                        returnFullObject
                        selectedHandler={(service) => {
                          fieldChanger("services", item, {
                            service_id: parseInt(service.id),
                          });
                        }}
                        asyncDataService={async (keyword) =>
                          keyword.length > 0 ? searchServicesByKey(keyword) : []
                        }
                      />
                      {/* <Select
                        label={context.t(
                          `['packages/sales/add'].serviceInputLabel`
                        )}
                        items={insertData.services_by_staff}
                        labelKey="name"
                        valueKey="id"
                        selected={
                          new_appointment.services[item].service_id ?? null
                        }
                        returnFullObject
                        handler={(service) =>
                          fieldChanger("services", item, {
                            service_id: parseInt(service.id),
                          })
                        }
                      /> */}
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-12 xl-1">
                      <Button
                        icon="delete_forever"
                        iconSize={35}
                        backgroundColor="red-opacity"
                        textColor="red"
                        size="lg"
                        fullWidth
                        onClick={() => deleteServiceInPackageSale(item)}
                      />
                    </Column>
                  </Grid>
                ))
            ) : (
              <NoDataContainer>
                <img
                  src={require("../../../assets/images/manypixels/click_action.svg")}
                  alt="Malesef bulunamadı!"
                />
                <p>{context.t(`appointments.create.noDataText`)}</p>
              </NoDataContainer>
            )}
          </>

          <Grid className="no-gutters-row">
            <Column className="xs-12 sm-12 md-12 lg-6 xl-6">
              <CustomerSearchInput
                label={context.t(
                  `appointments.create.selectedCustomerInputLabel`
                )}
                placeholder={context.t(
                  `appointments.create.selectedCustomerInputPlaceholder`
                )}
                labelKey="full_name"
                valueKey="id"
                selected={
                  insertData.selected_customer !== null
                    ? insertData.selected_customer
                    : ""
                }
                withPhone
                returnFullObject
                selectedHandler={(selected_customer) => {
                  setSendSMS(selected_customer.send_sms);
                  setInsertData({
                    ...insertData,
                    selected_customer,
                    selected_description: selected_customer.description,
                  });
                }}
              />
              {/* <AutocompleteInput
                label={context.t(
                  `appointments.create.selectedCustomerInputLabel`
                )}
                placeholder={context.t(
                  `appointments.create.selectedCustomerInputPlaceholder`
                )}
                labelKey="full_name"
                valueKey="id"
                selected={
                  insertData.selected_customer !== null
                    ? insertData.selected_customer
                    : ""
                }
                returnFullObject
                selectedHandler={(selected_customer) => {
                  setSendSMS(selected_customer.send_sms);
                  setInsertData({
                    ...insertData,
                    selected_customer,
                    selected_description: selected_customer.description,
                  });
                }}
                asyncDataService={async (keyword) =>
                  keyword.length > 2 ? searchCustomerByKey(keyword) : []
                }
              /> */}
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-6 flex">
              <TimeSelect
                label={context.t(`['settings/hours'].startInputLabelHour`)}
                type="hour"
                value={
                  insertData.selected_time !== null
                    ? insertData.selected_time.split(":")[0]
                    : ""
                }
                fullWidth={true}
                size="medium"
                companyShiftTime={true}
                startHour={context.state.company_shift.start_hour}
                endHour={context.state.company_shift.end_hour}
                // minuteStep={5}
                handler={(time) => {
                  // After 7 PM restrict Minute selection to 30 max
                  setInsertData({
                    ...insertData,
                    selected_time: moment(insertData.selected_date)
                      .set("hours", time)
                      .set("minutes", insertData.selected_time.split(":")[1])
                      .format("HH:mm"),
                  });
                }}
              />
              <TimeSelect
                label={context.t(`['settings/hours'].startInputLabelMinute`)}
                type="minute"
                value={
                  insertData.selected_time !== null
                    ? insertData.selected_time.split(":")[1]
                    : ""
                }
                selectedHour={insertData.selected_time}
                lastHour={insertData.hours[insertData.hours.length - 1]}
                /*lastMinute={
                      state.selected_time
                        ? state.selected_time.split(":")[0] ===
                          state.hours[state.hours.length - 1].split(
                            ":"
                          )[0]
                          ? parseInt(
                              state.hours[state.hours.length - 1].split(
                                ":"
                              )[1]
                            )
                          : null
                        : null
                    }*/
                fullWidth={true}
                size="medium"
                handler={(time) => {
                  setInsertData({
                    ...insertData,
                    selected_time: moment(insertData.selected_date)
                      .set("hours", insertData.selected_time.split(":")[0])
                      .set("minutes", time)
                      .format("HH:mm"),
                  });
                }}
              />
            </Column>
            {/*<Column className="xs-6 sm-6 md-6 lg-6 xl-6">
              <Button
                icon="add"
                title={context.t(
                  `appointments.create.createNewCustomerButtonTitle`
                )}
                backgroundColor="primary-opacity"
                textColor="primary"
                size="lg"
                fullWidth
                onClick={() => {
                  setCreateAppointmentModal(false);
                  setCustomerAddModal(true);
                }}
              />
            </Column>
            <Column className="xs-6 sm-6 md-6 lg-6 xl-6">
              <Button
                icon="add"
                title={context.t(
                  `appointments.create.createNewWalkinCustomerButtonTitle`
                )}
                backgroundColor="secondary-opacity"
                textColor="secondary"
                size="lg"
                fullWidth
                onClick={() => insertRandomCustomer()}
              />
                </Column>*/}
          </Grid>
          <Grid className="no-gutters-row">
            <Column className="xs-12 sm-12 md-12 lg-6">
              <Input
                label={context.t(`appointments.create.noteInputLabel`)}
                onChange={(e) =>
                  setInsertData({
                    ...insertData,
                    note: e.target.value,
                  })
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-6">
              <Input
                disabled
                label={context.t(`appointments.create.description`)}
                value={
                  insertData.selected_customer &&
                    insertData.selected_description != null
                    ? insertData.selected_description
                    : context.t(`appointments.create.defaultValue`)
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 mt-1">
              <CSwitch
                label={context.t(`appointments.create.sendSmsInputLabel`)}
                checked={send_sms}
                color="primary"
                disabled={
                  insertData.selected_customer &&
                  insertData.selected_customer.send_sms === false
                }
                onChange={(checked) => {
                  setSendSMS(checked.target.checked);
                }}
              />
              {/* <Select
                    disabled={
                      state.selected_customer &&
                      state.selected_customer.send_sms === false
                    }
                    label={context.t(`appointments.create.sendSmsInputLabel`)}
                    items={[
                      {
                        label: context.t(`appointments.create.sendSmsTrue`),
                        value: true,
                      },
                      {
                        label: context.t(`appointments.create.sendSmsFalse`),
                        value: false,
                      },
                    ]}
                    selected={`${state.send_sms}`}
                    labelKey="label"
                    valueKey="value"
                    handler={(send_sms) =>
                      setState({
                        send_sms: send_sms === "true",
                      })
                    }
                  /> */}
            </Column>
          </Grid>
        </AlertDialog>
      ) : (
        <></>
      )}
      <CenterDialogModal
        open={dialogModal.isOpen}
        handleClose={handleClose}
        message={dialogModal.message}
        type={dialogModal.type}
      />
      {/* Toplu Randevu Oluşturma */}-
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        maxWidth="sm"
        fullWidth={true}
        title={context.t(`appointments.deleteConfirm.title`)}
        open={cancelAppointmentDialog}
        closeHandler={() => setCancelAppointmentDialog(false)}
        buttons={[
          {
            title: context.t(`appointments.deleteConfirm.confirmButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            timer: 5,
            onClick: () => {
              setCancelAppointmentArray((prevArray) => [
                ...prevArray,
                appointment.id,
              ]);
              if (!cancelAppointmentArray.includes(appointment.id)) {
                cancelAppointment(appointment.id);
              }
            },
          },
          {
            title: context.t(`appointments.deleteConfirm.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          <b>{context.t(`appointments.deleteConfirm.alertBoldText`)}</b>
          <br />
          {context.t(`appointments.deleteConfirm.alertText`)}
        </Alert>
      </AlertDialog>
    </>
  );
};

export const COLUMN_WIDTH_14 = 108;
export const COLUMN_WIDTH_13 = 117;
export const COLUMN_WIDTH_12 = 126;
export const COLUMN_WIDTH_11 = 138;
export const COLUMN_WIDTH_10 = 152;
export const COLUMN_WIDTH_9 = 169;
export const COLUMN_WIDTH_8 = 190;
export const COLUMN_WIDTH_7 = 218;
export const COLUMN_WIDTH_6 = 254;
export const COLUMN_WIDTH_5 = 305;
export const COLUMN_WIDTH_4 = 382;
export const COLUMN_WIDTH_3 = 510;
export const COLUMN_WIDTH_2 = 760;
export const COLUMN_WIDTH_1 = 1520;
export const COLUMN_WIDTH_WEEK = 218;
export const SMALL_COLUMN_WIDTH = 1000;
export const LARGE_COLUMN_WIDTH = 1150;
export const XLARGE_COLUMN_WIDTH = 1400;
export const DEFAULT_BACKGROUND_COLOR = "#f5f5f5";
export const DEFAULT_BACKGROUND_COLOR_DARK = "#000000";

const ArtboardContainer = styled.div`
  width: ${(props) => COLUMN_WIDTH_14 * props.staffCount + 5}px;
`;

const TableContainer = styled.div`
  width: max-content;
  overflow-x: visible;
  position: relative;
`;

const TableView = styled.table`
  table-layout: fixed;
  border-collapse: collapse;

  @media only screen and (max-width: 576px) {
    position: static !important;
  }
`;

const TableRow = styled.tr`
  width: 100%;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

const TableColumn = styled.td`
  width: ${(props) =>
    props.largerow
      ? LARGE_COLUMN_WIDTH
      : props.week
        ? COLUMN_WIDTH_WEEK
        : props.staffCount === 7
          ? COLUMN_WIDTH_7
          : props.staffCount === 6
            ? COLUMN_WIDTH_6
            : props.staffCount === 5
              ? COLUMN_WIDTH_5
              : props.staffCount === 4
                ? COLUMN_WIDTH_4
                : props.staffCount === 3
                  ? COLUMN_WIDTH_3
                  : props.staffCount === 2
                    ? COLUMN_WIDTH_2
                    : props.staffCount === 1
                      ? COLUMN_WIDTH_1
                      : COLUMN_WIDTH_14}px;
  height: 40px;
  padding: 0;
  line-height: 1.2;
  text-align: left;
  vertical-align: top;
  border-collapse: collapse;
  box-sizing: border-box;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
`;

const ColumnInner = styled.div`
  width: ${(props) =>
    props.largerow
      ? LARGE_COLUMN_WIDTH
      : props.week
        ? COLUMN_WIDTH_WEEK
        : props.staffCount === 7
          ? COLUMN_WIDTH_7
          : props.staffCount === 6
            ? COLUMN_WIDTH_6
            : props.staffCount === 5
              ? COLUMN_WIDTH_5
              : props.staffCount === 4
                ? COLUMN_WIDTH_4
                : props.staffCount === 3
                  ? COLUMN_WIDTH_3
                  : props.staffCount === 2
                    ? COLUMN_WIDTH_2
                    : props.staffCount === 1
                      ? COLUMN_WIDTH_1
                      : COLUMN_WIDTH_14}px;
  max-width: ${(props) =>
    props.largerow
      ? LARGE_COLUMN_WIDTH
      : props.week
        ? COLUMN_WIDTH_WEEK
        : props.staffCount === 7
          ? COLUMN_WIDTH_7
          : props.staffCount === 6
            ? COLUMN_WIDTH_6
            : props.staffCount === 5
              ? COLUMN_WIDTH_5
              : props.staffCount === 4
                ? COLUMN_WIDTH_4
                : props.staffCount === 3
                  ? COLUMN_WIDTH_3
                  : props.staffCount === 2
                    ? COLUMN_WIDTH_2
                    : props.staffCount === 1
                      ? COLUMN_WIDTH_1
                      : COLUMN_WIDTH_14}px;
  height: 100%;
  position: relative;

  @media only screen and (max-width: 576px) {
    width: ${(props) =>
    props.staffCount === 7
      ? COLUMN_WIDTH_7
      : props.staffCount === 6
        ? COLUMN_WIDTH_6
        : props.staffCount === 5
          ? COLUMN_WIDTH_5
          : props.staffCount === 4
            ? COLUMN_WIDTH_4
            : props.staffCount === 3
              ? COLUMN_WIDTH_3
              : props.staffCount === 2
                ? COLUMN_WIDTH_2
                : props.staffCount === 1
                  ? COLUMN_WIDTH_1
                  : COLUMN_WIDTH_14}px;
  }

  @media only screen and (min-width: 577px) {
    width: ${(props) =>
    props.largerow
      ? SMALL_COLUMN_WIDTH
      : props.week
        ? COLUMN_WIDTH_WEEK
        : props.staffCount === 7
          ? COLUMN_WIDTH_7
          : props.staffCount === 6
            ? COLUMN_WIDTH_6
            : props.staffCount === 5
              ? COLUMN_WIDTH_5
              : props.staffCount === 4
                ? COLUMN_WIDTH_4
                : props.staffCount === 3
                  ? COLUMN_WIDTH_3
                  : props.staffCount === 2
                    ? COLUMN_WIDTH_2
                    : props.staffCount === 1
                      ? COLUMN_WIDTH_1
                      : COLUMN_WIDTH_14}px;
  }

  @media only screen and (min-width: 1400px) {
    width: ${(props) =>
    props.largerow
      ? LARGE_COLUMN_WIDTH
      : props.week
        ? COLUMN_WIDTH_WEEK
        : props.staffCount === 7
          ? COLUMN_WIDTH_7
          : props.staffCount === 6
            ? COLUMN_WIDTH_6
            : props.staffCount === 5
              ? COLUMN_WIDTH_5
              : props.staffCount === 4
                ? COLUMN_WIDTH_4
                : props.staffCount === 3
                  ? COLUMN_WIDTH_3
                  : props.staffCount === 2
                    ? COLUMN_WIDTH_2
                    : props.staffCount === 1
                      ? COLUMN_WIDTH_1
                      : COLUMN_WIDTH_14}px;
  }

  @media only screen and (min-width: 1700px) {
    width: ${(props) =>
    props.largerow
      ? XLARGE_COLUMN_WIDTH
      : props.week
        ? COLUMN_WIDTH_WEEK
        : props.staffCount === 7
          ? COLUMN_WIDTH_7
          : props.staffCount === 6
            ? COLUMN_WIDTH_6
            : props.staffCount === 5
              ? COLUMN_WIDTH_5
              : props.staffCount === 4
                ? COLUMN_WIDTH_4
                : props.staffCount === 3
                  ? COLUMN_WIDTH_3
                  : props.staffCount === 2
                    ? COLUMN_WIDTH_2
                    : props.staffCount === 1
                      ? COLUMN_WIDTH_1
                      : COLUMN_WIDTH_14}px;
    max-width: ${(props) =>
    props.largerow
      ? XLARGE_COLUMN_WIDTH
      : props.week
        ? COLUMN_WIDTH_WEEK
        : props.staffCount === 7
          ? COLUMN_WIDTH_7
          : props.staffCount === 6
            ? COLUMN_WIDTH_6
            : props.staffCount === 5
              ? COLUMN_WIDTH_5
              : props.staffCount === 4
                ? COLUMN_WIDTH_4
                : props.staffCount === 3
                  ? COLUMN_WIDTH_3
                  : props.staffCount === 2
                    ? COLUMN_WIDTH_2
                    : props.staffCount === 1
                      ? COLUMN_WIDTH_1
                      : COLUMN_WIDTH_14}px;
  }

  button {
    width: 100%;
    height: 100%;
    cursor: grab !important;
  }

  .staff-link {
    color: black;
    text-decoration: none;
  }

  @media only screen and (max-width: 576px) {
    width: ${(props) =>
    props.staffCount === 7
      ? COLUMN_WIDTH_7
      : props.staffCount === 6
        ? COLUMN_WIDTH_6
        : props.staffCount === 5
          ? COLUMN_WIDTH_5
          : props.staffCount === 4
            ? COLUMN_WIDTH_4
            : props.staffCount === 3
              ? COLUMN_WIDTH_3
              : props.staffCount === 2
                ? COLUMN_WIDTH_2
                : props.staffCount === 1
                  ? COLUMN_WIDTH_1
                  : COLUMN_WIDTH_14}px;
  }

  @media only screen and (min-width: 577px) {
    width: ${(props) =>
    props.largerow
      ? SMALL_COLUMN_WIDTH
      : props.week
        ? COLUMN_WIDTH_WEEK
        : props.staffCount === 7
          ? COLUMN_WIDTH_7
          : props.staffCount === 6
            ? COLUMN_WIDTH_6
            : props.staffCount === 5
              ? COLUMN_WIDTH_5
              : props.staffCount === 4
                ? COLUMN_WIDTH_4
                : props.staffCount === 3
                  ? COLUMN_WIDTH_3
                  : props.staffCount === 2
                    ? COLUMN_WIDTH_2
                    : props.staffCount === 1
                      ? COLUMN_WIDTH_1
                      : COLUMN_WIDTH_14}px;
  }

  @media only screen and (min-width: 1400px) {
    width: ${(props) =>
    props.largerow
      ? LARGE_COLUMN_WIDTH
      : props.week
        ? COLUMN_WIDTH_WEEK
        : props.staffCount === 7
          ? COLUMN_WIDTH_7
          : props.staffCount === 6
            ? COLUMN_WIDTH_6
            : props.staffCount === 5
              ? COLUMN_WIDTH_5
              : props.staffCount === 4
                ? COLUMN_WIDTH_4
                : props.staffCount === 3
                  ? COLUMN_WIDTH_3
                  : props.staffCount === 2
                    ? COLUMN_WIDTH_2
                    : props.staffCount === 1
                      ? COLUMN_WIDTH_1
                      : COLUMN_WIDTH_14}px;
  }

  @media only screen and (min-width: 1700px) {
    width: ${(props) =>
    props.largerow
      ? XLARGE_COLUMN_WIDTH
      : props.week
        ? COLUMN_WIDTH_WEEK
        : props.staffCount === 7
          ? COLUMN_WIDTH_7
          : props.staffCount === 6
            ? COLUMN_WIDTH_6
            : props.staffCount === 5
              ? COLUMN_WIDTH_5
              : props.staffCount === 4
                ? COLUMN_WIDTH_4
                : props.staffCount === 3
                  ? COLUMN_WIDTH_3
                  : props.staffCount === 2
                    ? COLUMN_WIDTH_2
                    : props.staffCount === 1
                      ? COLUMN_WIDTH_1
                      : COLUMN_WIDTH_14}px;
  }
`;

const HourLabel = styled.div`
  position: relative;
  width: 45%;
  top: 33px;

  font-weight: 500;
  font-size: 12.4px;
  line-height: 1;
  background: #fff;

  &._past {
    color: #909090;
  }

  &._future {
    color: #151515;
  }
`;

const StaffHeading = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    background: #fafafa;
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
    border-width: 3px;
    border-style: solid;
    border-color: ${(props) =>
    props.background ? props.background : DEFAULT_BACKGROUND_COLOR};
  }

  h3 {
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    padding: 0;
    margin: 0;
    color: ${DEFAULT_BACKGROUND_COLOR_DARK};
  }

  .appointment-count-bagde {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    left: 8px;
    background: #FFF6ED;
    color: #6689EC;
    border-radius: 50%
  }
}
`;

const AppointmentCard = styled.div`
  width: ${(props) =>
    props.largerow
      ? LARGE_COLUMN_WIDTH
      : props.staffCount === 7
        ? COLUMN_WIDTH_7
        : props.staffCount === 6
          ? COLUMN_WIDTH_6
          : props.staffCount === 5
            ? COLUMN_WIDTH_5
            : props.staffCount === 4
              ? COLUMN_WIDTH_4
              : props.staffCount === 3
                ? COLUMN_WIDTH_3
                : props.staffCount === 2
                  ? COLUMN_WIDTH_2
                  : props.staffCount === 1
                    ? COLUMN_WIDTH_1
                    : COLUMN_WIDTH_14}px;

  overflow: hidden;
  box-sizing: border-box;
  position: absolute;
  z-index: 100;
  cursor: pointer;

  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  border-bottom: 0;
  border: 1px solid #c0c0c0;
  // border: 1.6px solid rgba(0, 0, 0, 0.04);
  // border-radius: 5px;

  transition: transform 0.5s, margin 0.5s, width 0.5s;

  &:hover {
    transform: scale(1.01);
  }

  @media only screen and (max-width: 576px) {
    width: ${(props) =>
    props.staffCount === 7
      ? COLUMN_WIDTH_7
      : props.staffCount === 6
        ? COLUMN_WIDTH_6
        : props.staffCount === 5
          ? COLUMN_WIDTH_5
          : props.staffCount === 4
            ? COLUMN_WIDTH_4
            : props.staffCount === 3
              ? COLUMN_WIDTH_3
              : props.staffCount === 2
                ? COLUMN_WIDTH_2
                : props.staffCount === 1
                  ? COLUMN_WIDTH_1
                  : COLUMN_WIDTH_14}px;
  }

  @media only screen and (min-width: 577px) {
    width: ${(props) =>
    props.largerow
      ? SMALL_COLUMN_WIDTH
      : props.staffCount === 7
        ? COLUMN_WIDTH_7
        : props.staffCount === 6
          ? COLUMN_WIDTH_6
          : props.staffCount === 5
            ? COLUMN_WIDTH_5
            : props.staffCount === 4
              ? COLUMN_WIDTH_4
              : props.staffCount === 3
                ? COLUMN_WIDTH_3
                : props.staffCount === 2
                  ? COLUMN_WIDTH_2
                  : props.staffCount === 1
                    ? COLUMN_WIDTH_1
                    : COLUMN_WIDTH_14}px;
  }

  @media only screen and (min-width: 1400px) {
    width: ${(props) =>
    props.largerow
      ? LARGE_COLUMN_WIDTH
      : props.staffCount === 7
        ? COLUMN_WIDTH_7
        : props.staffCount === 6
          ? COLUMN_WIDTH_6
          : props.staffCount === 5
            ? COLUMN_WIDTH_5
            : props.staffCount === 4
              ? COLUMN_WIDTH_4
              : props.staffCount === 3
                ? COLUMN_WIDTH_3
                : props.staffCount === 2
                  ? COLUMN_WIDTH_2
                  : props.staffCount === 1
                    ? COLUMN_WIDTH_1
                    : COLUMN_WIDTH_14}px;
  }

  @media only screen and (min-width: 1700px) {
    width: ${(props) =>
    props.largerow
      ? XLARGE_COLUMN_WIDTH
      : props.staffCount === 7
        ? COLUMN_WIDTH_7
        : props.staffCount === 6
          ? COLUMN_WIDTH_6
          : props.staffCount === 5
            ? COLUMN_WIDTH_5
            : props.staffCount === 4
              ? COLUMN_WIDTH_4
              : props.staffCount === 3
                ? COLUMN_WIDTH_3
                : props.staffCount === 2
                  ? COLUMN_WIDTH_2
                  : props.staffCount === 1
                    ? COLUMN_WIDTH_1
                    : COLUMN_WIDTH_14}px;
  }
`;

const AppointmentCardInner = styled.div`
  // padding: 6px 4px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  // background: rgb(255, 255, 255);
  // background: linear-gradient(
  //   -180deg,
  //   rgba(255, 255, 255, 0.5) 0%,
  //   rgba(255, 255, 255, 0) 100%
  // );
`;

const AppointmentRow = styled.div`
  width: 100%;
  display: flex;
  // margin-bottom: 6px;

  font-weight: 500;

  svg {
    height: 16px;
    margin-right: 4px;
  }
  span {
    flex: 1;
    font-size: 12px;
    line-height: 16px;
  }
`;

const TooltipCardInner = styled.div`
  width: 100%;
  height: auto;
  color: black;
  box-sizing: border-box;
`;

const TooltipCardRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;

  font-weight: 500;

  svg {
    height: 16px;
    margin-right: 4px;
  }
  span {
    flex: 1;
    font-size: 12px;
    line-height: 16px;
  }
`;

const DateNowIndicator = styled.div`
  width: 100%;
  height: 2px;
  background: #f7c0ba;
  position: absolute;
  z-index: 300;
`;

const DetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  margin-bottom: 16px;
`;

const DetailIcon = styled.div`
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  i {
    font-size: 21px;
  }
`;

const DetailInner = styled.div`
  flex: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;

  h4 {
    color: #808080;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 4px;
  }
  s span {
    color: #202020;
    font-size: 14px;
  }
`;

const ColorMean = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;

  &:nth-last-child(1) {
    margin: 0;
  }

  div.color {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    margin-right: 8px;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        -180deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  span {
    flex: 1;
    font-size: 13px;
    line-height: 1.2;
    font-weight: 500;
    color: #404040;
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 120px;
    object-fit: contain;
  }
  p {
    color: #505050;
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;
const AlignContainer = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const DivStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DetailDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 120px;
  height: 42px;
  border-radius: 5px;
`;

const DetailDivOption = styled.div`
  width: 170px !important;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: #ffffff;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.149));
  border-radius: 5px;
`;

const DetailCard = (props) => (
  <DetailBox>
    <DetailIcon className={`bg-${props.color}-opacity fc-${props.color}`}>
      <i className="material-icons">{props.icon}</i>
    </DetailIcon>
    <DetailInner>
      <h4>{props.label}</h4>
      <span>{props.value}</span>
    </DetailInner>
  </DetailBox>
);

export default React.memo(WeekAppointment);
