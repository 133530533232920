import React, { useEffect, useState, useContext, useCallback, memo, useRef } from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import AuthContainer from "../../../components/AuthContainer";
import Axios from "axios";
import Select from "../../../theme/CustomMUI/Select";
import moment from "moment";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import Button from "../../../theme/Button";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import CalculateMessageCharCount from "../../../functions/CalculateMessageCharCount";
import Input from "../../../theme/CustomMUI/Input";
import CalculateMessageCount from "../../../functions/CalculateMessageCount";
import SMSReview from "../../../components/Dialogs/SMSReview";
import { toast } from "react-toastify";
import { phoneNumberDisplay } from "../../../functions/PhoneNumberDisplay";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import Loader from "../../../assets/images/loader.gif";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { DateRange, Search } from "@material-ui/icons";
const MessageDialogContent = memo(({ initialSms, onSmsChange, templates, context }) => {
  const [localSms, setLocalSms] = useState(initialSms);
  const handleInputChange = useCallback((e) => {
    const newValue = e.target.value;
    setLocalSms(prev => ({
      ...prev,
      message: newValue
    }));
    onSmsChange({
      ...localSms,
      message: newValue
    });
  }, [localSms, onSmsChange]);
  return (
    <>
      <Select
        label={context.t(`sms.send.template`)}
        items={templates}
        selected={localSms.selectedTemplate?.name ?? ""}
        labelKey="name"
        valueKey="name"
        returnFullObject
        handler={(template) => {
          const newSms = {
            ...localSms,
            selectedTemplate: template,
            message: template.content,
          };
          setLocalSms(newSms);
          onSmsChange(newSms);
        }}
      />
      <Input
        label={context.t(`sms.send.messageInputLabel`)}
        multiline={true}
        rows={10}
        value={localSms.message}
        onChange={handleInputChange}
        helperText={CalculateMessageCharCount(localSms.message, "1")}
      />
    </>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.initialSms.message === nextProps.initialSms.message &&
    prevProps.initialSms.selectedTemplate?.name === nextProps.initialSms.selectedTemplate?.name
  );
});
const MessageDialog = memo(({
  open,
  sms,
  onClose,
  onSmsChange,
  onSend,
  onDiscard,
  context
}) => {
  return (
    <AlertDialog
      open={open}
      title={context.t(`['customers/risky'].sendSMSButtonTitle`)}
      maxWidth="md"
      fullWidth={true}
      buttons={[
        {
          disabled: sms.message.length === 0 || !CalculateMessageCount(sms.message),
          icon: "send",
          title: context.t(`sms.send.buttonTitle`),
          backgroundColor: "primary",
          textColor: "white",
          fullWidth: true,
          onClick: onSend,
        },
        {
          icon: "close",
          title: context.t(`sms.send.discardButtonTitle`),
          backgroundColor: "red",
          textColor: "white",
          fullWidth: true,
          onClick: onDiscard,
        },
      ]}
      closeHandler={onClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <MessageDialogContent
        initialSms={sms}
        templates={sms.templates}
        context={context}
        onSmsChange={onSmsChange}
      />
    </AlertDialog>
  );
}, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open &&
    prevProps.sms.message === nextProps.sms.message &&
    prevProps.sms.selectedTemplate?.name === nextProps.sms.selectedTemplate?.name;
});

const CustomersRisky = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);

  const [offset, setOffset] = useState(30);

  const [pagination, setPagination] = useState({
    total_page: 1,
    page: 1,
    onChange: (_page) => { setPagination({ ...pagination, page: _page }) }
  });

  const [data, setData] = useState([]);

  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [selectedFilterPicker, setSelectedFilterPicker] = useState({
    id: 1,
    label: "Bugün",
    offset: 1,
  });

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedAllCustomer, setSelectedAllCustomer] = useState(false);
  const [exceptedCustomers, setExceptedCustomers] = useState([]);

  const [sms, setSms] = useState({
    message: "",
    templates: [],
    selectedTemplate: null,
  });

  const [openSmsDialog, setOpenSmsDialog] = useState(false);

  const [openSmsReviewDialog, setOpenSmsReviewDialog] = useState(false);
  const [totalNumber, setTotalNumber] = useState(null);
  const [totalSmsCredit, setTotalSmsCredit] = useState(null);

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [readyToUseFilter, setReadyToUseFilter] = useState(null);
  const [reportsDate, setReportsDate] = useState({
    between: {
      start: moment().local().format("DD/MM/YYYY"),
      end: moment().local().format("DD/MM/YYYY"),
    },
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const isFirstRender = useRef(true);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const getRiskyCustomers = () => {
    setLoaded(false);
    Axios.get(
      `${context.api_endpoint}/company/customers/risky`,
      selectedFilterPicker === null
        ? {
          params: {
            page: pagination.page,
            start: moment(start).format("YYYY-MM-DD"),
            end: moment(end).format("YYYY-MM-DD"),
            isbetween: true,
          },
        }
        : {
          params: {
            page: pagination.page,
            offset: selectedFilterPicker.offset,
            isbetween: false,
          },
        }
    )
      .then(({ data }) => {
        setLoaded(true);
        setData(data?.data?.records ? [
          ...data.data.records.map((item) => {
            return {
              ...item,
              phone:
                context.state.user.permission === 2 &&
                  context.state.limited_permission.customer_info === 0
                  ? phoneNumberDisplay(item.phone)
                  : `0${item.phone.substring(0, 3)} ${item.phone.substring(
                    3,
                    6
                  )} ${item.phone.substring(6, 10)}`,
              extra_detail: {
                ...item.extra_detail,
                last_transaction_date: item.extra_detail.last_transaction_date
                  ? moment(item.extra_detail.last_transaction_date)
                    .startOf()
                    .fromNow()
                  : null,
                sum_income: item.extra_detail.sum_income
                  ? item.extra_detail.sum_income.toFixed(2)
                  : "0.00",
                total_debt: item.extra_detail.total_debt
                  ? item.extra_detail.total_debt.toFixed(2)
                  : "0.00",
              },
            };
          }),
        ] : []);
        setPagination({
          ...pagination,
          total_page: data.data.records.length,
          //page: data.data.page
        })
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else if (e.response.status === 400) {
          setData([])
        } else {
          toast.warning(e.response.data.message);
        }
        setLoaded(true);
        // if (e.response.status === 401) {
        //   toast.error(e.response.data.message);
        // } else {
        //   toast.warning(e.response.data.message);
        // }
      });
  };

  const handleList = () => {
    setInitialLoad(false);
    getRiskyCustomers();
  };
  const handleFilterClick = (filter) => {
    setSelectedFilterPicker(filter);
    // Otomatik istek atmıyoruz, kullanıcı Listele butonuna basmalı
  };
  const getSMSReview = async () => {
    await Axios.post(
      `${context.api_endpoint}/company/customers/risky/sms/preview`,
      {
        all: selectedAllCustomer,
        customer_id: [...selectedCustomers],
        except: [...exceptedCustomers],
        message: sms.message,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setTotalNumber(response.data.data.total_numbers);
          setTotalSmsCredit(response.data.data.total_sms_credit);
          setOpenSmsDialog(true);
          setOpenSmsReviewDialog(true);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const sendSMS = () => {
    Axios.post(
      `${context.api_endpoint}/company/customers/risky/send/sms`,
      {
        all: selectedAllCustomer,
        customer_id: [...selectedCustomers],
        except: [...exceptedCustomers],
        message: sms.message,
        offset: parseInt(offset),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setSelectedCustomers([]);
          setSelectedAllCustomer(false);
          setSms({
            ...sms,
            message: "",
            selectedTemplate: null,
          })
          toast.success(context.t(`["customers/risky"].sendSMSSuccessToast`));
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const handleSmsChange = useCallback((newSms) => {
    setSms(prev => ({
      ...prev,
      message: newSms.message,
      selectedTemplate: newSms.selectedTemplate
    }));
  }, []);

  const handleDiscard = useCallback(() => {
    setSelectedCustomers([]);
    setExceptedCustomers([]);
    setSelectedAllCustomer(false);
    setSms({
      ...sms,
      message: "",
      selectedTemplate: null,
    });
    setOpenSmsDialog(false);
  }, []);

  return (
    <AuthContainer authorities={[1]} limited_permission="customer">
      <Grid>
        <Column
          className="xs-12 sm-12 md-12 lg-12 mb-1 pb-2"
          style={{ borderBottom: "1px solid #b2b2b2" }}
        >
          <Column
            className="xs-12 sm-12 md-12 lg-6"
            style={{ height: "63px", display: "flex", alignItems: "center" }}
          >
            <ShortButtonContainer>
              <ShortButton
                onClick={() => handleFilterClick({
                  id: 1,
                  label: "Bugün",
                  offset: 1,
                })}
                style={{
                  backgroundColor: `${selectedFilterPicker?.id === 1 ? "rgb(49, 169, 243, 0.14)" : ""}`,
                  border: "1px solid rgb(91, 187, 245, 0.41)",
                  color: "rgb(49, 169, 243)",
                }}
              >
                {context.t(`accounting.today`)}
              </ShortButton>
              <ShortButton
                onClick={() => handleFilterClick({
                  id: 2,
                  label: "Bu Hafta",
                  offset: 3,
                })}
                style={{
                  backgroundColor: `${selectedFilterPicker?.id === 2 ? "rgb(29, 139, 62, 0.21)" : ""}`,
                  border: "1px solid rgb(58, 154, 87, 0.35)",
                  color: "rgb(29, 139, 62)",
                }}
              >
                {context.t(`accounting.thisWeek`)}
              </ShortButton>
              <ShortButton
                onClick={() => handleFilterClick({
                  id: 3,
                  label: "Bu Ay",
                  offset: 4,
                })}
                style={{
                  backgroundColor: `${selectedFilterPicker?.id === 3 ? "rgb(254, 151, 151, 0.19)" : ""}`,
                  border: "1px solid rgb(185, 178, 178, 0.65)",
                  color: "rgb(254, 111, 111)",
                }}
              >
                {context.t(`accounting.thisMonth`)}
              </ShortButton>
            </ShortButtonContainer>
          </Column>
          <Column
            className="xs-12 sm-12 md-12 lg-6"
            style={{ borderLeft: "1px solid #b2b2b2" }}
          >
            <FilterContainer>
              <DatePickerWrapper>
                <FilterButton
                  onClick={() => {
                    setFilterModalOpen(true);
                    setSelectedFilterPicker(null);
                    setReadyToUseFilter(1);
                  }}
                >
                  <DateRange />
                  <span style={{ fontWeight: "500", fontSize: "1.2em", paddingLeft: "1vw" }}>
                    {reportsDate.between.start} - {reportsDate.between.end}
                  </span>
                </FilterButton>
                <ShortButton
                  onClick={handleList}
                  style={{
                    backgroundColor: "rgb(33, 150, 243, 0.14)",
                    border: "1px solid rgb(33, 150, 243, 0.41)",
                    color: "rgb(33, 150, 243)",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px"
                  }}
                >
                  <Search style={{ fontSize: "20px" }} />
                  {context.t(`['customers/risky'].listButtonTitle`)}
                </ShortButton>
              </DatePickerWrapper>
            </FilterContainer>
          </Column>
        </Column>

        {data.length > 0 && (
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-3 mb-1">
              <Button
                title={selectedAllCustomer ? context.t(`['customers/risky'].cancelAllButtonTitle`) : context.t(`['customers/risky'].selectedAllButtonTitle`)}
                icon="check"
                backgroundColor={selectedAllCustomer ? "red" : "primary"}
                textColor="white"
                fullWidth
                onClick={() => {
                  setSelectedCustomers([]);
                  setSelectedAllCustomer(!selectedAllCustomer);
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-3 mb-1">
              <Button
                title={context.t(`['customers/risky'].sendSMSButtonTitle`)}
                icon="message"
                backgroundColor="blue-opacity"
                textColor="blue"
                fullWidth
                disabled={selectedAllCustomer ? false : selectedCustomers.length === 0}
                onClick={() => setOpenSmsDialog(true)}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <ExcelButton
                route="customers/risky"
                requestData={{ is_excel: true }}
                size="sm"
              />
            </Column>
          </Grid>
        )}

        <Grid>
          <Column className="xs-12 sm-12">
            <Table
              refreshAction={() => getRiskyCustomers()}
              loaded={true}
              headings={{
                full_name: {
                  label: context.t(`['customers/risky'].headings.fullName`),
                  sortable: {
                    0: [
                      { field: "`customers`.`name`", order: "ASC" },
                      { field: "`customers`.`surname`", order: "ASC" },
                    ],
                    1: [
                      { field: "`customers`.`name`", order: "DESC" },
                      { field: "`customers`.`surname`", order: "DESC" },
                    ],
                  },
                },
                phone: {
                  label: context.t(`['customers/risky'].headings.phone`),
                  style: { width: 150 },
                },
                sex: {
                  label: context.t(`['customers/risky'].headings.sex`),
                },
                "extra_detail.sum_income": {
                  label: context.t(`['customers/risky'].headings.incomeAll`),
                  suffix: context.state.currency ? context.state.currency : "₺",
                  sortable: {
                    0: [{ field: "`rp`.`amount`", order: "ASC" }],
                    1: [{ field: "`rp`.`amount`", order: "DESC" }],
                  },
                },
                "extra_detail.total_debt": {
                  label: context.t(`['customers/risky'].headings.debtAll`),
                  suffix: context.state.currency ? context.state.currency : "₺",
                },
                "extra_detail.last_transaction_date": {
                  label: context.t(`['customers/risky'].headings.lastTransaction`),
                },
                _: { label: context.t(`component.actionHeadingText`) },
              }}
              rows={initialLoad ? [] : data}
              replacements={{
                sex: {
                  null: context.t(`['customers/risky'].replacements.sex.undef`),
                  0: context.t(`['customers/risky'].replacements.sex.female`),
                  1: context.t(`['customers/risky'].replacements.sex.male`),
                  2: context.t(`['customers/risky'].replacements.sex.undef`),
                },
              }}
              pagination={pagination}
              selectedAll={selectedAllCustomer}
              excepteds={exceptedCustomers}
              checkedCheckboxes={selectedCustomers}
              checkboxOnChange={(row, state) => {
                if (state) {
                  setSelectedCustomers([...selectedCustomers, row.id]);
                  setExceptedCustomers([...exceptedCustomers.filter((id) => id !== row.id)]);
                } else {
                  setSelectedCustomers([...selectedCustomers.filter((id) => id !== row.id)]);
                  setExceptedCustomers([...exceptedCustomers, row.id]);
                }
              }}
              buttons={[
                {
                  title: context.t(`component.detailEditButtonTitle`),
                  icon: "launch",
                  textColor: "primary",
                  transitionEffect: true,
                  pushEffect: true,
                  onClick: (row) => history.push(`/customers/detail/${row.id}`),
                },
              ]}
            />
          </Column>
        </Grid>
      </Grid>

      <AlertDialog
        title={<h3 style={{ textAlign: "center" }}>{context.t(`accounting.filterArea.header`)}</h3>}
        open={filterModalOpen}
        maxWidth="sm"
        closeHandler={() => setFilterModalOpen(false)}
        buttons={[
          {
            title: context.t(`accounting.filterArea.approve`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {
              setFilterModalOpen(false);
              // Modal kapandıktan sonra otomatik istek atmıyoruz
            },
          },
          {
            title: context.t(`accounting.filterArea.cancel`),
            icon: "close",
            textColor: "grey",
            onClick: () => {
              setSelectedFilterPicker({
                id: 1,
                label: "Bugün",
                offset: 1,
              });
              setFilterModalOpen(false);
            },
          },
        ]}
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12 mt-3 mb-5">
            <Column className="xs-12 sm-12 md-12 lg-6">
              <ShortButtonContainer
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <ShortButton
                  style={{
                    marginBottom: "10px",
                    border: "1px solid rgba(249,186,132, 0.51)",
                    color: "rgb(219,146,92)",
                    backgroundColor: `${readyToUseFilter === 1 ? "rgba(249,186,132, 0.21)" : ""}`
                  }}
                  onClick={() => {
                    setReadyToUseFilter(1);
                    setStart(moment().subtract(1, "days").toDate());
                    setEnd(moment().toDate());
                  }}
                >
                  {context.t(`component.dateRangeStats.yesterday`)}
                </ShortButton>
                <ShortButton
                  style={{
                    marginBottom: "10px",
                    border: "1px solid rgb(91, 187, 245, 0.41)",
                    color: "rgb(49, 169, 243)",
                    backgroundColor: `${readyToUseFilter === 2 ? "rgb(49, 169, 243, 0.14)" : ""}`
                  }}
                  onClick={() => {
                    setReadyToUseFilter(2);
                    setStart(moment().subtract(7, "days").toDate());
                    setEnd(moment().toDate());
                  }}
                >
                  {context.t(`component.dateRangeStats.lastWeek`)}
                </ShortButton>
                <ShortButton
                  style={{
                    marginBottom: "10px",
                    border: "1px solid rgb(58, 154, 87, 0.35)",
                    color: "rgb(29, 139, 62)",
                    backgroundColor: `${readyToUseFilter === 3 ? "rgb(29, 139, 62, 0.21)" : ""}`
                  }}
                  onClick={() => {
                    setReadyToUseFilter(3);
                    setStart(moment().subtract(15, "days").toDate());
                    setEnd(moment().toDate());
                  }}
                >
                  {context.t(`component.dateRangeStats.last_15_days`)}
                </ShortButton>
                <ShortButton
                  style={{
                    marginBottom: "10px",
                    border: "1px solid rgb(185, 178, 178, 0.65)",
                    color: "rgb(254, 111, 111)",
                    backgroundColor: `${readyToUseFilter === 4 ? "rgb(254, 151, 151, 0.19)" : ""}`
                  }}
                  onClick={() => {
                    setReadyToUseFilter(4);
                    setStart(moment().subtract(30, "days").toDate());
                    setEnd(moment().toDate());
                  }}
                >
                  {context.t(`component.dateRangeStats.lastMonth`)}
                </ShortButton>
              </ShortButtonContainer>
            </Column>

            <Column
              className="xs-12 sm-12 md-12 lg-6"
              style={{ height: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderLeft: "1px solid #b2b2b2",
                  height: "100%",
                }}
              >
                <Column className="xs-12 sm-12 md-12 lg-12">
                  <DatePickerInput
                    label={context.t(`reports.startInputLabel`)}
                    value={start}
                    maxDate={end}
                    onChange={(date) => {
                      setStart(date);
                      setSelectedFilterPicker(null);
                      setReportsDate({
                        ...reportsDate,
                        between: {
                          ...reportsDate.between,
                          start: moment(date).format("DD/MM/YYYY"),
                        },
                      });
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12">
                  <DatePickerInput
                    label={context.t(`reports.endInputLabel`)}
                    value={end}
                    minDate={start}
                    onChange={(date) => {
                      setEnd(date);
                      setSelectedFilterPicker(null);
                      setReportsDate({
                        ...reportsDate,
                        between: {
                          ...reportsDate.between,
                          end: moment(date).format("DD/MM/YYYY"),
                        },
                      });
                    }}
                  />
                </Column>
              </div>
            </Column>
          </Column>
        </Grid>
      </AlertDialog>

      <MessageDialog
        open={openSmsDialog}
        sms={sms}
        context={context}
        onClose={() => setOpenSmsDialog(false)}
        onSmsChange={handleSmsChange}
        onSend={getSMSReview}
        onDiscard={handleDiscard}
      />
      <SMSReview
        open={openSmsReviewDialog}
        closeHandler={() => {
          setOpenSmsReviewDialog(false);
        }}
        sendSMS={sendSMS}
        sms_content={sms.message}
        total_number={totalNumber}
        total_sms_credit={totalSmsCredit}
      />
    </AuthContainer>
  );
}

// Styled components
const ShortButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const ShortButton = styled.div`
  border-radius: 8px;
  width: 12vw;
  padding: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const FilterButton = styled.div`
  border-radius: 8px;
  padding: 0 2.5vw;
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  color: rgb(219, 146, 92);
  border: 1px solid rgba(249, 186, 132, 0.51);
  background-color: rgba(249, 186, 132, 0.21);
`;
const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;
const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 90%;
  max-width: 800px;
`;

export default CustomersRisky;
