import React, { useContext } from "react";

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
} from "@material-ui/core";
import styled from "styled-components";
import { Delete, Edit } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";

import { toast } from "react-toastify";
import AppContext from "../../../../../context/store";
import Button from "../../../../../theme/Button";
import { Column, Grid } from "../../../../../theme/Grid";

const EditClauseDetail = (props) => {
  const context = useContext(AppContext)
  const {
    clauses,
    setSelectedClause,
    setDeleteModal,
    setEditMode,
    setUpdateClause,
    selectedClause,
    updateClause,
    updateClauses,
    saveClauses
  } = props
  return (
    <Grid>
      {/* Maddelerin edit sayfasındaki yeri */}
      <Column className="xs-12 sm-12 md-12 lg-6">
        {clauses.map((item) => (
          <List>
            <ListItem>
              <ListItemText
                primary={
                  item.type === 2 ? (
                    <TypeShow>
                      {item.title}
                      <span>
                        {context.t(`[settings/agreements].yesOrNo`)}
                      </span>
                    </TypeShow>
                  ) : (
                    item.title
                  )
                }
                secondary={item.content.split("\n").map((str) => (
                  <p>{str}</p>
                ))}
                style={{
                  paddingRight: "1.75vw",
                }}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                  <Delete
                    onClick={() => {
                      setSelectedClause({
                        title: item.title,
                        content: item.content,
                        id: item.id,
                        type: item.type,
                      });
                      setDeleteModal(true);
                    }}
                  />
                </IconButton>
                <IconButton edge="end" aria-label="edit">
                  <Edit
                    onClick={() => {
                      setSelectedClause({
                        title: item.title,
                        content: item.content,
                        id: item.id,
                        type: item.type,
                      });
                      setEditMode(true);
                      setUpdateClause(true);
                    }}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        ))}
      </Column>

      {/* Maddelerin editleneceği bölüm */}
      <Column
        className="xs-12 sm-12 md-12 lg-6"
        style={{ borderLeft: "1px solid #b2b2b2" }}
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12 mt-3">
            <TextField
              label={context.t(
                `[settings/agreements].createClausesArea.title`
              )}
              value={selectedClause.title}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      onClick={() => {
                        setSelectedClause({
                          ...selectedClause,
                          type: selectedClause.type === 1 ? 2 : 1,
                        });
                      }}
                      style={{
                        background: "#fff",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        padding: "8px 16px",
                        border: "1px solid #b2b2b2",
                        borderRadius: "15px",
                        zIndex: "99999999",
                        color: "#3189ec",
                      }}
                    >
                      <span>
                        {" "}
                        {context.t(
                          `[settings/agreements].createClausesArea.yesOrNo`
                        )}
                      </span>
                      <Checkbox
                        style={{
                          transform: "scale(1.5)",
                          padding: "5px 15px",
                        }}
                        checked={selectedClause.type === 1 ? false : true}
                        // onChange={async () => {
                        //   await setClausesType(clauses_type === 1 ? 2 : 1);
                        // }}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    </div>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSelectedClause({
                  ...selectedClause,
                  title: e.target.value,
                });
              }}
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-12">
            <TextField
              label={context.t(
                `[settings/agreements].createClausesArea.context`
              )}
              multiline
              value={selectedClause.content}
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setSelectedClause({
                  ...selectedClause,
                  content: e.target.value,
                });
              }}
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-12">
            {updateClause ? (
              <>
                <Column className="sx-12 sm-12 md-12 lg-6">
                  <Button
                    fullWidth={true}
                    title={context.t(
                      `[settings/agreements].createClausesArea.update`
                    )}
                    backgroundColor="primary"
                    textColor="white"
                    icon="update"
                    onClick={() => {
                      updateClauses();
                      setEditMode(false);
                      setUpdateClause(false);
                    }}
                  />
                </Column>
                <Column className="sx-12 sm-12 md-12 lg-6">
                  <Button
                    fullWidth={true}
                    title={context.t(
                      `[settings/agreements].createClausesArea.cancel`
                    )}
                    backgroundColor="white"
                    textColor="black"
                    outlined
                    icon="close"
                    onClick={() => {
                      setSelectedClause({});
                      setEditMode(false);
                      setUpdateClause(false);
                    }}
                  />
                </Column>
              </>
            ) : (
              <>
                <Column className="sx-12 sm-12 md-12 lg-6">
                  <Button
                    fullWidth={true}
                    title={context.t(
                      `[settings/agreements].createClausesArea.save`
                    )}
                    backgroundColor="primary"
                    textColor="white"
                    icon="save"
                    onClick={() => {
                      if (
                        selectedClause.title.trim() !== "" &&
                        selectedClause.content.trim() !== ""
                      ) {
                        saveClauses();
                        setEditMode(false);
                      } else {
                        toast.warning(
                          context.t(
                            `[settings/agreements].createClausesArea.fillAreaComplately`
                          )
                        );
                      }
                    }}
                  // onClick={() => {
                  //   console.log(clauses_type);
                  // }}
                  />
                </Column>
                <Column className="sx-12 sm-12 md-12 lg-6">
                  <Button
                    fullWidth={true}
                    title={context.t(
                      `[settings/agreements].createClausesArea.cancel`
                    )}
                    backgroundColor="white"
                    textColor="black"
                    outlined
                    icon="close"
                    onClick={() => {
                      setSelectedClause({});
                      setEditMode(false);
                    }}
                  />
                </Column>
              </>
            )}
          </Column>
        </Grid>
      </Column>
    </Grid>
  )
}

const TypeShow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  span {
    border: 1px solid #3189ec;
    background: rgba(13, 78, 108, 0.04);
    color: #3189ec;
    font-size: 10px;
    border-radius: 10px;
    height: fit-content;
    padding: 5px;
    margin-left: 10px;
  }
`;

export default EditClauseDetail