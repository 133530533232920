/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useContext, useEffect } from "react";
import Loader from "../../assets/images/loader.gif";
import LoaderEN from "../../assets/images/circular.gif";
import LoaderSM from "../../assets/images/loaderSM.gif";
import { MenuItem } from "@material-ui/core";
import styled from "styled-components";
import Input from "./Input";
import AppContext from "../../context/store";
import Axios from "axios";

const CustomerSearchInput = (props) => {
  const context = useContext(AppContext);
  // Suggested menu show/hide
  const [open, setOpen] = useState(false);

  // Input end-adornment loader
  const [loading, setLoading] = useState(false);

  // Suggested values
  const [options, setOptions] = useState();

  // Posting to async request
  const [keyword, setKeyword] = useState("");


  // Handling this
  const [selected, setSelected] = useState(
    props.selected ? props.selected : null
  );

  // timeout
  const [debouncedValue, setDebouncedValue] = useState('')

  // Input value
  const [input, setInput] = useState(null);

  const getCustomers = (key) => {
    Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    }).then(async ({ data }) => {
      if (props.withPhone) {
        setOptions(
          [...data?.data?.records].map((item) => {
            return {
              id: item.id,
              full_name: `${item.full_name} (*** ${item.phone.slice(6, 11)})`,
              send_sms: item.send_sms,
              description: item.description,
            };
          })
        )
      }
      else {
        setOptions([...data?.data?.records]);
      }
    });
  };


  useEffect(() => {
    if (debouncedValue !== null) {
      getCustomers(debouncedValue)
    }
  }, [debouncedValue]);

  useEffect(() => {
    setLoading(true)
    const handler = setTimeout(() => {
      if (keyword !== null &&
        (selected && selected.hasOwnProperty(props.labelKey)
          ? selected[props.labelKey] !== keyword
            ? true
            : false
          : true)) {
        setDebouncedValue(keyword);
        setLoading(false)
      }
    }, 800);
    return () => {
      clearTimeout(handler);
    };
  }, [keyword])

  useEffect(() => {
    selected
      ? props.selectedHandler(
        props.returnFullObject ? selected : selected[props.valueKey]
      )
      : setKeyword("");
  }, [selected]);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  useEffect(() => {
    window.addEventListener("click", () => {
      setOpen(false);
    });
  }, []);

  let typingTimeout = null;

  return (
    <AutocompleteContainer className="mb-3">
      <Input
        required={props.required}
        style={props.style}
        // onClick={async (e) => {
        //   e.stopPropagation();
        //   const x = await props.asyncDataService(keyword);
        //   await setOptions(x?.length ? [...x] : []);
        //   await setLoading(false);
        //   await setOpen(true);
        //   await setKeyword(" ");
        // }}
        onClick={async (e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        className="mb-0"
        // readOnly={selected ? true : false}
        label={props.label}
        helperText={props.helperText}
        disabled={props.disabled}
        hoverHelperText={props.hoverHelperText}
        placeholder={props.placeholder}
        value={`${selected?.[props.labelKey] ?? input ?? props.value ?? ""}`}
        InputProps={{
          onChange: (e) => {
            setInput(e.target.value);
            setKeyword(e.target.value)
          },
          // onKeyUp: (e) => {
          //   const value = e.target.value;
          //   // eslint-disable-next-line
          //   if (value != keyword) {
          //     clearTimeout(typingTimeout);
          //     typingTimeout =
          //       value.length > 0 && setTimeout(() => setKeyword(value), 500);
          //     setLoading(true);
          //   }
          // },
          startAdornment: props.startAdornment ? props.startAdornment : "",
          endAdornment: (
            <React.Fragment>
              {selected || props.value ? (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    await setKeyword("");
                    await setInput("");
                    await setSelected(null);
                    await props.onRemove();
                  }}
                  className="material-icons"
                >
                  close
                </span>
              ) : loading ? (
                <img
                  src={
                    process.env.APP_NAME === "salon"
                      ? Loader
                      : process.env.APP_NAME === "en"
                        ? LoaderEN
                        : LoaderSM
                  }
                  style={{ margin: '0px', padding: '0px', height: '50px', width: '50px' }}
                  alt="loading"
                />
              ) : null}
            </React.Fragment>
          ),
        }}
      />
      {open && !props.disabled ? (
        <OptionsContainer
          hasHelperText={props.helperText !== undefined ? true : false}
          style={props.opcStyle}
        >
          {options &&
            options.length > 0 &&
            options.map((option, index) => (
              <MenuItem
                key={index}
                onClick={async () => {
                  setSelected(option);
                  setOpen(false);
                  setKeyword(option[props.labelKey]);
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {props.extraLimit ? (
                    <p>{`${option[props.labelKey]} (${option[props.extraLimit]
                      })`}</p>
                  ) : (
                    <p>{option[props.labelKey]}</p>
                  )}
                  <p>
                    {props.showField
                      ? `${Math.round(option[props.showField])} ${context.state.currency ? context.state.currency : "₺"
                      }`
                      : ""}
                  </p>
                </div>
              </MenuItem>
            ))}
        </OptionsContainer>
      ) : null}
    </AutocompleteContainer>
  );
};

export default React.memo(CustomerSearchInput);

const AutocompleteContainer = styled.div`
  position: relative;
  width: 100%;
`;

const OptionsContainer = styled.div`
  width: 100%;
  background: #fff;
  max-height: 50vh;
  overflow-y: auto;
  margin-top: ${({ hasHelperText }) =>
    `${hasHelperText === true ? "-22px" : "0"}`};
  padding: 8px 0;
  border-radius: 5px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.06);
  z-index: 99999999999 !important;
  position: absolute;
`;
