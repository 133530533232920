/* eslint-disable */
import React, { useEffect, useContext, useRef, useState } from "react";
import styled from "styled-components";
import AppContext from "../../../../context/store";

import { Grid, Column } from "../../../../theme/Grid";
import AutocompleteInput from "../../../../theme/CustomMUI/AutoComplete";
import Button from "../../../../theme/Button";
import Select from "../../../../theme/CustomMUI/Select";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import MaskedInput from "../../../../theme/CustomMUI/MaskedInput";
import { toast } from "react-toastify";
import moment from "moment";
import {
  AddBox,
  Layers,
  Search,
} from "@material-ui/icons";
import { flushSync } from "react-dom";
import RemainingDebtRed from "../../../../assets/images/svg/remaining_debt_red.svg";
import StaffSelect from "../../../../theme/CustomMUI/StaffSelect";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

const themeForDatePicker = createTheme({
  typography: {
    fontSize: 11,
  },
});

/**
 * Hizmet satışının yapıldığı, verilerin düzenlendiği alan.
 */
export const _ServiceSalesBuilder = ({
  checkout_data,
  lateCheckout,
  setLateCheckout,
  setCheckoutData,
  getTotalAmounts,
  setServicesDetailDialogModal,
  _service_transaction,
  setServiceTransaction,
  setSelectedStaffServices,
  staffs,
  setServiceTransactionModal,
  services,
  customer_s,
  setDiscountTarget,
  setDiscountModal,
  setDiscountValue,
  setDiscountType,
  print_dialog,
  stock_items,
  customer
}) => {
  const context = useContext(AppContext);
  let total_mount = 0;
  const rounded_amount = Math.round(getTotalAmounts().services);

  let amountData = Object.keys(checkout_data.services).map((key) =>
    checkout_data.services[key] !== undefined
      ? checkout_data.services[key].amount !== NaN
        ? checkout_data.services[key].amount
        : 0
      : 0
  );

  for (let i = 0; i < amountData.length - 1; i++) {
    total_mount += Math.round(amountData[i]);
  }

  for (let j = 0; j < amountData.length; j++) {
    amountData[j] = Math.round(amountData[j]);
    if (amountData[amountData.length - 1]) {
      amountData[amountData.length - 1] = rounded_amount - total_mount;
    }
  }

  useEffect(() => {
    Object.keys(checkout_data.services).map((key) => {
      checkout_data.services[key] !== undefined
        ? checkout_data.services[key].amount !== NaN
          ? (checkout_data.services[key].amount = Math.round(amountData[key]))
          : 0
        : 0;
    });
  }, [checkout_data]);

  const inputRef = useRef();

  return (
    <SectionBox style={{ overflowX: "hidden" }}>
      <SectionHeading
        style={{
          backgroundColor: "rgba(13, 78, 108, 0.04)",
          color: "#3189ec",
          border: "1px solid #3189ec",
          padding: "10px",
          borderRadius: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div
          className="detail-search"
          style={{
            backgroundColor: "#3189ec",
          }}
          onClick={() => {
            setServicesDetailDialogModal(true);
            console.log(123);
          }}
        >
          <Search style={{ color: "white", fontSize: "20px" }} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Layers />
          <h3 style={{ marginLeft: "15px" }}>
            {context.t(
              `['receipt/detail/id'].serviceTransactions.title`
            )}
          </h3>
        </div>
        <div>
          <AddBox
            style={{ cursor: "pointer", fontSize: "36px" }}
            onClick={() => {
              setServiceTransaction({
                ..._service_transaction,
                process_date: moment().format("YYYY-MM-DD"),
              });
              setSelectedStaffServices([
                ...staffs
                  .find(
                    (staff) =>
                      staff.id === _service_transaction.staff_id
                  )
                  .services.map((service) => service.service),
              ]);
              setServiceTransactionModal(true);
            }}
          />
        </div>
      </SectionHeading>
      <ListSection className="no-scroll-bar">
        {
          //Hizmet Listesi
          Object.keys(checkout_data.services).filter(
            (key) => checkout_data.services[key] !== undefined
          ).length > 0 ? (
            Object.keys(checkout_data.services).map(
              (key, index) =>
                checkout_data.services[key] !== undefined &&
                checkout_data.services[key].process_type !== 1 && (
                  <Grid className="no-gutters-row" key={index}>
                    <Column className="xs-12 sm-12 md-12 mt-1 lg-3">
                      <MuiThemeProvider theme={themeForDatePicker}>
                        <DatePickerInput
                          className="mb-1"
                          label={context.t(
                            `['receipt/detail/id'].serviceTransactions.dateInputLabel`
                          )}
                          error={false}
                          value={
                            moment(
                              checkout_data.services[key].process_date
                            ).format("YYYY-MM-DD") === "0001-01-01"
                              ? null
                              : moment(
                                checkout_data.services[key].process_date
                              )
                          }
                          onChange={(process_date) => {
                            setCheckoutData({
                              ...checkout_data,
                              services: {
                                ...checkout_data.services,
                                [key]: {
                                  ...checkout_data.services[key],
                                  process_date:
                                    moment(process_date).format(
                                      "YYYY-MM-DD"
                                    ),
                                },
                              }
                            });
                          }}
                        />
                      </MuiThemeProvider>
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-3">
                      <Select
                        className="mb-1"
                        label={context.t(
                          `['receipt/detail/id'].serviceTransactions.nameInputLabel`
                        )}
                        items={staffs
                          ?.filter(
                            (staff) =>
                              staff.id ===
                              checkout_data.services[key].staff_id
                          )[0]
                          ?.services?.map((service) => service.service)
                        }
                        deletedService={staffs
                          ?.filter(
                            (staff) =>
                              staff.id ===
                              lateCheckout?.services[key]?.staff_id
                          )[0]
                          ?.services?.map((service) => service.service)?.includes(services.filter((el) => el.id === lateCheckout?.services[key]?.service_id)?.map((data) => {
                            return ({
                              ...data,
                              is_passive: true
                            })
                          })) ? [] : services?.filter((el) => el.id === lateCheckout?.services[key]?.service_id)?.map((data) => {
                            return ({
                              ...data,
                              is_passive: true
                            })
                          })}
                        serviceDisabled={true}
                        selected={
                          checkout_data?.services[key]?.service_id
                            ? services?.filter(
                              (item) =>
                                item.id ===
                                checkout_data.services[key].service_id
                            )?.length
                              ? checkout_data.services[key].service_id
                              : context.t(`["receipt/detail/id"].deletedService`)
                            : context.t(`["receipt/detail/id"].deletedService`)
                        }
                        labelKey="name"
                        valueKey="id"
                        title={
                          services.filter(
                            (item) =>
                              item.id ===
                              checkout_data.services[key].service_id
                          ).length > 0
                            ? services.filter(
                              (item) =>
                                item.id ===
                                checkout_data.services[key].service_id
                            )[0].name
                            : context.t(`["receipt/detail/id"].deletedService`)
                        }
                        handler={(service_id) => {
                          setCheckoutData({
                            ...checkout_data,
                            services: {
                              ...checkout_data.services,
                              [key]: {
                                ...checkout_data.services[key],
                                service_id: parseInt(service_id),
                                process_type: 2,
                              },
                            }
                          });
                        }}
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-2">
                      <StaffSelect
                        className="mb-1"
                        disabled={context.state.user.permission === 0}
                        label={context.t(
                          `['receipt/detail/id'].serviceTransactions.staffInputLabel`
                        )}
                        items={staffs}
                        selected={
                          checkout_data.services[key].staff_id
                            ? checkout_data.services[key].staff_id
                            : ""
                        }
                        labelKey="full_name"
                        valueKey="id"
                        handler={(staff_id) => {
                          setCheckoutData({
                            ...checkout_data,
                            services: {
                              ...checkout_data.services,
                              [key]: {
                                ...checkout_data.services[key],
                                staff_id: parseInt(staff_id),
                                process_type: 2,
                              },
                            }
                          });
                          setLateCheckout({
                            ...checkout_data,
                            services: {
                              ...checkout_data.services,
                              [key]: {
                                ...checkout_data.services[key],
                                staff_id: parseInt(staff_id),
                                process_type: 2,
                              },
                            }
                          })
                        }}
                      />
                    </Column>

                    <Column className="xs-12 sm-12 md-12 lg-2">
                      <Select
                        className="mb-1"
                        disabled={context.state.user.permission === 0}
                        label={context.t(`sms.deviceStatus`)}
                        items={customer_s}
                        selected={
                          checkout_data.services[key].customer_state !== null
                            ? `${checkout_data.services[key].customer_state}`
                            : ""
                        }
                        labelKey="label"
                        valueKey="value"
                        handler={(customer_state) => {
                          setCheckoutData({
                            ...checkout_data,
                            services: {
                              ...checkout_data.services,
                              [key]: {
                                ...checkout_data.services[key],
                                customer_state: parseInt(customer_state),
                                process_type: 2,
                              },
                            }
                          });
                        }}
                      />
                    </Column>

                    <Column className="xs-12 sm-12 md-12 lg-2">
                      <MaskedInput
                        className="mb-1"
                        label={
                          context.t(
                            `['receipt/detail/id'].serviceTransactions.amountInputLabel`
                          ) +
                          (context.state.currency
                            ? " (" + context.state.currency + ")"
                            : "(₺)")
                        }
                        mask="9999999999999"
                        maskChar=""
                        value={
                          checkout_data.services[key].amount !== null
                            ? checkout_data.services[key].amount
                            : ""
                        }
                        onChange={(e) => {
                          setCheckoutData({
                            ...checkout_data,
                            services: {
                              ...checkout_data.services,
                              [key]: {
                                ...checkout_data.services[key],
                                amount: /[0-9,.]+/.test(e.target.value)
                                  ? Math.round(parseFloat(e.target.value))
                                  : null,
                                process_type: 2,
                              },
                            },
                          });
                        }}
                      />
                    </Column>
                  </Grid>
                )
            )
          ) : (
            <NoDataContainer style={{ height: "80%" }}>
              <img
                src={require("../../../../assets/images/no-data-img.png")}
                alt="Malesef bulunamadı!"
              />
              <p>
                {context.t(
                  `['receipt/detail/id'].serviceTransactions.noDataText`
                )}
              </p>
            </NoDataContainer>
          )
        }
      </ListSection>
      {Object.keys(checkout_data.services).filter(
        (key) => checkout_data.services[key] !== undefined
      ).length > 0 && (
          <SubTotalText className="mt-2">
            <CustomerBoxDiv>
              <Button
                style={{
                  border: "1px solid rgba(255, 127, 80, 0.53)",
                }}
                disabled={
                  Object.keys(checkout_data.services).filter(
                    (key) => checkout_data.services[key] !== undefined
                  ).length === 0
                }
                className="mr-2"
                icon="emoji_symbols"
                title={context.t(
                  `['receipt/detail/id'].applyDiscountButtonTitle`
                )}
                backgroundColor="orange-opacity"
                textColor="orange"
                onClick={() => {
                  if (
                    Object.keys(checkout_data.paids).filter(
                      (key) => checkout_data.paids[key] !== undefined
                    ).length === 0 &&
                    Object.keys(checkout_data.installment).filter(
                      (key) => checkout_data.installment[key] !== undefined
                    ).length === 0
                  ) {
                    setDiscountTarget("services");
                    setDiscountValue(null)
                    setDiscountType("")
                    setDiscountModal(true)
                  } else {
                    toast.warning(
                      context.t(
                        `['receipt/detail/id'].discountBeforePaymentToast`
                      )
                    );
                  }
                }}
              />
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={RemainingDebtRed} alt="remaining_debt_red" />
                <CustomerPointBox>
                  {getTotalAmounts().services !=
                    getTotalAmounts()._fservices ? (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <p
                        style={{ textDecoration: "line-through" }}
                        className="fc-grey mr-1"
                      >
                        {getTotalAmounts()._fservices.toFixed(0)}
                        {context.state.currency
                          ? context.state.currency
                          : "₺"}
                      </p>
                      <p>
                        {getTotalAmounts().services.toFixed(0)}
                        {context.state.currency
                          ? context.state.currency
                          : "₺"}
                      </p>
                    </span>
                  ) : (
                    <span>
                      <p>
                        {Math.round(getTotalAmounts().services)}
                        {context.state.currency
                          ? context.state.currency
                          : "₺"}
                      </p>
                    </span>
                  )}
                  <h3>
                    {context.t(
                      `['receipt/detail/id'].serviceTransactions.totalAmount`
                    )}
                  </h3>
                </CustomerPointBox>
              </div>
            </CustomerBoxDiv>
          </SubTotalText>
        )}
    </SectionBox>
  );
};

// Servis satışını eklemek için açılan dialog.

export const _ServiceSalesDialogBuilder = ({
  service_transaction,
  setServiceTransaction,
  service_transaction_modal,
  setServiceTransactionModal,
  addSingleServiceIntoCheckout,
  staffs,
  setSelectedStaffServices,
  selected_staff_services,
  searchServicesByKey,
}) => {
  const context = useContext(AppContext);

  return (
    <AlertDialog
      containerStyle={{
        overflow: "visible",
      }}
      fullWidth
      maxWidth="sm"
      title={context.t(`['receipt/detail/id'].serviceTransaction.title`)}
      open={service_transaction_modal}
      closeHandler={() => setServiceTransactionModal(false)}
      buttons={[
        {
          title: context.t(
            `['receipt/detail/id'].serviceTransaction.addButtonTitle`
          ),
          icon: "check",
          backgroundColor: "primary-opacity",
          textColor: "primary",
          onClick: () => addSingleServiceIntoCheckout(),
        },
        {
          title: context.t(
            `['receipt/detail/id'].serviceTransaction.discardButtonTitle`
          ),
          icon: "close",
          textColor: "grey",
        },
      ]}
    >
      <StaffSelect
        disabled={context.state.user.permission === 0}
        label={context.t(
          `['receipt/detail/id'].serviceTransaction.staffInputLabel`
        )}
        items={staffs}
        selected={service_transaction.staff_id}
        labelKey="full_name"
        valueKey="id"
        returnFullObject
        handler={(staff) => {
          setSelectedStaffServices([...staff.services.map((service) => service.service)]);
          setServiceTransaction({
            ...service_transaction,
            staff_id: parseInt(staff.id),
          })
        }}
      />

      <AutocompleteInput
        label={context.t(
          `['receipt/detail/id'].serviceTransaction.serviceInputLabel`
        )}
        labelKey="name"
        showField="amount"
        style={{ zIndex: "9999" }}
        // opcStyle={{ marginTop: "-80px" }}
        valueKey="id"
        selected={
          selected_staff_services !== null
            ? selected_staff_services
            : ""
        }
        onRemove={() => {
          setSelectedStaffServices(null);
          searchServicesByKey("");
        }}
        returnFullObject
        selectedHandler={(service) => {
          setServiceTransaction({
            ...service_transaction,
            service_id: parseInt(service.id),
            amount: Math.round(service.amount),
            first_amount: Math.round(service.amount),
            process_date: moment().format("YYYY-MM-DD"),
            process_type: 2,
          });
          console.log(service_transaction);
        }}
        asyncDataService={(keyword) =>
          keyword.length > 0 ? searchServicesByKey(keyword) : []
        }
      />
    </AlertDialog>
  );
};

const SectionBox = styled.div`
  width: 100%;
  overflow-x: scroll;
  box-sizing: border-box;
  padding-top: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  color: var(--dark-primary);

  h3{font - size: 18px; font-weight: 600; flex: 1; margin: 0; padding: 0}
`;

const ListSection = styled.div`
  overflow-x: scroll;
  margin-bottom: auto;
  padding-left: 5px;
  padding-right: 5px;
`;

const SubTotalText = styled.div`
  span {
    font-size: 13px;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
`;

const CustomerBoxDiv = styled.div`
  width: 100%;
  height: 57px;
  padding: 8px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 5px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    margin-top: 7px;
    margin-left: 10px;
  }
`;

const CustomerPointBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-top: 5px;

  h3 {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    color: #3c3c3c;
  }

  span {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;

    b {
      font-size: 13px;
      font-weight: 400;
      color: #aaa;
    }
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 32vh;
    object-fit: contain;
  }
  p {
    color: #407bff;
    font-size: 18px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;