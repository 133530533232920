import React, { useContext, useState, useEffect } from "react";
import { ChromePicker } from "react-color";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import CardSection from "../../../components/Cards/CardSection";

import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";

import styled from "styled-components";
import { Column, Grid } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import Select from "../../../theme/CustomMUI/Select";
import Button from "../../../theme/Button";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import {
  ButtonBase,
  FormControlLabel,
  Switch,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Backdrop,
} from "@material-ui/core";
import Axios from "axios";
import { toast } from "react-toastify";
import { Alert } from "@material-ui/lab";
import Validate from "../../../functions/Validate";
import FCLabel from "../../../theme/FCLabel";
import CSwitch from "../../../theme/CustomMUI/CSwitch";
import LimitedPermission from "../../../functions/LimitedPermissions";
import { flushSync } from "react-dom";
import { CloseOutlined } from "@material-ui/icons";
import FormDataGenerator from "../../../functions/FormDataGenerator";
import CompressImage from "../../../functions/CompressImage";
import { async } from "q";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import PhoneInput from "react-phone-input-2";
import EmpoolesPackages from "../../../components/EmpoolesPackages";
import ModalDialog from "../../../theme/CustomMUI/AlertDialog";

const AddEmployee = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const params = useParams();

  const [loaded, setLoaded] = useState(true);
  const [tutorial, setTutorial] = useState(false);
  const [selectedDay, setSelectedDay] = useState(1);
  const [week, setWeek] = useState([
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
    "Pazar",
  ]);

  const [generatedHours, setGeneratedHours] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companyServices, setCompanyServices] = useState([]);

  const [employeeId, setEmployeeId] = useState(null);
  const [filePhoto, setFilePhoto] = useState(null);
  const [image, setImage] = useState(null);

  const [categoryOpen, setCategoryOpen] = useState([
    {
      id: 0,
      inAcvtive: false,
    },
  ]);

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const [employee, setEmployee] = useState({
    color: "",
    id: null,
    name: null,
    surname: null,
    sex: 0,
    password: null,
    send_sms: true,
    show_at_url: true,
    detail: {
      profile_photo: null,
      job_title: null,
      bounty: null,
      dialCode: null,
      phone: process.env.APP_NAME === 'management' ? context.state?.dialCode : null,
      mail: null,
    },
    limited_perm: {
      id: null,
      appointment: 2,
      sales: 2,
      packet: 2,
      stock: 2,
      customer: 2,
      customer_info: 2,
      services: 2,
      sms: 2,
      income_expense: 2,
      accounting: 2,
      wallet: 2,
      gallery: 2,
      system: 2,
    },
    permission: 0,
    service_ids: [],
    closed_appointments: {
      1: { active: true, hours: [] },
      2: { active: true, hours: [] },
      3: { active: true, hours: [] },
      4: { active: true, hours: [] },
      5: { active: true, hours: [] },
      6: { active: true, hours: [] },
      7: { active: true, hours: [] },
    },
    packet_ids: [],
  });

  const [limitedPermission, setLimitedPermission] = useState([
    ...LimitedPermission(),
  ]);

  const [selectedAgainServiceList, setSelectedAgainServiceList] = useState([]);
  const [selectedAgainServiceModal, setSelectedAgainServiceModal] = useState(false);

  const [packets, setPackets] = useState([]);
  const updateState = (newStateValue) => {
    setPackets(newStateValue);
  };

  const getRequiredArraysForStaffInsert = () => {
    Axios.get(`${context.api_endpoint}/company/itself`)
      .then(({ data }) => {
        setGeneratedHours([
          ...BetweenTimeGenerator({
            ...data.data.hours,
          }),
        ]);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getCompanyServices = () => {
    Axios.get(`${context.api_endpoint}/company/services/with/category/all`)
      .then(({ data }) => {
        setCompanyServices(
          data.data !== null
            ? [...data.data.filter((m) => m.services.length !== 0)]
            : []
        );
      })
      .catch((e) => console.log(e));
  };

  // Servicesleri Kategoriye Göre Sıralama

  // getServiceCategories = async () => {
  //   await Axios.post(
  //     `${context.api_endpoint}/company/services/category/list/all`
  //   ).then(async ({ data }) => {
  //     console.log(data);
  //   });
  // };

  const handleCategoryClick = (category, open_category) => {
    flushSync(() => {
      setCategoryOpen([
        ...categoryOpen.map((c) => {
          return {
            ...c,
            id: category,
            open: open_category,
          };
        }),
      ]);
    });
  };

  const insertStaff = async () => {
    validateStaff() &&
      (await Axios.post(`${context.api_endpoint}/company/staff/insert`, {
        ...employee,
        job_title: employee.detail.job_title,
        packet_ids: packets,
      })
        .then((response) => {
          if (!response.data.data.id) {
            setSelectedAgainServiceList(response.data.data.map((m) => m));
            setEmployee({
              ...employee,
              service_ids: [
                ...employee.service_ids,
                ...response.data.data.map((m) => m.id),
              ]
            });
            setSelectedAgainServiceModal(true);
          } else {
            toast.success(context.t(`employees.insertStaffSuccessToast`));
            history.push("/settings/employees");
            setEmployeeId(response.data.data.id);
            uploadPhotoSingleStaff(response.data.data.id);
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        }));
  };

  const employeeAddPhoto = async (e) => {
    setFilePhoto(
      FormDataGenerator({
        file: await CompressImage(e.target.files[0], {
          maxSizeMB: 2,
          fileType: "image/jpg",
        }),
      })
    );
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const checkTutorial = async () => {
    Axios.get(`${context.api_endpoint}/company/gift/wheel/`)
      .then((data) => {
        const item = data?.data?.data;
        setTutorial(!item.is_done);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadPhotoSingleStaff = async (id) => {
    if (filePhoto !== null) {
      Axios.post(
        `${context.api_endpoint}/company/staff/update/${id}/photo`,
        filePhoto,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then((response) => {
          if (response.status === 201) {
            // toast.success(context.t(`profile.photoUploadToast`));
            toast.success(context.t(`employees.updateStaffSuccessToast`));
            history.push("/settings/employees");
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    }
  };

  const deletePhotoSingleStaff = () => {
    Axios.delete(
      `${context.api_endpoint}/company/staff/delete/${employeeId}/photo`
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`profile.photoDeleteToast`));
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  // Set State Limited Permission
  const handleChange = (event, label) => {
    setEmployee({
      ...employee,
      limited_perm: {
        ...employee.limited_perm,
        [label]: parseInt(event.target.value),
      },
    });
  };

  const getServices = () => {
    Axios.get(
      `${context.api_endpoint}/company/services/with/category/all`
    ).then(async ({ data }) => {
      setCategories([...data.data.filter((m) => m.services.length !== 0)]);
    });
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  useEffect(() => {
    // Servicesleri Kategoriye Göre Sıralama
    getRequiredArraysForStaffInsert();
    // Servislerin geldiği endpoint
    getCompanyServices();

    // getServiceCategories();
    getServices();

    //tutorial kontrolü
    checkTutorial();
  }, []);

  const changeHourStatus = (hour) => {
    !employee.closed_appointments[selectedDay].hours.includes(hour)
      ? setEmployee({
        ...employee,
        closed_appointments: {
          ...employee.closed_appointments,
          [selectedDay]: {
            ...employee.closed_appointments[selectedDay],
            hours: [...employee.closed_appointments[selectedDay].hours, hour],
          },
        },
      })
      : setEmployee({
        ...employee,
        closed_appointments: {
          ...employee.closed_appointments,
          [selectedDay]: {
            ...employee.closed_appointments[selectedDay],
            hours: [
              ...employee.closed_appointments[selectedDay].hours.filter(
                (item) => item !== hour
              ),
            ],
          },
        },
      });
  };

  const changeDayStatus = () => {
    setEmployee({
      ...employee,
      closed_appointments: {
        ...employee.closed_appointments,
        [selectedDay]: {
          ...employee.closed_appointments[selectedDay],
          active: !employee.closed_appointments[selectedDay].active,
        },
      },
    });
  };

  const changeServiceStatus = (service_id) => {
    employee.service_ids.includes(service_id)
      ? setEmployee({
        ...employee,
        service_ids: employee.service_ids.filter(
          (s_id) => service_id !== s_id
        ),
      })
      : setEmployee({
        ...employee,
        service_ids: [...employee.service_ids, service_id],
      });
  };

  const validateStaff = () => {
    return Validate([
      {
        field: "İsim",
        value: employee.name,
        condition: /[a-zA-Z0-9ğüşöçİĞÜŞÖÇ]+/,
      },
      {
        field: "Soyisim",
        value: employee.surname,
        condition: /[a-zA-Z0-9ğüşöçİĞÜŞÖÇ]+/,
      },
      {
        field: "Cinsiyet",
        value: employee.sex,
        condition: Number,
      },
      {
        field: "Color",
        value: employee.color,
        condition: String,
      },
      {
        field: "Cep Telefonu",
        value: employee.detail.phone,
        condition: /\S{10}/,
      },
      {
        field: "E-Posta Adresi",
        value: employee.detail.mail,
        condition: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
      },
      {
        field: "Şifre",
        value: employee.password,
        condition: /.+/,
      },
    ]);
  };

  return (
    <AuthContainer limited_permission="system">
      <Backdrop open={tutorial} />
      <Grid>
        <Column
          className="xs-12 mb-3"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {loaded ? (
            <ProfilePhoto
              src={
                image
                  ? image
                  : require("../../../assets/images/profile_photo.svg")
              }
            />
          ) : (
            <div
              style={{ width: 160, height: 160, borderRadius: 5 }}
              className="skeleton"
            />
          )}
          <input
            id="profile_photo"
            style={{ display: "none" }}
            type="file"
            accept="image/jpg, image/jpeg, image/png"
            onChange={(e) => {
              employeeAddPhoto(e);
            }}
          />

          <Button
            className="mt-1"
            icon="add_photo_alternate"
            title={context.t(`profile.photo.uploadButtonTitle`)}
            textColor="blue"
            backgroundColor="blue-opacity"
            fullWidth
            onClick={() => document.getElementById("profile_photo").click()}
          />
          {employee.detail.profile_photo && (
            <Button
              className="mt-1"
              icon="delete"
              title={context.t(`profile.photo.deleteButtonTitle`)}
              textColor="red"
              backgroundColor="red-opacity"
              fullWidth
              onClick={() => {
                deletePhotoSingleStaff();
              }}
            />
          )}
        </Column>

        <Column className="col-auto xs-12 sm-12 md-12 lg-12 xl-12">
          <CardSection title={context.t(`profile.detail.title`)}>
            <Column className="xs-12 sm-12 md-4">
              <Input
                required
                label={context.t(`profile.detail.nameInputLabel`)}
                value={employee.name !== null ? employee.name : ""}
                onChange={(e) => {
                  setEmployee({
                    ...employee,
                    name: e.target.value,
                  });
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-4">
              <Input
                required
                label={context.t(`profile.detail.surnameInputLabel`)}
                value={employee.surname !== null ? employee.surname : ""}
                onChange={(e) => {
                  setEmployee({
                    ...employee,
                    surname: e.target.value,
                  });
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-2">
              <CSwitch
                title={context.t(`customers.headings.sex`)}
                label={context.t(`profile.detail.genderMale`)}
                labelLeft={context.t(`profile.detail.genderFemale`)}
                checked={!!employee.sex}
                color="primary"
                closeColor="purple"
                onChange={(checked) => {
                  setEmployee({
                    ...employee,
                    sex: checked.target.checked === false ? 0 : 1,
                  });
                }}
              />
              {/* <Select
                  required
                  label={context.t(`profile.detail.genderInputLabel`)}
                  items={[
                    {
                      value: "0",
                      name: context.t(`profile.detail.genderFemale`),
                    },
                    {
                      value: "1",
                      name: context.t(`profile.detail.genderMale`),
                    },
                  ]}
                  selected={employee.sex !== null ? `${employee.sex}` : ""}
                  labelKey="name"
                  valueKey="value"
                  handler={(sex) =>
                    setState({
                      employee: {
                        ...employee,
                        sex: parseInt(sex),
                      },
                    })
                  }
                /> */}
            </Column>
            <Column className="xs-12 sm-12 md-2">
              <h3>{context.t(`customers.headings.colorSelect`)}</h3>
              <div>
                <div className="swatch" onClick={handleClick}>
                  <div
                    className="color"
                    style={{ background: employee.color }}
                  />
                </div>
                {displayColorPicker ? (
                  <div className="popover">
                    <div className="cover" onClick={handleClose} />
                    <ChromePicker
                      color={employee.color}
                      onChangeComplete={(color) => {
                        setEmployee({
                          ...employee,
                          color: color.hex,
                        });
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </Column>

            <Column className="xs-12 sm-12 md-6">
              <Input
                required
                label={context.t(`profile.detail.jobTitleInputLabel`)}
                value={
                  employee.detail.job_title !== null
                    ? employee.detail.job_title
                    : ""
                }
                onChange={(e) => {
                  setEmployee({
                    ...employee,
                    detail: {
                      ...employee.detail,
                      job_title: e.target.value,
                    },
                  });
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-6">
              <MaskedInput
                label={context.t(`profile.detail.bountyInputLabel`)}
                mask="99"
                maskChar=""
                value={
                  employee.detail.bounty !== null ? employee.detail.bounty : ""
                }
                onChange={(e) => {
                  setEmployee({
                    ...employee,
                    detail: {
                      ...employee.detail,
                      bounty:
                        e.target.value.length > 0
                          ? parseInt(e.target.value)
                          : null,
                    },
                  });
                }}
              />
            </Column>

            <Column className="xs-12 sm-12 md-4">
              <Select
                required
                disabled={context.state.user.id === parseInt(params.id)}
                label={context.t(`profile.detail.permissionInputLabel`)}
                items={
                  context.state.user.permission === 2
                    ? [
                      {
                        value: 0,
                        name: context.t(`profile.detail.permissionStaff`),
                      },
                    ]
                    : [
                      {
                        value: 0,
                        name: context.t(`profile.detail.permissionStaff`),
                      },
                      {
                        value: 1,
                        name: context.t(`profile.detail.permissionOwner`),
                      },
                      {
                        value: 2,
                        name: context.t(`profile.detail.permissionLimited`),
                      },
                    ]
                }
                selected={
                  employee.permission !== null ? `${employee.permission}` : ""
                }
                labelKey="name"
                valueKey="value"
                handler={(permission) => {
                  setEmployee({
                    ...employee,
                    permission: parseInt(permission),
                  });
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-4">
              <Select
                required
                label={context.t(`profile.detail.sendSmsInputLabel`)}
                items={[
                  {
                    value: true,
                    label: context.t(`profile.detail.sendSmsTrue`),
                  },
                  {
                    value: false,
                    label: context.t(`profile.detail.sendSmsFalse`),
                  },
                ]}
                selected={
                  employee.send_sms !== null ? `${employee.send_sms}` : ""
                }
                labelKey="label"
                valueKey="value"
                handler={(send_sms) => {
                  setEmployee({
                    ...employee,
                    send_sms: send_sms === "true",
                  });
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-4">
              <Select
                required
                label={context.t(`profile.detail.urlInputLabel`)}
                items={[
                  {
                    value: true,
                    label: context.t(`profile.detail.showAtUrlTrue`),
                  },
                  {
                    value: false,
                    label: context.t(`profile.detail.showAtUrlFalse`),
                  },
                ]}
                selected={
                  employee.show_at_url !== null ? `${employee.show_at_url}` : ""
                }
                labelKey="label"
                valueKey="value"
                handler={(show_at_url) => {
                  setEmployee({
                    ...employee,
                    show_at_url: show_at_url === "true",
                  });
                }}
              />
            </Column>

            {/* Form Section Settings Limited Permission  */}
            <Column
              className="xs-12 sm-12 md-12"
              style={{
                display: employee.permission === 2 ? "block" : "none",
              }}
            >
              <div className="authoritySettings">
                {limitedPermission.map((permission, index) => (
                  <div key={permission.name} className="authoritySection">
                    <div className="authorityForm">
                      <FormControl component="fieldset">
                        <FormLabel
                          className="authorityLabel"
                          component="legend"
                        >
                          {permission.label}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label="gender"
                          name={permission.name}
                          value={employee.limited_perm[`${permission.name}`]}
                          onChange={(event) => {
                            handleChange(event, permission.name);
                          }}
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio size="small" />}
                            label={context.t(
                              `profile.limited_permission_label.no_permission`
                            )}
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio size="small" />}
                            label={context.t(
                              `profile.limited_permission_label.display`
                            )}
                          />
                          <FormControlLabel
                            value={2}
                            control={<Radio size="small" />}
                            label={context.t(
                              `profile.limited_permission_label.full_permission`
                            )}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                ))}
              </div>
            </Column>
          </CardSection>

          <CardSection
            title={context.t(`profile.contact.title`)}
            className="mt-5"
          >
            <Column className="xs-12 sm-12 md-4">
              <Input
                required
                label={context.t(`profile.contact.mailInputLabel`)}
                value={
                  employee.detail.mail !== null ? employee.detail.mail : ""
                }
                onChange={(e) => {
                  setEmployee({
                    ...employee,
                    detail: {
                      ...employee.detail,
                      mail: e.target.value,
                    },
                  });
                }}
              />
            </Column>

            <Column className="xs-12 sm-12 md-4">
              {process.env.APP_NAME === "management" ? (
                <PhoneInput
                  specialLabel={context.t(`profile.inputPhone`)}
                  // country={"tr"}
                  countryCodeEditable={true}
                  value={`${employee.detail.phone}`}
                  placeholder={context.t(`customers.phone.placeHolder`)}
                  onChange={(value, data) => {
                    setEmployee((prev) => ({
                      ...prev,
                      detail: {
                        ...employee.detail,
                        phone: value,
                        dialCode: data.dialCode,
                      },
                    }));
                  }}
                />
              ) : (
                <MaskedInput
                  required
                  label={context.t(`profile.contact.phoneInputLabel`)}
                  placeholder="500 000 0000"
                  mask="999 999 9999"
                  maskChar=""
                  value={
                    employee.detail.phone !== null ? employee.detail.phone : ""
                  }
                  startAdornment="+90"
                  onChange={(e) => {
                    setEmployee({
                      ...employee,
                      detail: {
                        ...employee.detail,
                        phone:
                          e.target.value &&
                            e.target.value.substring(0, 1) === "5"
                            ? e.target.value.replace(/\s/g, "")
                            : null,
                      },
                    });
                  }}
                />
              )}
            </Column>
            <Column className="xs-12 sm-12 md-4">
              <Input
                required
                label={context.t(`profile.contact.passwordInputLabel`)}
                autoComplete={false}
                type="password"
                onChange={(e) => {
                  setEmployee({
                    ...employee,
                    password: e.target.value,
                  });
                }}
              />
            </Column>
          </CardSection>

          <CardSection
            title={context.t(`profile.services.title`)}
            className="mt-5"
          >
            <Column className="xs-12 sm-12 mb-3">
              <Alert severity="info">
                <b>{context.t(`profile.services.alertBoldText`)}</b>
                <br />
                {context.t(`profile.services.alertText`)}
              </Alert>
            </Column>
            <Column className="xs-12 sm-12">
              {/* Category name göre gruplanacak */}
              <Grid>
                {loaded === true &&
                  categories.map((category, index) => {
                    return (
                      <Column
                        key={index}
                        className="col xs-12 sm-12 md-12 lg-12 xl-12"
                      >
                        <div className="category-container_divider">
                          <div
                            onClick={() => {
                              handleCategoryClick(category.id, true);
                            }}
                            className="mb-2 category-container"
                          >
                            <span>{category.name}</span>
                          </div>
                          <span
                            className="category-container_span"
                            onClick={() => {
                              handleCategoryClick(category.id, false);
                            }}
                          >
                            <CloseOutlined />
                          </span>
                        </div>

                        {category.services.map((service) => {
                          return (
                            <>
                              {categoryOpen.map((category_open, index) => {
                                if (
                                  category_open.id === category.id &&
                                  category_open.open === true
                                ) {
                                  return (
                                    <Column className="col xs-12 sm-12 md-6 lg-6 xl-6">
                                      <Button
                                        className="mb-2"
                                        fullWidth={true}
                                        size="sm"
                                        icon={
                                          employee.service_ids.includes(
                                            service.id
                                          )
                                            ? "check"
                                            : "close"
                                        }
                                        title={service.name}
                                        outlined={employee.service_ids.includes(
                                          service.id
                                        )}
                                        textColor={
                                          employee.service_ids.includes(
                                            service.id
                                          )
                                            ? "primary"
                                            : "black"
                                        }
                                        onClick={() =>
                                          changeServiceStatus(service.id)
                                        }
                                      />
                                    </Column>
                                  );
                                }
                              })}
                            </>
                          );
                        })}
                      </Column>
                    );
                  })}
              </Grid>
            </Column>
          </CardSection>

          <CardSection title={context.t(`profile.packages.title`)} className="mt-5">
            <Column className="xs-12 sm-12 mb-3">
              <Alert severity="info">
                <b>{context.t(`profile.packages.alertBoldText`)}</b>
                <br />
                {context.t(`profile.packages.alertText`)}
              </Alert>
            </Column>
            <EmpoolesPackages
              packets={packets}
              updateState={updateState}
            />
          </CardSection>

          <CardSection
            title={context.t(`profile.booking.title`)}
            className="mt-5"
          >
            <Column className="xs-12 sm-12">
              <DayTabContainer>
                {context.t(`profile.booking.week`)?.map((item, index) => (
                  <ButtonBase key={index}>
                    <StyledDayTab
                      key={index}
                      className="ripple"
                      day={index + 1}
                      selected_day={selectedDay}
                      onClick={() => {
                        setSelectedDay(index + 1);
                      }}
                      children={item}
                    />
                  </ButtonBase>
                ))}
              </DayTabContainer>
              {loaded === true ? (
                <FormControlLabel
                  className="mb-2"
                  control={
                    <Switch
                      color="primary"
                      checked={employee.closed_appointments[selectedDay].active}
                      onChange={() => changeDayStatus(selectedDay)}
                    />
                  }
                  label={
                    <FCLabel>
                      {employee.closed_appointments[selectedDay].active === true
                        ? context.t(`profile.booking.dayCloseText`)
                        : context.t(`profile.booking.dayOpenText`)}
                    </FCLabel>
                  }
                />
              ) : (
                <div
                  style={{
                    width: 283,
                    maxWidth: "100%",
                    height: 33,
                    borderRadius: 5,
                  }}
                  className="skeleton mb-2 ml-1"
                />
              )}
            </Column>
            <Column className="xs-12 sm-12">
              {parseInt(context.state.company_shift.slice) > 0 ? (
                generatedHours.map((hour, index) => (
                  <div
                    key={index}
                    className="col xs-6 sm-2 md-2 lg-2 xl-2 mb-2 p-horizontal-1"
                  >
                    <Button
                      disabled={
                        !employee.closed_appointments[selectedDay].active
                      }
                      key={index}
                      fullWidth={true}
                      className={
                        employee.closed_appointments[selectedDay].active &&
                          !employee.closed_appointments[
                            selectedDay
                          ].hours.includes(hour)
                          ? `elevation-2`
                          : ""
                      }
                      onClick={() => changeHourStatus(hour)}
                      icon={
                        employee.closed_appointments[selectedDay].active &&
                          !employee.closed_appointments[
                            selectedDay
                          ].hours.includes(hour)
                          ? "check"
                          : "close"
                      }
                      title={hour}
                      //outlined={employee.appointments[selected_day].active && !employee.appointments[selected_day].hours.includes(hour)}
                      backgroundColor={"white"}
                      textColor={
                        employee.closed_appointments[selectedDay].active &&
                          !employee.closed_appointments[
                            selectedDay
                          ].hours.includes(hour)
                          ? "green"
                          : "red"
                      }
                    />
                  </div>
                ))
              ) : (
                <Alert severity="info">
                  <b>{context.t(`profile.booking.alertBoldText`)}</b>
                </Alert>
              )}
            </Column>
          </CardSection>

          <Button
            className="mt-3"
            icon="update"
            title={context.t(`profile.createButtonTitle`)}
            backgroundColor="primary"
            textColor="white"
            fullWidth={true}
            onClick={() => insertStaff()}
          />
          <ModalDialog
            open={selectedAgainServiceModal}
            closeHandler={() => {
              setSelectedAgainServiceModal(false);
            }}
            buttons={[
              {
                title: context.t(`employees.addAllService`),
                icon: "check",
                backgroundColor: "primary-opacity",
                textColor: "primary",
                onClick: () => {
                  setEmployee({
                    ...employee,
                    service_ids: [
                      ...employee.service_ids,
                      ...selectedAgainServiceList.map(
                        (m) => m.id
                      ),
                    ]
                  });
                  insertStaff();
                },
              },
              {
                title: context.t(`["accounting/bounty"].check.discardButtonTitle`),
                icon: "close",
                textColor: "grey",
                onClick: () => {
                  setEmployee({
                    ...employee,
                    service_ids: [],
                  });
                }
              },
            ]}
          >
            <Alert severity="warning">
              {context.t(`employees.addAllServiceWarn`)}
            </Alert>
            <div style={{ width: "100%" }}>
              {selectedAgainServiceList.map((m) => (
                <div style={{ width: "100%" }}>-{m.name} </div>
              ))}
            </div>
          </ModalDialog>
        </Column>
      </Grid>
    </AuthContainer>
  );
};

export default AddEmployee;

const StyledDayTab = styled.div`
  width: auto;
  flex: none;
  text-align: center;
  font-size: 14px;
  padding: 16px;
  font-weight: 500;
  color: ${({ day, selected_day }) =>
    day === selected_day ? "var(--primary)" : "var(--black)"};
  border-bottom: 2px solid
    ${({ day, selected_day }) =>
    day === selected_day ? "var(--primary)" : "var(--white)"};
  box-sizing: border-box;
  cursor: pointer;
`;

const DayTabContainer = styled.div`
  width: -webkit-fill-available;
  position: relative;
  z-index: 100;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden !important;
  list-style: none;
  display: inline-flex;
  margin-bottom: 16px;
  box-sizing: content-box;

  button {
    font-family: "Inter", sans-serif !important;
  }
`;

const ProfilePhoto = styled.img`
  width: 160px;
  height: 160px;
  background: #fafafa;
  max-width: 100%;
  object-fit: cover;
  border-radius: 5px;
`;
